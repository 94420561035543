/* eslint-disable no-nested-ternary */
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect } from 'react';
import { checkLength } from '../../../../utils/helpers/validation';
import localeUser from '../../../../utils/i18n/userLocales/user.json';
import localeButtons from '../../../../utils/i18n/commonLocales/buttons.json';
import localeUserList from '../../../../utils/i18n/userLocales/userList.json';
import type localeErrorMessage from '../../../../utils/i18n/commonLocales/errorMessage.json';
import { FormInput } from '../../../common/form/FormInput';
import { useServerContext } from '../../../../hooks/useServerContext';
import { DropDownSelect } from '../../../common/form/DropDownSelect';
import { useAccounts } from '../../../../hooks/components/useAccounts';
import { Button } from '../../../common/baseElements/Button';
import { FormTextArea } from '../../../common/form/FormTextArea';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import { getUserData } from '../../../../services/local-storage-service';
import styles from './AddUserForm.module.scss';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { SelectedCredForAsset } from '../../../common/form/SelectedCredForAsset';
import { CheckCredentialInfo } from '../../../common/CheckCredentialInfo';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeErrorMessage;

export function AddServerForm(): React.ReactElement {
  const currentUser = getUserData();

  const {
    url,
    setUrl,
    baseDN,
    setBaseDN,
    editServer,
    name,
    setName,
    description,
    setDescription,
    handleClose,
    handleSubmit,
    type,
    changeDropDownType,
    setErrorMutation,
    errorMutation,
    credentialSrv,
    servername,
    setServername,
  } = useServerContext();

  useEffect(() => {
    return () => setErrorMutation('');
  }, []);

  const { accountId, changeDropDownAccount } = useAccountModalContext();

  const { destructuringAccounts, isLoading, isFetching } = useAccounts();

  const { setCredential } = useCredentialContext();

  const destructuredAccounts = destructuringAccounts();

  return (
    <Dialog
      title={
        !editServer
          ? localeUser[currentLocale].connectServers.form.title
          : `${localeUser[currentLocale].connectServers.form.titleEdit} ${name}`
      }
      onClose={handleClose}
      className="modal small"
    >
      <Form
        // onSubmit={handleSubmit}
        render={(): JSX.Element => (
          <FormElement>
            <div className="form-content">
              {currentUser?.role === 'super_admin' && (
                <Field
                  name="accountId"
                  component={DropDownSelect}
                  label={localeUserList[currentLocale].modal.accountName}
                  onChange={changeDropDownAccount}
                  data={destructuredAccounts?.filter((a) => a.id !== accountId.id)}
                  textField="name"
                  dataItemKey="id"
                  defaultValue={{
                    id: '000',
                    name: localeUserList[currentLocale].modal.placeholderAccountName,
                  }}
                  cls="form-field-wrapper"
                  currentValue={accountId}
                  disabled={!destructuredAccounts}
                  classForLabel="required-label"
                />
              )}
              <Field
                name="type"
                component={DropDownSelect}
                label={localeUserList[currentLocale].modal.type}
                onChange={changeDropDownType}
                data={['ldap', 'AD'].filter(
                  (t) => t !== (type === 'activedirectory' ? 'AD' : type),
                )}
                defaultValue={
                  !type
                    ? localeUser[currentLocale].connectServers.grid.selectType
                    : type === 'activedirectory'
                    ? 'AD'
                    : type
                }
                cls="form-field-wrapper"
                currentValue={type === 'activedirectory' ? 'AD' : type}
                classForLabel="required-label"
              />
              <Field
                name="name"
                component={FormInput}
                id="name"
                label={localeUser[currentLocale].connectServers.form.name}
                onChange={(e): void => {
                  setName(e.target.value);
                }}
                placeholder={localeUser[currentLocale].connectServers.form.placeholderSrvName}
                inputValue={name}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="text"
                classForLabel="required-label"
              />
              <Field
                name="url"
                component={FormInput}
                id="url"
                label={localeUser[currentLocale].connectServers.form.url}
                onChange={(e): void => {
                  setUrl(e.target.value);
                }}
                placeholder="ldap://192.168.0.1"
                inputValue={url}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="text"
                classForLabel="required-label"
              />
              <Field
                name="baseDN"
                component={FormInput}
                id="baseDn"
                label={localeUser[currentLocale].connectServers.form.baseDN}
                onChange={(e): void => {
                  setBaseDN(e.target.value);
                }}
                placeholder="dc=test,dc=example,dc=com'"
                inputValue={baseDN}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="text"
                classForLabel="required-label"
              />
              <div className={`${styles.credentialWrap} form-field-wrapper`}>
                <Button
                  fill="flat"
                  icon="plus"
                  type="button"
                  textColor="green"
                  className={`credential-bnt ${styles.add_button}`}
                  style={{ paddingLeft: '0' }}
                  disabled={!type}
                  onClick={(): void =>
                    setCredential({
                      accountId: accountId.id || undefined,
                      assetType: 'server',
                      type,
                    })
                  }
                >
                  {credentialSrv?.credentialId
                    ? localeUser[currentLocale].connectServers.form.changedCredential
                    : localeUser[currentLocale].connectServers.form.addCredential}
                </Button>
                <SelectedCredForAsset dataCred={credentialSrv} type="server" />
              </div>
              {url && baseDN && (
                <CheckCredentialInfo
                  ip={undefined}
                  port={undefined}
                  сredentialId={credentialSrv?.credentialId}
                  url={url}
                  baseDN={baseDN}
                />
              )}
              <Field
                name="servername"
                component={FormInput}
                id="servername"
                label={localeUser[currentLocale].connectServers.form.servername}
                onChange={(e): void => {
                  setServername(e.target.value);
                }}
                placeholder={localeUser[currentLocale].connectServers.form.servernamePlaceholder}
                inputValue={servername}
                className="form-field-wrapper"
                type="text"
              />
              <Field
                name="description"
                component={FormTextArea}
                id="description"
                label={localeUser[currentLocale].connectServers.form.description}
                onChange={(e): void => {
                  setDescription(e.value);
                }}
                placeholder={localeUser[currentLocale].connectServers.form.desPlaceholder}
                inputValue={description}
              />
            </div>
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button
                type="submit"
                disabled={
                  !name ||
                  !url ||
                  !baseDN ||
                  (currentUser?.role === 'super_admin' && accountId.id === '000') ||
                  !type
                }
                onClick={handleSubmit}
              >
                {editServer ? localeButtons[currentLocale].save : localeButtons[currentLocale].add}
              </Button>
              <Button type="button" onClick={handleClose}>
                {localeButtons[currentLocale].cancel}
              </Button>
            </div>
          </FormElement>
        )}
      />
      <СommonTableLoader queryData={isLoading || isFetching} filteringData={false} />
    </Dialog>
  );
}
