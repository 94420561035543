import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import type { GridPageChangeEvent } from '@progress/kendo-react-grid';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';
import type { FilterChangeEvent } from '@progress/kendo-react-data-tools';
import { getSensorList, getSensorTreeList } from '../../services/dashboard-service';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import { handleError } from '../../utils/errors';
import { handleFilteringTest } from '../../utils/filtering-in-table-test';
import { fillFiltersHostList } from '../../utils/helpers/host-list-fill-filter-helper';
import type { IUrlParamsHosts } from '../../utils/helpers/host-list-helper';
import type { IDashboard, IPageState } from '../../utils/helpers/types';
import { useNotificationContext } from '../useNotificationContext';
import { useFilterHostChange } from './useFilterHostChange';
import { usePageChange } from './usePageChange';
import { handleAddAllAsset } from '../../utils/helpers/add-host-list-helper';
import { useHostModalContext } from '../useHostModalContext';
import type { AssetsHostItemDashboardResponse } from '../../types/__generated/on-premise-solution/api/assetsHostsDashboardResponse.v1';
import { useStatesForList } from './useStatesForList';
import { useCustomSearch } from './useCustomSearch';
import { useSensorContext } from '../useSensorContext';
import type { SensorsDashboardResponse } from '../../types/__generated/on-premise-solution/api/sensorsDashboardResponse.v1';
import type { SensorsDashboardResponseTree } from '../../types/__generated/on-premise-solution/api/sensorsDashboardResponseTree.v1';

interface IUseHostList {
  // handleAsset: (e: MenuSelectEvent) => Promise<void>;
  handleRefresh: () => void;
  filter: CompositeFilterDescriptor;
  onFilterChange: (event: FilterChangeEvent) => void;
  customSearch: string;
  handleCustomSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sort: SortDescriptor[];
  // dataDashboard: IDashboard[] | AssetsHostItemDashboardResponse[] | undefined;
  query: UseQueryResult<SensorsDashboardResponse, ApiError>;
  queryTree: UseQueryResult<SensorsDashboardResponseTree, ApiError>;
  page: IPageState;
  total: number | undefined;
  pageChange: (event: GridPageChangeEvent) => void;
  setSort: React.Dispatch<React.SetStateAction<SortDescriptor[]>>;
  dataForHooks: {
    name: string;
    componentName: string;
  };
  isLoading: boolean;
  isFetching: boolean;
  filterStatus: boolean;
  isError: boolean;
  errorMessage: string | undefined;
  cancelAllAsset: () => void;
  handleExit: () => void;
  addSelectedAssets: () => void;
  cancelAssetTypeHost: () => void;
  handleCancelHost: () => void;
}

export function useSensorList(
  urlParams: IUrlParamsHosts,
  dataForHooks: {
    name: string;
    componentName: string;
  },
  sensorIds?: string[] | undefined | null,
): IUseHostList {
  const queryClient = useQueryClient();

  const {
    customSearch,
    setCustomSearch,
    sort,
    setSort,
    page,
    setPage,
    filter,
    setFilter,
    filterStatus,
    setFilterStatus,
    filterVal,
    setFilterVal,
    filterValue,
    filterRef,
  } = useStatesForList(urlParams);

  const { createNotification } = useNotificationContext();

  const { setOpen, hostFormTemp, setHostForm, setHostFormTemp } = useHostModalContext();
  const { setAvailableSensors } = useSensorContext();

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilterHostChange(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    urlParams,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<SensorsDashboardResponse, ApiError>(
    [
      dataForHooks.componentName === 'SensorListTab' ? 'sensorListTab' : 'sensorList',
      page,
      filterVal,
      sort,
      sensorIds,
    ],
    () => getSensorList(page, filterVal, sort, sensorIds),
    {
      enabled: dataForHooks.componentName !== 'SensorTreeList',
      keepPreviousData: true,
      onSuccess: (resp) => {
        setAvailableSensors(resp.data);
      },
    },
  );

  const queryTree = useQuery<SensorsDashboardResponseTree, ApiError>(
    ['sensorTreeList', page, filterVal, sort],
    () => getSensorTreeList(page, filterVal, sort),
    {
      enabled: dataForHooks.componentName === 'SensorTreeList',
      keepPreviousData: true,
      onSuccess: (resp) => {
        setAvailableSensors(resp.data);
      },
    },
  );

  const handleCancelHost = (): void => {
    queryClient.cancelMutations();
    // setDropdownParentId('');
    // setConfirm(true);
    // setIdValue(null);
    // clearAddHost();
    // setErrCreateHostMutation('');
    // setCheckErrorMutation('');
    // setResultCheckIp(null);
    // setAssetName(false);
    // setResultCheckIp(null);
  };

  // Effects
  useEffect(() => {
    fillFiltersHostList(urlParams, filterValue, setFilter, setFilterStatus);
  }, []);

  useEffect(() => {
    if (urlParams.filterUrlData.tagsName && urlParams.filterUrlData.tagsName !== '') {
      setPage({ skip: urlParams.page.skip, take: urlParams.page.take });
      fillFiltersHostList(urlParams, filterValue, setFilter, setFilterStatus);
    }
  }, [urlParams.filterUrlData.tagsName]);

  useEffect(() => {
    queryClient.invalidateQueries('sensorList');
    queryClient.invalidateQueries('sensorTreeList');
    queryClient.invalidateQueries('sensorListTab');
  }, [queryClient]);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter]);

  useEffect(() => {
    if (query.data?.total && query.data.total <= page.skip)
      setPage({ take: page.take, skip: Math.trunc(query.data.total / page.take) });
  }, [query.data?.total]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, createNotification, query.error]);

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  function handleCustomSearch(e: React.ChangeEvent<HTMLInputElement>): void {
    debouncedCustomSearch(e);
  }

  // const handleAsset = async (e: MenuSelectEvent): Promise<void> => {
  //   if (e.item.text === 'linux' || e.item.text === 'windows') {
  //     const selectAssetType = e.item.text as 'linux' | 'windows';
  //     const dataAssetDashboard = await getAgentInstaller(selectAssetType, null);
  //     setHostCreateData({
  //       ...dataAssetDashboard,
  //       assetType: selectAssetType,
  //       queryFn: query,
  //       accountId,
  //     });
  //   }
  // };

  const handleRefresh = (): void => {
    setFilterStatus(true);
    query.refetch().then(() => setFilterStatus(false));
  };

  const addSelectedAssets = (): void => {
    setHostForm(hostFormTemp);
    setOpen(false);
  };

  const cancelAllAsset = (): void => {
    setHostFormTemp(null);
    setHostForm(null);
  };

  const cancelAssetTypeHost = (): void => {
    const mutableAssetTypeHost = hostFormTemp?.filter((h) => h.assetGroupId);
    if (mutableAssetTypeHost) {
      setHostFormTemp(mutableAssetTypeHost);
      setHostForm(mutableAssetTypeHost);
    } else {
      setHostFormTemp(null);
      setHostForm(null);
    }
  };

  const handleExit = (): void => {
    setHostFormTemp(null);
    setHostForm(null);
    setOpen(false);
  };

  return {
    // handleAsset,
    handleRefresh,
    filter,
    onFilterChange,
    customSearch,
    handleCustomSearch,
    sort,
    // dataDashboard,
    query,
    queryTree,
    page,
    total: query.data?.total,
    pageChange,
    setSort,
    dataForHooks,
    isLoading: query.isLoading,
    isFetching: query.isFetching,
    filterStatus,
    isError: query.isError,
    errorMessage: query?.error?.message,
    cancelAllAsset,
    handleExit,
    addSelectedAssets,
    cancelAssetTypeHost,
    handleCancelHost,
  };
}
