/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/no-array-index-key */
import { TileLayout, TileLayoutRepositionEvent } from '@progress/kendo-react-layout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Typography } from '@progress/kendo-react-common';
import { NavLink } from 'react-router-dom';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from '../../../common/baseElements/Button';
import styles from '../Dashboard.module.scss';
import locale from '../../../../utils/i18n/dashboardLocale/dashboardLayout.json';
import {
  getAgentStatusesDashboard,
  getTotalAssets,
  getAssetDashboardOs,
  getInventarizationAssetsAge,
  getOsPlatformsLinux,
  getOsPlatformsWindows,
  getAssetHostsAuditType,
} from '../../../../services/dashboard-service';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { AgentStatus } from './AgentStatus';
import { DASHBOARD_INTERVAL } from '../../../../utils/helpers/constants';
import type { IDashboardInterval } from '../../../../utils/helpers/types';
import { AssetByOs } from './AssetByOs';
import type { AssetOs } from '../../../../types/__generated/on-premise-solution/api/assetOs.v1';
import type { AgentStatuses } from '../../../../types/__generated/on-premise-solution/api/agentStatuses.v1';
import type {
  OSLinuxPlatforms,
  OSWindowsPlatforms,
} from '../../../../types/__generated/on-premise-solution/api/oSPlatformsResponse.v1';
import type { InventorizationAssetsAge } from '../../../../types/__generated/on-premise-solution/api/inventorizationAssetsAge.v1';
import { InvAssetsAge } from './InvAssetsAge';
import { AuditType } from './AuditType';
import { OsLinux } from './OsLinux';
import { OsWindows } from './OsWindows';
import { clearDashboardSettings } from '../../../../services/local-storage-service';
import type { AssetsResponse } from '../../../../types/__generated/on-premise-solution/api/assetsResponse.v1';
import type { AssetHostsAuditType } from '../../../../types/__generated/on-premise-solution/api/assetHostsAuditType.v1';
import { BoundaryErrorComponent } from '../../../common/BoundaryErrorComponent';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

const defaultDashboardSet = [
  { order: 7, rowSpan: 2, colSpan: 1, col: 6 },
  { order: 0, rowSpan: 2, colSpan: 2, col: 1 },
  { order: 1, rowSpan: 2, colSpan: 3, col: 3 },
  { order: 2, rowSpan: 2, colSpan: 2, col: 7 },
  { order: 3, rowSpan: 2, colSpan: 2, col: 7 },
  { order: 5, rowSpan: 2, colSpan: 4, col: 1 },
  { order: 4, rowSpan: 2, colSpan: 2, col: 5 },
  { order: 7, rowSpan: 1, colSpan: 2, col: 3 },
];

export function DashboardInventorization(): React.ReactElement {
  const [intervalValue, setIntervalValue] = useState<null | IDashboardInterval>(null);

  const queryTotalAssets = useQuery<AssetsResponse, ApiError>(['totalAssets'], () =>
    getTotalAssets(),
  );
  const queryAssetOs = useQuery<AssetOs, ApiError>(['asset-os'], () => getAssetDashboardOs());
  const queryAgentStatuses = useQuery<AgentStatuses, ApiError>(['agent-statuses'], () =>
    getAgentStatusesDashboard(),
  );
  const queryOsPlatformsLinux = useQuery<OSLinuxPlatforms, ApiError>(['osPlatformsLinux'], () =>
    getOsPlatformsLinux(),
  );
  const queryOsPlatformsWindows = useQuery<OSWindowsPlatforms, ApiError>(
    ['osPlatformsWindows'],
    () => getOsPlatformsWindows(),
  );
  const queryInvAssetsAge = useQuery<InventorizationAssetsAge, ApiError>(['invAssetsAge'], () =>
    getInventarizationAssetsAge(),
  );
  const queryAssetHostsAuditTypes = useQuery<AssetHostsAuditType, ApiError>(['assetTypes'], () =>
    getAssetHostsAuditType(),
  );

  const dashboardColRow =
    localStorage.getItem('dashboardInv') && JSON.parse(localStorage.getItem('dashboardInv') || '');

  if (dashboardColRow && defaultDashboardSet.length > dashboardColRow.length)
    localStorage.removeItem('dashboardInv');

  useEffect(() => {
    if (localStorage.getItem('dashboardInvUpdate')) {
      setIntervalValue(JSON.parse(localStorage.getItem('dashboardInvUpdate') || ''));
    }
  }, []);

  const INITIAL_DASHBOARD_SET = dashboardColRow || defaultDashboardSet;

  const [data, setData] = useState(INITIAL_DASHBOARD_SET);
  const tiles = [
    {
      header: locale[currentLocale].numberOfHosts,
      body: (
        <div className={styles.number_of_assets}>
          <NavLink className="dashboard__link" to="/lk/assets/hosts">
            <h2>{queryTotalAssets.data?.total}</h2>
          </NavLink>
        </div>
      ),
    },
    {
      header: locale[currentLocale].topLinux,
      body: useMemo(() => {
        return (
          <div>
            <OsLinux data={queryOsPlatformsLinux.data} />
          </div>
        );
      }, [queryOsPlatformsLinux.data]),
    },
    {
      header: locale[currentLocale].topWindows,
      body: useMemo(() => {
        return (
          <div>
            <OsWindows data={queryOsPlatformsWindows.data} />
          </div>
        );
      }, [queryOsPlatformsWindows.data]),
    },
    {
      header: locale[currentLocale].agentsStatus,
      body: useMemo(() => {
        return (
          <div>
            <AgentStatus data={queryAgentStatuses.data} />
          </div>
        );
      }, [queryAgentStatuses.data]),
    },
    {
      header: locale[currentLocale].invAssetsAge,
      body: useMemo(() => {
        return (
          <div>
            <InvAssetsAge data={queryInvAssetsAge.data} />
          </div>
        );
      }, [queryInvAssetsAge.data]),
    },
    {
      header: locale[currentLocale].osHosts,
      body: useMemo(() => {
        return (
          <div>
            <AssetByOs data={queryAssetOs.data} />
          </div>
        );
      }, [queryAssetOs.data]),
    },
    {
      header: locale[currentLocale].invTypes,
      body: useMemo(() => {
        return (
          <div>
            <AuditType data={queryAssetHostsAuditTypes.data} />
          </div>
        );
      }, [queryAssetHostsAuditTypes.data]),
    },
  ];

  const handleReposition = useCallback((e: TileLayoutRepositionEvent): void => {
    setData(e.value);
    localStorage.setItem('dashboardInv', JSON.stringify(e.value));
  }, []);

  const handleRefresh = useCallback((): void => {
    queryTotalAssets.refetch();
    queryAssetOs.refetch();
    queryAgentStatuses.refetch();
    queryOsPlatformsLinux.refetch();
    queryOsPlatformsWindows.refetch();
    queryInvAssetsAge.refetch();
    queryAssetHostsAuditTypes.refetch();
  }, [
    queryAgentStatuses,
    queryAssetOs,
    queryAssetHostsAuditTypes,
    queryInvAssetsAge,
    queryOsPlatformsLinux,
    queryOsPlatformsWindows,
    queryTotalAssets,
  ]);

  const handleChangeUpdate = (event: DropDownListChangeEvent): void => {
    setIntervalValue({
      value: { text: event.target.value.text, value: event.target.value.value },
    });
  };

  useEffect(() => {
    let repeatDashboard: NodeJS.Timer;
    if (intervalValue && intervalValue.value.value !== 0) {
      localStorage.setItem('dashboardInvUpdate', JSON.stringify(intervalValue));
      repeatDashboard = setInterval(() => {
        handleRefresh();
      }, Number(intervalValue.value.value));
    } else if (intervalValue && intervalValue.value.value === 0) {
      localStorage.removeItem('dashboardInvUpdate');
    }

    return () => clearInterval(repeatDashboard);
  }, [handleRefresh, intervalValue]);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="dashboard">
        <div className="common-header-page">
          <Typography.h3>{locale[currentLocale].titleInv}</Typography.h3>
          <div className="topactions">
            <Button icon="refresh" onClick={handleRefresh}>
              {locale[currentLocale].btnRefresh}
            </Button>
            {/* <span className="k-icon k-i-rotate" />{' '} */}
            <span className="auto-update-dashboard">{locale[currentLocale].titleRefresh}</span>
            <DropDownList
              data={DASHBOARD_INTERVAL[currentLocale]}
              textField="text"
              dataItemKey="value"
              defaultValue={DASHBOARD_INTERVAL[currentLocale][0]}
              value={intervalValue?.value}
              onChange={handleChangeUpdate}
            />
            <Button
              // fillMode="flat"
              // icon="grid"
              onClick={(): void => clearDashboardSettings('dashboardInv')}
            >
              {locale[currentLocale].btnDefault}
            </Button>
          </div>
        </div>
        <TileLayout
          autoFlow="column"
          columns={8}
          rowHeight={255}
          positions={data}
          gap={{
            rows: 10,
            columns: 10,
          }}
          items={tiles}
          onReposition={handleReposition}
        />
      </div>
    </ErrorBoundary>
  );
}
