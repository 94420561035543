/* eslint-disable react/jsx-pascal-case */
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { useEffect, useState, type ReactElement } from 'react';
import { FormInput } from '../../common/form/FormInput';
import { Button } from '../../common/baseElements/Button';
import { useSensorContext } from '../../../hooks/useSensorContext';
import { CreateSensorBlock } from './CreateSensorBlock';
import localeSensor from '../../../utils/i18n/sensorLocales/sensor.json';
import localeButtons from '../../../utils/i18n/commonLocales/buttons.json';
import { FormTextArea } from '../../common/form/FormTextArea';
import { useSensorActions } from '../../../hooks/components/useSensorActions';
import { SensorConfirm } from './SensorConfirm';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeButtons;

export function SensorAddModal(): ReactElement {
  const { installerQuery, setCreationOpen, dropdownParentId } = useSensorContext();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const { isConfirm, setConfirm, editSensor, cancelCreateSensor } = useSensorActions();

  const handleClose = (): void => setConfirm(true);

  useEffect(() => {
    installerQuery.mutateAsync(undefined).then((resp) => {
      if (dropdownParentId)
        editSensor(
          resp.sensor.sensorId,
          {
            parentId: dropdownParentId,
          },
          undefined,
          true,
        );
    });

    return () => {
      installerQuery.reset();
    };
  }, []);

  return (
    <Dialog
      autoFocus
      className="add-sensor small"
      title={localeSensor[currentLocale].addModal.title}
      onClose={handleClose}
    >
      {isConfirm && <SensorConfirm setConfirm={setConfirm} handleCancel={cancelCreateSensor} />}
      <Form
        onSubmit={(): void => {
          if (installerQuery.data?.sensor.sensorId) {
            editSensor(installerQuery.data?.sensor.sensorId, {
              name,
              description: description || undefined,
            });
            setCreationOpen(undefined);
          }
        }}
        render={(formRenderProps: FormRenderProps): JSX.Element => (
          <FormElement className="create-sensor">
            <div className="form-content">
              <Field
                name="name"
                component={FormInput}
                id="name"
                label={`${localeSensor[currentLocale].addModal.nameField}:`}
                onChange={(e): void => {
                  setName(e.target.value);
                }}
                inputValue={name}
                className="form-field-wrapper"
                classForLabel="required-label"
                // customValidator={checkLength}
                type="text"
              />
              <Field
                name="name"
                component={FormTextArea}
                id="description"
                label={`${localeSensor[currentLocale].addModal.description}:`}
                onChange={(e): void => {
                  setDescription(e.value);
                }}
                inputValue={description}
                className="form-field-wrapper"
                type="text"
                required={false}
              />
              <CreateSensorBlock data={installerQuery.data} />
            </div>
            <div className="k-form-buttons">
              <Button
                disabled={!formRenderProps.allowSubmit || !formRenderProps.valid || !name}
                type="submit"
              >
                {localeButtons[currentLocale].create}
              </Button>
              <Button
                onClick={
                  // handleCancelSensor
                  (e): void => {
                    e.preventDefault();
                    setConfirm(true);
                  }
                }
              >
                {localeButtons[currentLocale].cancel}
              </Button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
}
