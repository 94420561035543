import '../../Task/TaskDescription.module.scss';
import type { FilterChangeEvent } from '@progress/kendo-react-data-tools';
import type { GridPageChangeEvent } from '@progress/kendo-react-grid';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { useQuery } from 'react-query';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import {
  DATA_CACHE_STALETIME,
  FILTER_INITIAL,
  INITIAL_SORT_ALL_USER_LOGLIST,
} from '../../../../utils/helpers/constants';
import type { IDataHooks, IPageState } from '../../../../utils/helpers/types';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';
import { handleError } from '../../../../utils/errors';
import { handleFilteringTest, IFilterVal } from '../../../../utils/filtering-in-table-test';
import type { LogsResponse } from '../../../../types/__generated/on-premise-solution/api/logsResponse.v1';
import { usePageChange } from '../../../../hooks/components/usePageChange';
import { useFilter } from '../../../../hooks/components/useFilter';
import { useCustomSearch } from '../../../../hooks/components/useCustomSearch';
import { CommonLogs } from '../../../common/CommonLogs';
import { getAllTaskLogs } from '../../../../services/logs-service';
import { getUrlList } from '../../../../utils/helpers/getUrl-list-helper';

export function AllTaskLogList({ accountId }: { accountId?: string }): React.ReactElement {
  const urlParams = getUrlList('AllTaskLogList', INITIAL_SORT_ALL_USER_LOGLIST);

  const [customSearch, setCustomSearch] = useState('');

  const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const [staleTimeVal, setStaleTimeVal] = useState(DATA_CACHE_STALETIME);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const { taskExecutionId } = useParams<{ taskExecutionId: string }>();

  const dataForHooks: IDataHooks = {
    name: 'asset.latestInventorization.hostname',
    componentName: 'AllTaskLogList',
    urlPath: '/lk/systemlog/tasks',
  };

  const { createNotification } = useNotificationContext();

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<LogsResponse, ApiError>(
    ['AllTaskLogList', page, sort, filterVal, accountId],
    () => getAllTaskLogs(page, sort, filterVal, accountId),
    {
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;
    if (!filter || filter.filters.length === 0) {
      setStaleTimeVal(DATA_CACHE_STALETIME);
      handleFilteringTest(filter, setFilterVal);
      filterRef.current = filter;
      setFilterStatus(false);
    }

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter, page.skip, taskExecutionId]);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error, createNotification]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  return (
    <CommonLogs
      filter={filter}
      onFilterChange={onFilterChange}
      customSearch={customSearch}
      handleCustomSearch={handleCustomSearch}
      page={page}
      sort={sort}
      data={query.data}
      pageChange={pageChange}
      isLoading={query.isLoading}
      filterStatus={filterStatus}
      setSort={setSort}
      componentName={dataForHooks.componentName}
    />
  );
}
