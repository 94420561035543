import { Dialog } from '@progress/kendo-react-dialogs';
import { AddLocalUserForm } from './AddLocalUserForm';
import { useUserModalContext } from '../../../../hooks/useUserModalContext';
import localeUser from '../../../../utils/i18n/userLocales/userList.json';
import styles from './AddUserForm.module.scss';
import { AddLdapUserForm } from './AddLdapUserForm';
import { AddAdUserForm } from './AddAdUserForm';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { getUserData } from '../../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function AddUserForm(): React.ReactElement {
  const { handleClose, editUserData } = useUserModalContext();

  const { type } = useAuthContext();

  const currentUser = getUserData();

  return (
    <Dialog
      title={
        editUserData
          ? `${localeUser[currentLocale].modal.editTitle}`
          : localeUser[currentLocale].modal.createTitle
      }
      onClose={handleClose}
      className={`small user-edit ${styles.addUserForm}`}
    >
      {type === 'local' && <AddLocalUserForm currentUser={currentUser} />}
      {type === 'ldap' && <AddLdapUserForm currentUser={currentUser} />}
      {type === 'activedirectory' && <AddAdUserForm currentUser={currentUser} />}
    </Dialog>
  );
}
