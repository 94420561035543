import { useRef, useState } from 'react';
import { useMutation, useQueryClient, UseMutationResult } from 'react-query';
import { useHistory } from 'react-router';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import { useAssetCreatePropContext } from '../useAssetCreatePropContext';
import { IOpenedFromComponentInfo, useSensorContext } from '../useSensorContext';
import {
  deleteSensor as deleteSensorQuery,
  editSensor as editSensorQuery,
} from '../../services/sensor-service';
import type { Sensor } from '../../types/__generated/on-premise-solution/api/sensorDashboardResponse.v1';
import type { SensorUpdateRequest } from '../../types/__generated/on-premise-solution/api/sensorUpdateRequest.v1';
import type { Tag } from '../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';
import { handleError } from '../../utils/errors';
import { useNotificationContext } from '../useNotificationContext';

export interface IUseSensorActions {
  createSensor: (
    parentId?: string | null,
    openedFromInfo?: IOpenedFromComponentInfo,
  ) => Promise<void>;
  editSensor: (
    sensorId: string,
    payload: SensorUpdateRequest,
    needUpdateInstallerInfo?: boolean,
    isSystem?: boolean,
  ) => Promise<void>;
  deleteSensor: (assetId: string, onSuccess?: () => void | undefined) => void;
  //

  cancelCreateSensor: () => void;
  mutation: UseMutationResult<
    SensorUpdateRequest,
    ApiError,
    {
      payloadSensorId: string;
      payload: SensorUpdateRequest;
    },
    unknown
  >;
  mutationSensor: UseMutationResult<Sensor | null, ApiError, string, unknown>;
  isConfirm: boolean;
  setConfirm: (v: boolean) => void;
  handleCancelSensor: () => void;
  deleteTagFromSensor: (
    tag: string,
    tags: Tag[] | null | undefined,
    tagIds: string[] | null | undefined,
    sensorId: string,
  ) => void;
}

export function useSensorActions(): IUseSensorActions {
  const history = useHistory();
  const [isConfirm, setConfirm] = useState(false);
  const isSystemEdit = useRef<boolean>(false);

  const { createNotification } = useNotificationContext();

  const {
    creationOpen,
    reinstallOpen,
    deleteOpen,
    setDeleteOpen,
    editOpen,
    setSensorNotEmptyModal,
    setCreationOpen,
    setDropdownParentId,
    installerQuery,
  } = useSensorContext();

  const { setErrCreateHostMutation } = useAssetCreatePropContext();

  const queryClient = useQueryClient();

  const mutation = useMutation<
    SensorUpdateRequest,
    ApiError,
    { payloadSensorId: string; payload: SensorUpdateRequest }
  >(({ payloadSensorId, payload }) => editSensorQuery(payloadSensorId, payload), {
    onSuccess: (resp) => {
      setDropdownParentId(resp.parentId || undefined);
      if (
        (!isSystemEdit.current && creationOpen?.rootComponentName === 'SensorDescription') ||
        reinstallOpen?.rootComponentName === 'SensorDescription' ||
        editOpen?.rootComponentName === 'SensorDescription' ||
        (!creationOpen && !reinstallOpen && !editOpen)
      )
        queryClient.invalidateQueries('sensorDescription');
      else if (
        (!isSystemEdit.current && creationOpen?.rootComponentName === 'SensorClassicList') ||
        editOpen?.rootComponentName === 'SensorClassicList'
      )
        queryClient.invalidateQueries('sensorList');
      else if (
        (!isSystemEdit.current && creationOpen?.rootComponentName === 'SensorTreeList') ||
        editOpen?.rootComponentName === 'SensorTreeList'
      )
        queryClient.invalidateQueries('sensorTreeList');

      if (
        creationOpen?.rootComponentName === 'SensorDescription' &&
        creationOpen?.subcomponentName === 'SensorListTab'
      )
        queryClient.invalidateQueries('sensorListTab');
    },
    onError: (response) => {
      handleError(response, createNotification);
      setErrCreateHostMutation(response.message);
    },
  });

  const mutationSensor = useMutation<Sensor | null, ApiError, Sensor['sensorId']>(
    (payload) => deleteSensorQuery(payload),
    {
      onSuccess: () => {
        if (deleteOpen?.rootComponentName === 'SensorClassicList')
          queryClient.invalidateQueries('sensorList');
        else if (deleteOpen?.rootComponentName === 'SensorTreeList')
          queryClient.invalidateQueries('sensorTreeList');
        else if (deleteOpen?.rootComponentName === 'SensorDescription') history.goBack();
        setDeleteOpen(undefined);
      },
      onError: (resp) => {
        if (resp.type === 'Conflict' && resp.code === '409') {
          setDeleteOpen(undefined);
          setSensorNotEmptyModal(true);
        }
      },
    },
  );

  // Functions

  // exp
  // const;
  // end exp

  const editSensor = async (
    sensorId: string,
    payload: SensorUpdateRequest,
    needUpdateInstallerInfo?: boolean,
    isSystem?: boolean,
  ): Promise<void> => {
    isSystemEdit.current = isSystem || false;
    mutation
      .mutateAsync({
        payloadSensorId: sensorId,
        payload,
      })
      .then(() => {
        if (needUpdateInstallerInfo) {
          installerQuery.mutateAsync(sensorId);
        }
      });
  };

  const createSensor = async (
    parentId?: string | null,
    openedFromInfo?: IOpenedFromComponentInfo,
  ): Promise<void> => {
    setDropdownParentId(parentId || undefined);
    setCreationOpen(openedFromInfo);
  };

  const handleCancelSensor = (): void => {
    setConfirm(true);
  };

  const cancelCreateSensor = (): void => {
    if (installerQuery.data?.sensor.sensorId) {
      mutationSensor.mutate(installerQuery.data?.sensor.sensorId);
      setCreationOpen(undefined);
      setConfirm(false);
    }
  };

  const deleteTagFromSensor = (
    tag: string,
    tags: Tag[] | null | undefined,
    tagIds: string[] | null | undefined,
    sensorId: string,
  ): void => {
    let tagIdsData: string[] | undefined = [];

    if (tagIds && tagIds.length === 1 && tagIds[0] === tag) {
      tagIdsData = [];
    } else if (tagIds && tagIds.length > 1) {
      tagIdsData = tagIds?.filter((t) => t !== tag);
    }
    editSensor(sensorId, { tagIds: tagIdsData && tagIdsData.length > 0 ? [...tagIdsData] : null });
  };

  const deleteSensor = (assetId: string, onSuccess?: () => void | undefined): void => {
    mutationSensor.mutateAsync(assetId).then(onSuccess);
  };

  return {
    editSensor,
    createSensor,
    deleteSensor,
    cancelCreateSensor,
    mutation,
    mutationSensor,
    isConfirm,
    setConfirm,
    handleCancelSensor,
    deleteTagFromSensor,
  };
}
