import React, { createContext, useState, useMemo, useContext, useEffect, useCallback } from 'react';
import type { UseQueryResult } from 'react-query';
import type { AgentInstallerDownloadUrlResponse } from '../types/__generated/on-premise-solution/api/agentInstallerDownloadUrlResponse.v1';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
// import type { AssetsDashboardResponse } from '../types/__generated/on-premise-solution/api/assetsDashboardResponse.v1';
import type { AssetsHostsDashboardResponse } from '../types/__generated/on-premise-solution/api/assetsHostsDashboardResponse.v1';
import type { AssetResponse } from '../types/__generated/on-premise-solution/api/assetsResponse.v1';
import type { IChildrenNodes } from '../utils/helpers/types';
import { useCommonContext } from './useCommonContext';
import type localeDashboardForm from '../utils/i18n/dashboardLocale/addDashboardForm.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDashboardForm;

export interface ICreateHostData {
  url: AgentInstallerDownloadUrlResponse['url'];
  agentId: AgentInstallerDownloadUrlResponse['agentId'];
  assetId: AgentInstallerDownloadUrlResponse['assetId'];
  assetType: 'linux' | 'windows' | undefined;
  accountId: string | null;
  parentId?: string;
  hostname?: string | undefined | null;
  queryFn?:
    | UseQueryResult<AssetsHostsDashboardResponse, ApiError>
    | UseQueryResult<AssetResponse, ApiError>
    | UseQueryResult<AssetsHostsDashboardResponse, ApiError>;
}
export interface IHostCreate {
  hostCreateData: ICreateHostData | null;
  setHostCreateData: (v: ICreateHostData | null) => void;
  handleLinkDowload: (url: string | undefined) => void;
}

const HostCreateContext = createContext<IHostCreate | null>(null);

export function HostCreateProvider({ children }: IChildrenNodes): React.ReactElement {
  const { setOpenForm } = useCommonContext();

  const [hostCreateData, setHostCreateData] = useState<ICreateHostData | null>(null);

  const handleLinkDowload = (url: string | undefined): void => {
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const value = useMemo(
    () => ({
      hostCreateData,
      setHostCreateData,
      handleLinkDowload,
    }),
    [hostCreateData],
  );

  useEffect((): void => {
    setOpenForm(!!hostCreateData);
  }, [hostCreateData, setOpenForm]);

  return <HostCreateContext.Provider value={value}>{children}</HostCreateContext.Provider>;
}
export const useHostCreateContext = (): IHostCreate => {
  const context = useContext(HostCreateContext);
  if (context === null) {
    throw new Error('HostCreateContext must be used inside the ModalContext.Provider.');
  }

  return context;
};
