/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect } from 'react';
import { Button } from '../../../common/baseElements/Button';
import localeDeviceForm from '../../../../utils/i18n/devices/devices.json';
import localeButtons from '../../../../utils/i18n/commonLocales/buttons.json';
import { useCheck } from '../../../../hooks/components/useCheck';
import { getAccountId } from '../../../../services/local-storage-service';
import { useCommonContext } from '../../../../hooks/useCommonContext';
import { useNetDeviceContext } from '../../../../hooks/useNetDeviceContext';
import { CheckCredentialInfo } from '../../../common/CheckCredentialInfo';
import styles from '../deviceComponents/DeviceDescription.module.scss';
import { Input } from '../../../common/baseElements/Input';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { DropDownSelect } from '../../../common/form/DropDownSelect';
import { RadioInputs } from './RadioInputs';
import { AssetParamForm } from '../../Host/forms/AssetParamForm';
import { SelectedCredForAsset } from '../../../common/form/SelectedCredForAsset';
import { SensorDropdownList } from '../../Sensor/SensorDropdownList';
import { useSensorContext } from '../../../../hooks/useSensorContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDeviceForm;

export function AddNetDeviceForm(): React.ReactElement {
  const accountId = getAccountId();

  const { ipValidator } = useCheck();

  const { resultCheckIp } = useCommonContext();

  const { credentialDev, setCredential } = useCredentialContext();

  const { dropdownParentId, setDropdownParentId } = useSensorContext();

  const {
    cancelAddEdit,
    netDeviceData,
    ip,
    editIp,
    initialNetDeviceFields,
    onSubmitDevice,
    typeOfDevice,
    errorMutation,
    setErrorMutation,
    routerVendor,
    switchVendor,
    firewallVendor,
    hypervisorVendor,
    changeVendor,
    vendor,
  } = useNetDeviceContext();

  useEffect(() => {
    // setDropdownParentId(sensorId);

    return () => {
      setErrorMutation('');
      setDropdownParentId('');
    };
  }, []);

  return (
    <Dialog
      title={
        netDeviceData
          ? `${localeDeviceForm[currentLocale].form.editTitle}`
          : `${localeDeviceForm[currentLocale].form.addTitle}`
      }
      onClose={(): void => cancelAddEdit()}
      className="add-edit-devices modal small"
    >
      <Form
        initialValues={initialNetDeviceFields}
        onSubmitClick={(dataItem: any): void => onSubmitDevice(dataItem, dropdownParentId)}
        render={(formRenderProps: FormRenderProps): React.ReactElement => (
          <FormElement>
            <div className="form-content">
              <RadioInputs />
              <Field
                name="vendor"
                component={DropDownSelect}
                label={localeDeviceForm[currentLocale].form.class}
                data={
                  typeOfDevice === 'switch'
                    ? switchVendor.filter((d) => d !== vendor)
                    : typeOfDevice === 'firewall'
                    ? firewallVendor.filter((d) => d !== vendor)
                    : typeOfDevice === 'router'
                    ? routerVendor.filter((d) => d !== vendor)
                    : hypervisorVendor.filter((d) => d !== vendor)
                }
                defaultValue={vendor}
                cls="form-field-wrapper"
                classForLabel="required-label"
                onChange={changeVendor}
                // value={vendor}
              />
              <div className="form-block">
                <div className="form-field-wrapper">
                  <label className="k-label required-label" htmlFor="ip">
                    IP:
                  </label>
                  <Input
                    className="k-input"
                    name="ip"
                    placeholder={localeDeviceForm[currentLocale].form.ipPlaceholder}
                    value={ip}
                    onChange={(e): void => editIp(e.target.value)}
                    onBlur={(e): void => ipValidator(e.target.value)}
                  />
                  {resultCheckIp && (
                    <div className="error-common-field">
                      <span>{resultCheckIp}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className={`${styles.credentialWrap} form-field-wrapper`}>
                <Button
                  fill="flat"
                  icon="plus"
                  type="button"
                  textColor="green"
                  className={`credential-bnt required-label-btn ${styles.add_button}`}
                  style={{ paddingLeft: '0' }}
                  onClick={(): void =>
                    setCredential({
                      accountId: accountId || undefined,
                      osType: typeOfDevice !== 'hypervisor' ? 'linux' : undefined,
                      typeOfDevice: typeOfDevice === 'hypervisor' ? 'hypervisor' : undefined,
                    })
                  }
                >
                  {!credentialDev?.credentialId
                    ? localeDeviceForm[currentLocale].form.addCredential
                    : localeDeviceForm[currentLocale].form.changedCredential}
                </Button>
                {/* <SelectedCredential /> */}
                <SelectedCredForAsset dataCred={credentialDev} type="device" />
              </div>
              {credentialDev && (
                <CheckCredentialInfo
                  ip={ip}
                  port={credentialDev?.credentialSshPort}
                  сredentialId={credentialDev?.credentialId}
                  typeOfPort="ssh"
                />
              )}
            </div>
            <AssetParamForm type="networkDevices" />
            <div className="form-block">
              <Field name="sensor" label="Test" component={SensorDropdownList} />
            </div>
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button
                type="submit"
                disabled={
                  !formRenderProps.valid ||
                  ip.length < 1 ||
                  (ip.length > 1 && !!resultCheckIp) ||
                  !credentialDev?.credentialId ||
                  vendor === localeDeviceForm[currentLocale].form.selectClass
                }
              >
                {localeButtons[currentLocale].add}
              </Button>
              <Button type="button" onClick={(): void => cancelAddEdit()}>
                {localeButtons[currentLocale].cancel}
              </Button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
}
