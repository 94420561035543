/* eslint-disable no-nested-ternary */
import { useParams } from 'react-router';
import { UseQueryResult, useQuery } from 'react-query';
import { useEffect, useRef } from 'react';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import { useMaintenanceWindowsContext } from '../useMaintenanceWindowsContext';
import { getAccountInfo } from '../../services/account-service';
import type { AccountDashboardResponse } from '../../types/__generated/on-premise-solution/api/accountsDashboardResponse.v1';
import { useAccountModalContext } from '../useAccountModalContext';

interface IUseDeviceDescription {
  query: UseQueryResult<AccountDashboardResponse, ApiError>;
}
export function useAccountDescription(): IUseDeviceDescription {
  const accountName = useRef<string | undefined>(undefined);

  const { accountId } = useParams<{ accountId: string }>();

  const { entityId, entityName, entityType, setMaintenanceWindowsList } =
    useMaintenanceWindowsContext();
  const { setAccountPatch } = useAccountModalContext();

  const query = useQuery<AccountDashboardResponse, ApiError>(
    ['accountDescription', accountId],
    () => getAccountInfo(accountId),
    {
      onSuccess: (resp) => {
        accountName.current = resp.name;
        setMaintenanceWindowsList(resp.maintenanceWindows);
        entityId.current = resp.accountId;
        entityType.current = 'account';
        entityName.current = accountName.current;
      },
    },
  );

  useEffect(() => {
    setMaintenanceWindowsList(undefined);
    setAccountPatch();
  }, []);

  return {
    query,
  };
}
