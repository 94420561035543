/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
import { useParams } from 'react-router-dom';
import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import localeHostDescription from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import styles from '../../../Host/hostComponents/HostDescription.module.scss';
import { useAuditModalContext } from '../../../../../hooks/useAuditModalContext';
import { ImageMetaBlock } from './ImageMetaBlock';
import { TabsImageTop } from './TabsImageTop';
import { DifferenceAuditModal } from '../../../Host/DifferenceModal/Audit/DifferenceAuditModal';
import { useDifferenceModalContext } from '../../../../../hooks/useDifferenceModalContext';
import { MissingKbModal } from '../../../Task/taskVmDetails/vmDetailsComponents/MissingKbModal';
import { DifferenceInvModal } from '../../../Host/DifferenceModal/Inventorization/DifferenceInvModal';
import { useImageDescription } from '../../../../../hooks/components/useImageDescription';
import { Button } from '../../../../common/baseElements/Button';
import { useCreateTask } from '../../../../../hooks/components/useCreateTask';
import { getUserData } from '../../../../../services/local-storage-service';
import { HostConfirmDel } from '../../../Host/hostComponents/HostConfirmDel';
import { useRegisterContext } from '../../../../../hooks/useRegisterContext';
import { useHostModalContext } from '../../../../../hooks/useHostModalContext';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';
import { MaintenanceListModal } from '../../../Host/MaintenanceWindows/MaintenanceListModal';
import { useMaintenanceWindowsContext } from '../../../../../hooks/useMaintenanceWindowsContext';
import type { MaintenanceWindowsField } from '../../../../../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';
import { useHostActions } from '../../../../../hooks/components/useHostActions';
import { EntityDescription } from '../../../../templates/EntityDescription';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;

export function ImageDescription(): React.ReactElement {
  const currentUser = getUserData();

  const { imagename } = useImageDescription();
  const { handleEditHost } = useHostActions();

  const { createReportImage, createTaskForImage } = useCreateTask();

  const {
    isAuditOpen: isDiffAuditOpen,
    setAuditOpen: setDiffAuditOpen,
    isInvOpen: isDiffInvOpen,
  } = useDifferenceModalContext();

  const { assetId } = useParams<{ assetId: string }>();

  const { queryDashboardAssetId, queryAuditId } = useImageDescription();

  const { idKb } = useAuditModalContext();

  const { setImageForm, imageForm } = useRegisterContext();

  const { setSelectedPkgs, setSelectedPkgsWl } = useHostModalContext();

  const { setSelectedVulns } = useAssetCreatePropContext();

  const { isTableOpen, setPatchFunction, entityId, entityType } = useMaintenanceWindowsContext();

  const [isConfirm, setConfirm] = useState(false);

  const onSelect = (e: MenuSelectEvent): void => {
    if (e.item.data === 'audit') setDiffAuditOpen(true);
    // else if (e.item.data === 'inventorization') setDiffInvOpen(true);
  };

  useEffect(() => {
    if (imagename && !imageForm) setImageForm([{ assetId, imageNames: imagename }]);
  }, [assetId, imagename, setImageForm, imageForm]);

  useEffect(() => {
    entityId.current = assetId;
    entityType.current = 'asset';
    setPatchFunction((maintenancePayload: MaintenanceWindowsField | undefined): void => {
      handleEditHost(assetId, {
        type: 'image',
        maintenanceWindows: maintenancePayload,
      });
    });

    return () => {
      setImageForm(null);
      setSelectedPkgs(null);
      setSelectedPkgsWl(null);
      setSelectedVulns(null);
    };
  }, []);

  return (
    <EntityDescription
      isLoading={queryDashboardAssetId.isLoading}
      isNotFound={queryDashboardAssetId.error?.code === '404'}
      entity={{
        id: assetId,
        type: 'image',
        name: imagename,
      }}
      topactions={
        <>
          <div className={`button-group ${styles.host_group}`}>
            <Button
              fill="action"
              onClick={(): void => {
                createTaskForImage(
                  'image',
                  queryDashboardAssetId.data?.imageNames[0],
                  queryDashboardAssetId.data?.imageId,
                  queryDashboardAssetId.data?.assetId,
                );
              }}
            >
              {localeHostDescription[currentLocale].btnCreateTask}
            </Button>
            <Button onClick={(): void => createReportImage(assetId, imagename || '-')}>
              {localeHostDescription[currentLocale].btnCreateReport}
            </Button>
          </div>
          <div>
            <Menu hoverOpenDelay={0} onSelect={onSelect} className="btn-menu">
              <MenuItem text={localeHostDescription[currentLocale].changes.differenceControl}>
                <MenuItem
                  text={localeHostDescription[currentLocale].changes.auditTitle}
                  data="audit"
                />
              </MenuItem>
            </Menu>
          </div>
          {currentUser?.role !== 'reader_user' && (
            <Button onClick={(): void => setConfirm(true)} fill="delete">
              {localeHostDescription[currentLocale].btnDelete}
            </Button>
          )}
        </>
      }
      className="imagedescription"
    >
      {isConfirm && <HostConfirmDel setConfirm={setConfirm} assetName={imagename || assetId} />}
      {isTableOpen && <MaintenanceListModal assetId={assetId} />}
      {isDiffAuditOpen && (
        <DifferenceAuditModal
          system={
            queryDashboardAssetId.data?.latestInventorization?.os?.type === 'linux'
              ? queryDashboardAssetId.data.latestInventorization.os.name
              : `${queryDashboardAssetId?.data?.latestInventorization.os?.name} ${queryDashboardAssetId.data?.latestInventorization.os?.version}`
          }
        />
      )}
      {isDiffInvOpen && (
        <DifferenceInvModal
          system={
            queryDashboardAssetId.data?.latestInventorization?.os?.type === 'linux'
              ? queryDashboardAssetId.data.latestInventorization.os.name
              : `${queryDashboardAssetId?.data?.latestInventorization.os?.name} ${queryDashboardAssetId.data?.latestInventorization.os?.version}`
          }
        />
      )}
      {idKb && <MissingKbModal />}
      {queryDashboardAssetId.data && <ImageMetaBlock {...queryDashboardAssetId} />}
      {queryDashboardAssetId.data && (
        <TabsImageTop
          data={queryDashboardAssetId.data}
          auditData={queryAuditId.data}
          maxVFstec={queryDashboardAssetId.data.latestAudit?.maxVFstec}
        />
      )}{' '}
    </EntityDescription>
  );
}
