import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { UserList } from './UserList';
import { AccountList } from '../Account/AccountList';
import { RoleList } from './RoleList';
import localeUser from '../../../utils/i18n/userLocales/user.json';
import { ServerList } from './ServerList';
import { getUserData } from '../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;
export function TabsUsers({
  selected,
  handleSelect,
}: {
  selected: number;
  handleSelect: (e: TabStripSelectEventArguments) => void;
}): React.ReactElement {
  const currentUser = getUserData();

  return (
    <TabStrip selected={selected} onSelect={handleSelect}>
      <TabStripTab title={localeUser[currentLocale].tabs.users}>
        <UserList />
      </TabStripTab>
      {currentUser?.role === 'super_admin' && (
        <TabStripTab title={localeUser[currentLocale].tabs.accounts}>
          <AccountList />
        </TabStripTab>
      )}
      <TabStripTab title={localeUser[currentLocale].tabs.roles}>
        <RoleList />
      </TabStripTab>
      <TabStripTab title={localeUser[currentLocale].tabs.servers}>
        <ServerList />
      </TabStripTab>
    </TabStrip>
  );
}
