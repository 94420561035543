/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-pascal-case */
// import { Typography } from '@progress/kendo-react-common';
import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { useCallback } from 'react';
import { useParams } from 'react-router';
import { Button } from '../../../common/baseElements/Button';
import localeHostDescription from '../../../../utils/i18n/assetLocales/hostDescription.json';
// import localeTag from '../../../../utils/i18n/commonLocales/tags.json';
import styles from './HostDescription.module.scss';
// import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { useCreateTask } from '../../../../hooks/components/useCreateTask';
import { getUserData } from '../../../../services/local-storage-service';
import { useDifferenceModalContext } from '../../../../hooks/useDifferenceModalContext';
// import { CommonTooltip } from '../../../common/CommonTooltip';
// import { AddTag } from './AddTag';
// import { BASE_ADD_TAG_PROP } from '../../../../utils/helpers/constants';
// import { TagList } from './TagList';
import { useHostActions } from '../../../../hooks/components/useHostActions';
import { useHostDescription } from '../../../../hooks/components/useHostDescription';
// import { useTagContext } from '../../../../hooks/useTagContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHostDescription;

export function HostDescriptionTopactions({
  setConfirm,
}: {
  setConfirm: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement {
  const { assetId } = useParams<{ assetId: string }>();

  const {
    assetIdData,
    hostname,
    // auditIdLoading,
    // assetIdIsLoading,
    // inventorizationIdIsLoading,
    queryDashboardAssetId,
    onCredentialChange,
  } = useHostDescription();

  const currentUser = getUserData();

  const { setOpenHostGroups } = useHostModalContext();

  // const { isShowAddTag, setShowAddTag } = useTagContext();

  const { selectCredential } = useHostActions();

  const { createTaskForAsset, handleAsset, createReportHost } = useCreateTask();

  const { setAuditOpen: setDiffAuditOpen, setInvOpen: setDiffInvOpen } =
    useDifferenceModalContext();

  const handleGroups = useCallback(() => {
    setOpenHostGroups(true);
  }, [setOpenHostGroups]);

  const onSelect = (e: MenuSelectEvent): void => {
    if (e.item.data === 'audit') setDiffAuditOpen(true);
    else if (e.item.data === 'inventorization') setDiffInvOpen(true);
  };

  const userRoleCondition =
    assetIdData?.createdBy.source === 'user' &&
    currentUser?.role === 'user' &&
    assetIdData.createdBy.id === currentUser.id;

  return (
    <>
      <div className={`button-group ${styles.host_group}`}>
        <Button
          fill="action"
          onClick={(): void => {
            createTaskForAsset({ val: 'default', data: assetIdData, assetId });
          }}
        >
          {localeHostDescription[currentLocale].btnCreateTask}
        </Button>
        <Button onClick={(): void => createReportHost(assetId, hostname)}>
          {localeHostDescription[currentLocale].btnCreateReport}
        </Button>
      </div>
      <div className={`button-group ${styles.host_group}`}>
        <Menu hoverOpenDelay={0} onSelect={onSelect} className="btn-menu">
          <MenuItem text={localeHostDescription[currentLocale].changes.differenceControl}>
            <MenuItem text={localeHostDescription[currentLocale].changes.auditTitle} data="audit" />
            <MenuItem
              text={localeHostDescription[currentLocale].changes.invTitle}
              data="inventorization"
            />
          </MenuItem>
        </Menu>
        {currentUser?.role !== 'reader_user' && (
          <Button
            onClick={handleGroups}
            disabled={
              assetIdData?.createdBy.source === 'user' &&
              currentUser?.role === 'user' &&
              currentUser.id !== assetIdData.createdBy.id
            }
          >
            {localeHostDescription[currentLocale].btnGroups}
          </Button>
        )}
      </div>
      {currentUser?.role !== 'reader_user' && (
        <div className={`button-group ${styles.host_group}`}>
          <Menu
            hoverOpenDelay={0}
            onSelect={(e): Promise<void> => handleAsset(e, queryDashboardAssetId)}
            className="btn-menu"
          >
            <MenuItem
              text={localeHostDescription[currentLocale].agent}
              disabled={
                assetIdData?.createdBy.source === 'user' &&
                currentUser?.role === 'user' &&
                currentUser.id !== assetIdData.createdBy.id
              }
            >
              <MenuItem text={localeHostDescription[currentLocale].agentUpdate} />
              <MenuItem
                disabled={
                  !assetIdData?.agent?.osType && !assetIdData?.latestInventorization?.os.type
                }
                text={
                  !assetIdData?.latestInventorization?.os ||
                  !assetIdData?.latestInventorization?.os.type
                    ? localeHostDescription[currentLocale].agentInstall
                    : localeHostDescription[currentLocale].agentReinstall
                }
              />
            </MenuItem>
          </Menu>
          {(!assetIdData?.credential || !assetIdData?.credentialId) &&
            (currentUser?.role === 'super_admin' ||
              currentUser?.role === 'account_admin' ||
              userRoleCondition) && (
              <Button
                onClick={(): void =>
                  selectCredential({
                    assetId: assetIdData?.assetId,
                    accountId: assetIdData?.accountId,
                    assetGroupIds: assetIdData?.assetGroupIds,
                    credentialId:
                      assetIdData?.type === 'host'
                        ? assetIdData?.credential?.credentialId
                        : undefined,
                    osType:
                      assetIdData?.type === 'host'
                        ? assetIdData?.latestInventorization?.os.type
                        : undefined,
                    assetType: 'host',
                  })
                }
                disabled={
                  !assetIdData?.latestInventorization?.os ||
                  !assetIdData?.latestInventorization?.os.type
                }
              >
                {localeHostDescription[currentLocale].btnAddCredential}
              </Button>
            )}
          {assetIdData &&
            assetIdData.type === 'host' &&
            assetIdData.credential &&
            assetIdData.credentialId &&
            (currentUser?.role === 'super_admin' ||
              currentUser?.role === 'account_admin' ||
              userRoleCondition) && (
              <Menu hoverOpenDelay={0} onSelect={onCredentialChange} className="btn-menu">
                <MenuItem
                  text={localeHostDescription[currentLocale].summary.credential}
                  disabled={
                    assetIdData?.createdBy.source === 'user' &&
                    currentUser?.role === 'user' &&
                    currentUser.id !== assetIdData?.createdBy.id
                  }
                >
                  <MenuItem
                    data="edit"
                    text={localeHostDescription[currentLocale].summary.editCredetial}
                  />
                  <MenuItem
                    data="changeCredential"
                    text={localeHostDescription[currentLocale].summary.changeCredential}
                  />
                  {assetIdData?.credential.type === 'ssh' && (
                    <MenuItem
                      data="changeSsh"
                      text={localeHostDescription[currentLocale].summary.changeSsh}
                    />
                  )}
                  {assetIdData?.credential.type === 'winrm' && (
                    <MenuItem
                      data="changeSmbPort"
                      text={localeHostDescription[currentLocale].summary.changesmbPort}
                    />
                  )}
                  {assetIdData?.credential.type === 'winrm' && (
                    <MenuItem
                      data="changeWinRmPort"
                      text={localeHostDescription[currentLocale].summary.changewinrmPort}
                    />
                  )}
                  <MenuItem
                    data="delete"
                    text={localeHostDescription[currentLocale].summary.deleteCredential}
                  />
                </MenuItem>
              </Menu>
            )}
        </div>
      )}
      {currentUser?.role !== 'reader_user' && (
        <Button
          onClick={(): void => setConfirm(true)}
          fill="delete"
          disabled={
            assetIdData?.createdBy.source === 'user' &&
            currentUser?.role === 'user' &&
            currentUser.id !== assetIdData.createdBy.id
          }
        >
          {localeHostDescription[currentLocale].btnDelete}
        </Button>
      )}
    </>
  );

  // return (
  //   <div className="common-header-page">
  //     <div className="common-header-page__title">
  //       <Typography.h3 className="title">
  //         <span className="k-i-computer" /> {localeHostDescription[currentLocale].title}
  //         {hostname || assetId}
  //       </Typography.h3>
  //       <div>
  //         {(!assetIdData?.tags || assetIdData?.tags.length === 0) && (
  //           <div className={styles.commonTooltipWrap} onClick={(): void => setShowAddTag(true)}>
  //             <CommonTooltip
  //               visibleStyle={{ marginLeft: '8px', marginTop: '-19px' }}
  //               hiddenStyle={{ marginLeft: '32px', marginTop: '-40px' }}
  //               width="20px"
  //               height="20px"
  //               icon="tag"
  //               time={400}
  //             >
  //               {localeTag[currentLocale].editTags}
  //             </CommonTooltip>
  //           </div>
  //         )}
  //         {isShowAddTag && (
  //           <AddTag entityType="host" addTagProp={BASE_ADD_TAG_PROP} assetId={assetId} />
  //         )}
  //       </div>
  //       <div className={styles.tagBlock}>
  //         <TagList
  //           assetId={assetId}
  //           tags={assetIdData?.tags}
  //           tagIds={assetIdData?.tagIds}
  //           entityType="host"
  //         />
  //       </div>
  //     </div>
  //     <div className="topactions">
  //       <div className={`button-group ${styles.host_group}`}>
  //         <Button
  //           fill="action"
  //           onClick={(): void => {
  //             createTaskForAsset({ val: 'default', data: assetIdData, assetId });
  //           }}
  //         >
  //           {localeHostDescription[currentLocale].btnCreateTask}
  //         </Button>
  //         <Button onClick={(): void => createReportHost(assetId, hostname)}>
  //           {localeHostDescription[currentLocale].btnCreateReport}
  //         </Button>
  //       </div>
  //       <div className={`button-group ${styles.host_group}`}>
  //         <Menu hoverOpenDelay={0} onSelect={onSelect} className="btn-menu">
  //           <MenuItem text={localeHostDescription[currentLocale].changes.differenceControl}>
  //             <MenuItem
  //               text={localeHostDescription[currentLocale].changes.auditTitle}
  //               data="audit"
  //             />
  //             <MenuItem
  //               text={localeHostDescription[currentLocale].changes.invTitle}
  //               data="inventorization"
  //             />
  //           </MenuItem>
  //         </Menu>
  //         {currentUser?.role !== 'reader_user' && (
  //           <Button
  //             onClick={handleGroups}
  //             disabled={
  //               assetIdData?.createdBy.source === 'user' &&
  //               currentUser?.role === 'user' &&
  //               currentUser.id !== assetIdData.createdBy.id
  //             }
  //           >
  //             {localeHostDescription[currentLocale].btnGroups}
  //           </Button>
  //         )}
  //       </div>
  //       {currentUser?.role !== 'reader_user' && (
  //         <div className={`button-group ${styles.host_group}`}>
  //           <Menu
  //             hoverOpenDelay={0}
  //             onSelect={(e): Promise<void> => handleAsset(e, queryDashboardAssetId)}
  //             className="btn-menu"
  //           >
  //             <MenuItem
  //               text={localeHostDescription[currentLocale].agent}
  //               disabled={
  //                 assetIdData?.createdBy.source === 'user' &&
  //                 currentUser?.role === 'user' &&
  //                 currentUser.id !== assetIdData.createdBy.id
  //               }
  //             >
  //               <MenuItem text={localeHostDescription[currentLocale].agentUpdate} />
  //               <MenuItem
  //                 disabled={
  //                   !assetIdData?.agent?.osType && !assetIdData?.latestInventorization?.os.type
  //                 }
  //                 text={
  //                   !assetIdData?.latestInventorization?.os ||
  //                   !assetIdData?.latestInventorization?.os.type
  //                     ? localeHostDescription[currentLocale].agentInstall
  //                     : localeHostDescription[currentLocale].agentReinstall
  //                 }
  //               />
  //             </MenuItem>
  //           </Menu>
  //           {(!assetIdData?.credential || !assetIdData?.credentialId) &&
  //             (currentUser?.role === 'super_admin' ||
  //               currentUser?.role === 'account_admin' ||
  //               userRoleCondition) && (
  //               <Button
  //                 onClick={(): void =>
  //                   selectCredential({
  //                     assetId: assetIdData?.assetId,
  //                     accountId: assetIdData?.accountId,
  //                     assetGroupIds: assetIdData?.assetGroupIds,
  //                     credentialId:
  //                       assetIdData?.type === 'host'
  //                         ? assetIdData?.credential?.credentialId
  //                         : undefined,
  //                     osType:
  //                       assetIdData?.type === 'host'
  //                         ? assetIdData?.latestInventorization?.os.type
  //                         : undefined,
  //                     assetType: 'host',
  //                   })
  //                 }
  //                 disabled={
  //                   !assetIdData?.latestInventorization?.os ||
  //                   !assetIdData?.latestInventorization?.os.type
  //                 }
  //               >
  //                 {localeHostDescription[currentLocale].btnAddCredential}
  //               </Button>
  //             )}
  //           {assetIdData &&
  //             assetIdData.type === 'host' &&
  //             assetIdData.credential &&
  //             assetIdData.credentialId &&
  //             (currentUser?.role === 'super_admin' ||
  //               currentUser?.role === 'account_admin' ||
  //               userRoleCondition) && (
  //               <Menu hoverOpenDelay={0} onSelect={onCredentialChange} className="btn-menu">
  //                 <MenuItem
  //                   text={localeHostDescription[currentLocale].summary.credential}
  //                   disabled={
  //                     assetIdData?.createdBy.source === 'user' &&
  //                     currentUser?.role === 'user' &&
  //                     currentUser.id !== assetIdData?.createdBy.id
  //                   }
  //                 >
  //                   <MenuItem
  //                     data="edit"
  //                     text={localeHostDescription[currentLocale].summary.editCredetial}
  //                   />
  //                   <MenuItem
  //                     data="changeCredential"
  //                     text={localeHostDescription[currentLocale].summary.changeCredential}
  //                   />
  //                   {assetIdData?.credential.type === 'ssh' && (
  //                     <MenuItem
  //                       data="changeSsh"
  //                       text={localeHostDescription[currentLocale].summary.changeSsh}
  //                     />
  //                   )}
  //                   {assetIdData?.credential.type === 'winrm' && (
  //                     <MenuItem
  //                       data="changeSmbPort"
  //                       text={localeHostDescription[currentLocale].summary.changesmbPort}
  //                     />
  //                   )}
  //                   {assetIdData?.credential.type === 'winrm' && (
  //                     <MenuItem
  //                       data="changeWinRmPort"
  //                       text={localeHostDescription[currentLocale].summary.changewinrmPort}
  //                     />
  //                   )}
  //                   <MenuItem
  //                     data="delete"
  //                     text={localeHostDescription[currentLocale].summary.deleteCredential}
  //                   />
  //                 </MenuItem>
  //               </Menu>
  //             )}
  //         </div>
  //       )}
  //       {currentUser?.role !== 'reader_user' && (
  //         <Button
  //           onClick={(): void => setConfirm(true)}
  //           fill="delete"
  //           disabled={
  //             assetIdData?.createdBy.source === 'user' &&
  //             currentUser?.role === 'user' &&
  //             currentUser.id !== assetIdData.createdBy.id
  //           }
  //         >
  //           {localeHostDescription[currentLocale].btnDelete}
  //         </Button>
  //       )}
  //     </div>
  //     <СommonTableLoader
  //       queryData={assetIdIsLoading || auditIdLoading || inventorizationIdIsLoading}
  //       filteringData={false}
  //     />
  //   </div>
  // );
}
