/* eslint-disable no-bitwise */
import type { UseMutationResult, UseQueryResult } from 'react-query';
// import localeHostDescription from '../../utils/i18n/assetLocales/hostDescription.json';
import { useCallback } from 'react';
import type { AssetUpdateRequest } from '../../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';
import type { TagCreationRequest } from '../../types/__generated/on-premise-solution/api/tagCreationRequest.v1';
import { getAccountId } from '../../services/local-storage-service';
import type { TagsResponse } from '../../types/__generated/on-premise-solution/api/tagsResponse.v1';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import type { TagCreationResponse } from '../../types/__generated/on-premise-solution/api/tagCreationResponse.v1';
import { backgroundColorList } from '../../utils/helpers/constants';
import { useSensorActions } from './useSensorActions';
import { useHostActions } from './useHostActions';
import type { Tag } from '../../types/__generated/on-premise-solution/api/assetsImagesDashboardResponse.v1';
import type { TagUpdateResponse } from '../../types/__generated/on-premise-solution/api/tagUpdateResponse.v1';
import type { TagUpdateRequest } from '../../types/__generated/on-premise-solution/api/tagUpdateRequest.v1';
import localeTag from '../../utils/i18n/commonLocales/tags.json';
import { useTagContext } from '../useTagContext';

// const currentLocale = (
//   window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
// ) as keyof typeof localeHostDescription;

interface IEditTag {
  name: string;
  editTagError: string | null;
  tags: Tag[] | null | undefined;
  isExistingTag: React.MutableRefObject<boolean>;
  setEditTagError: React.Dispatch<React.SetStateAction<string | null>>;
  mutation: UseMutationResult<TagUpdateResponse, ApiError, TagUpdateRequest, unknown>;
  setHovered: React.Dispatch<React.SetStateAction<string>>;
  keyValue: number | null;
}

interface IAddExistTag {
  query: UseQueryResult<TagsResponse, ApiError>;
  currentTagName: React.MutableRefObject<string | null>;
  existedTagName: string;
  tagId: string;
  tags: Tag[] | null | undefined;
  setHovered: React.Dispatch<React.SetStateAction<string>>;
  setEditTagError: React.Dispatch<React.SetStateAction<string | null>>;
  currentTagId: string;
  entityId: string;
  entityType: 'host' | 'sensor';
  beforeEditVal: string;
}

interface IPrepareTagVals {
  e: React.KeyboardEvent<HTMLDivElement>;
  tagsQuery: UseQueryResult<TagsResponse, ApiError>;
  tagName: string;
  mutationNewTag: UseMutationResult<TagCreationResponse, ApiError, TagCreationRequest, unknown>;
  entityId: string;
  entityType: 'host' | 'sensor';
}

interface IUseAssetActions {
  getRandomColor(min: number, max: number): string;
  getTextColor(backgroundColor: string): string;
  prepareTagData: (vals: IPrepareTagVals) => void;
  addExistingTag: (dataAddExistTag: IAddExistTag) => void;
  addExistedTag: (
    existedTagName: string,
    tagsQuery: UseQueryResult<TagsResponse, ApiError>,
    entityId: string,
    entityType: 'host' | 'sensor',
  ) => void;
  handleEditTag: (editData: IEditTag) => void;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTag;

export function useTagActions(): IUseAssetActions {
  const { handleEditHost } = useHostActions();
  const { editSensor } = useSensorActions();
  const { setTagName, setEditTagData, setTagMenu } = useTagContext();

  function getRandomColor(min: number, max: number): string {
    // const index = Math.floor(Math.random() * val);
    const index = Math.floor(Math.random() * (max - min) + min);

    return backgroundColorList[index];
  }

  function getTextColor(bgColor: string): string {
    const rgb = parseInt(bgColor.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? '#000000' : '#FFFFFF';
  }

  const prepareTagData = (vals: IPrepareTagVals): void => {
    const { e, tagsQuery, tagName, mutationNewTag, entityId, entityType } = vals;

    const accountId = getAccountId();

    const foundedTag = tagsQuery.data?.data.filter((t) => t.name === tagName);
    if (e.key === 'Enter' && (!foundedTag || foundedTag.length === 0)) {
      const payload: TagCreationRequest = {
        accountId: accountId || '',
        name: tagName,
        color: `#${getRandomColor(0, 12)}`,
        // color: `#${generateRandomColor()}`,
      };

      mutationNewTag.mutateAsync(payload);
    } else if (e.key === 'Enter' && foundedTag && foundedTag.length > 0) {
      if (entityType === 'sensor') editSensor(entityId, { tagIdsToAdd: [foundedTag[0].tagId] });
      else if (entityType === 'host')
        handleEditHost(entityId, {
          tagIdsToAdd: [foundedTag[0].tagId],
          type: 'host',
        } as AssetUpdateRequest);
    }
  };

  const addExistedTag = (
    existedTagName: string,
    tagsQuery: UseQueryResult<TagsResponse, ApiError>,
    entityId: string,
    entityType: 'host' | 'sensor',
  ): void => {
    const foundedExistedTag = tagsQuery.data?.data.filter((t) => t.name === existedTagName);

    if (existedTagName && foundedExistedTag) {
      if (entityType === 'host')
        handleEditHost(entityId, {
          tagIdsToAdd: [foundedExistedTag[0].tagId],
          type: 'host',
        } as AssetUpdateRequest);
      else if (entityType === 'sensor')
        editSensor(entityId, { tagIdsToAdd: [foundedExistedTag[0].tagId] });
    }
  };

  const addExistingTag = (dataAddExistTag: IAddExistTag): void => {
    const {
      existedTagName,
      query,
      currentTagName,
      currentTagId,
      tags,
      tagId,
      entityId,
      entityType,
      setHovered,
      setEditTagError,
      beforeEditVal,
    } = dataAddExistTag;
    console.log('addExistingTag', existedTagName, beforeEditVal);

    setTagName(existedTagName);

    const matchingTagNameIndex = query.data?.data.findIndex((t) => t.name === existedTagName);

    if (matchingTagNameIndex === -1) {
      const deletedExistingTag = tags?.filter((t) => t.tagId !== currentTagId).map((t) => t.tagId);

      if (entityType === 'sensor')
        editSensor(entityId, { tagIds: [...(deletedExistingTag || []), tagId] });
      else if (entityType === 'host')
        handleEditHost(entityId, {
          tagIds: [...(deletedExistingTag || []), tagId],
          type: 'host',
        } as AssetUpdateRequest);
      setEditTagData(null);
      setHovered('');
      setTagMenu(false);
    } else if (matchingTagNameIndex !== -1 && beforeEditVal !== existedTagName) {
      setTagMenu(false);
      setEditTagError(`${localeTag[currentLocale].confirmDelTag}`);
    } else if (matchingTagNameIndex !== -1 && beforeEditVal === existedTagName) {
      setTagMenu(false);
      setEditTagData(null);
    }
  };

  const handleEditTag = useCallback(
    (editData: IEditTag): void => {
      console.log('handleEditTag');

      const {
        name,
        editTagError,
        tags,
        isExistingTag,
        setEditTagError,
        mutation,
        setHovered,
        keyValue,
      } = editData;

      if (tags && !isExistingTag.current) {
        setHovered('');

        const indexAlreadyExistName = tags.findIndex((t) => t.name === name);

        const indexSameTag = tags.findIndex((t, i) => t.name === name && i === keyValue);

        console.log(indexAlreadyExistName, name, tags);

        if (indexAlreadyExistName !== -1 && indexSameTag === -1) {
          setEditTagError(`${localeTag[currentLocale].confirmDelTag}`);
        } else if (indexAlreadyExistName !== -1 && indexSameTag !== -1) {
          setEditTagData(null);
        } else if (indexAlreadyExistName === -1 && !editTagError && name !== '') {
          const payload = {
            name,
          };

          mutation.mutateAsync(payload);
        }
      }
    },
    [setEditTagData],
  );

  return {
    prepareTagData,
    getTextColor,
    getRandomColor,
    addExistedTag,
    addExistingTag,
    handleEditTag,
  };
}
