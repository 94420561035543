import locale from '../../../utils/i18n/credentials/credentials.json';
import { getUrlCredential } from '../../../utils/helpers/credential-helper';
import type { IDataHooks } from '../../../utils/helpers/types';
import { useCredentialList } from '../../../hooks/components/useCredentialList';
import { CommonCredentialList } from '../../common/CommonCredentialList';
import { СommonTableLoader } from '../../common/tableLoader/CommonTableLoader';
import { getUserData } from '../../../services/local-storage-service';
import styles from './CredentialList.module.scss';
import { useCredentialContext } from '../../../hooks/useCredentialContext';
import { AddCredentialForm } from './form/AddCredentialForm';
import { Button } from '../../common/baseElements/Button';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import { INITIAL_CREATED_AT_SORT } from '../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function CredentialList(): React.ReactElement {
  const urlParams = getUrlList('CredentialList', INITIAL_CREATED_AT_SORT);

  const currentUser = getUserData();

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'CredentialList',
  };

  const { filterStatus, isError, errorMessage } = useCredentialList(urlParams, dataForHooks);

  const { setAddCredential, credentialData, isAddCredential, isEdit } = useCredentialContext();

  return (
    <div className="credential-list">
      <div className={styles.credentialActions}>
        {currentUser?.role !== 'reader_user' && (
          <Button
            fill="action"
            onClick={(): void => {
              setAddCredential(true);
            }}
          >
            {locale[currentLocale].table.addCredential}
          </Button>
        )}
      </div>
      <CommonCredentialList dataForHooks={dataForHooks} />
      <СommonTableLoader queryData={filterStatus} filteringData={filterStatus} />{' '}
      {isError && <div className="info alert alert-danger">Error: {errorMessage}</div>}
      {(isAddCredential || (credentialData && isEdit)) && <AddCredentialForm />}
    </div>
  );
}
