import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
import { history } from '../utils/history';
import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import type { TaskCreationRequest } from '../types/__generated/on-premise-solution/api/taskCreationRequest.v1';
import type { TaskCreationResponse } from '../types/__generated/on-premise-solution/api/taskCreationResponse.v1';
import type { IPageState } from '../utils/helpers/types';
import type { TaskDeletionResponse } from '../types/__generated/on-premise-solution/api/taskDeletionResponse.v1';
import type { TaskResponse } from '../types/__generated/on-premise-solution/api/taskResponse.v1';
import type { InventorizationResponse } from '../types/__generated/on-premise-solution/api/inventorizationResponse.v1';
import type { ArtifactsResponse } from '../types/__generated/on-premise-solution/api/artifactsResponse.v1';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import type { TaskActionDashboardResponse } from '../types/__generated/on-premise-solution/api/taskActionDashboardResponse.v1';
import type { TaskActionsDashboardResponse } from '../types/__generated/on-premise-solution/api/taskActionsDashboardResponse.v1';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import { prepareFilterVal } from './filterVal-for-service';
import type { LogsResponse } from '../types/__generated/on-premise-solution/api/logsResponse.v1';
import type {
  TaskExecutionDashboardResponse,
  TaskExecutionsDashboardResponse,
} from '../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import type { TasksDashboardResponse } from '../types/__generated/on-premise-solution/api/tasksDashboardResponse.v1';
import type { LdapGroupsResponse } from '../types/__generated/on-premise-solution/api/ldapGroupsResponse.v1';

export async function getTasks(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
): Promise<TasksDashboardResponse> {
  const result = await dashboardInstance.get<TasksDashboardResponse>('/tasks', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      '$or[0][status]': 'processing&$or[1][status]=waiting',
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });
      history.replace({
        pathname: `/lk/audit/tasks?${stringifiedParams}`,
      });

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function createTask(taskData: TaskCreationRequest): Promise<TaskCreationResponse> {
  const result = await vmInstance.post<TaskCreationResponse>('/tasks', taskData);

  return result.data;
}

export async function deleteTask(taskId: number): Promise<TaskDeletionResponse | null> {
  const result = await vmInstance.delete<TaskDeletionResponse>(`/tasks/${taskId}`);

  return result.data;
}

export async function getTaskInfo(taskId: number): Promise<TaskResponse> {
  const result = await vmInstance.get<TaskResponse>(`/tasks/${taskId}`);

  return result.data;
}

export async function getTaskExecutionInfo(
  taskExecutionId: string | undefined,
): Promise<TaskExecutionDashboardResponse> {
  const result = await dashboardInstance.get<TaskExecutionDashboardResponse>(
    `/task/executions/${taskExecutionId}`,
  );

  return result.data;
}
export async function getTaskHistoryList(
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
): Promise<TaskExecutionsDashboardResponse> {
  const result = await dashboardInstance.get<TaskExecutionsDashboardResponse>('/task/executions', {
    params: {
      skip: page.skip,
      limit: page.take,
      sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
      sortOrder: sort[0].dir,
      ...prepareFilterVal(filterVal),
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });
      history.replace({
        pathname: `/lk/audit/history?${stringifiedParams}`,
      });

      return stringifiedParams;
    },
  });

  return result.data;
}

export async function getHistoryByAsset(
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
  asset: string,
): Promise<TaskExecutionsDashboardResponse> {
  const result = await dashboardInstance.get<TaskExecutionsDashboardResponse>(
    `/task/executions?assetIds=${asset}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/assets/hosts/${asset}?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getHistoryBySensor(
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
  sensorId: string,
): Promise<TaskExecutionsDashboardResponse> {
  const result = await dashboardInstance.get<TaskExecutionsDashboardResponse>(
    `/task/executions?sensorId=${sensorId}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/sensors/${sensorId}?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getHistoryByAccount(
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
  accountId: string,
): Promise<TaskExecutionsDashboardResponse> {
  const result = await dashboardInstance.get<TaskExecutionsDashboardResponse>(
    `/task/executions?accountId=${accountId}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/accounts/${accountId}?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getHistoryByRegistry(
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
  registry: string,
): Promise<TaskExecutionsDashboardResponse> {
  const result = await dashboardInstance.get<TaskExecutionsDashboardResponse>(
    `/task/executions?scope.registries=${registry}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/assets/registries/${registry}/historytask?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getHistoryByDevice(
  assetType: 'router' | 'firewall',
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
  assetId: string,
): Promise<TaskExecutionsDashboardResponse> {
  const result = await dashboardInstance.get<TaskExecutionsDashboardResponse>(
    `/task/executions?assetIds=${assetId}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/assets/devices/${assetType}/${assetId}`,
        });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getTaskInventorizationId(
  inventorizationId: string | null | undefined,
): Promise<InventorizationResponse> {
  const result = await vmInstance.get<InventorizationResponse>(
    `/inventorizations/${inventorizationId}`,
  );

  return result.data;
}

export async function getResultTaskActions(
  taskExecutionId: string | undefined,
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
): Promise<TaskActionsDashboardResponse> {
  const result = await dashboardInstance.get<TaskActionsDashboardResponse>(
    `/task/actions?taskExecutionId=${taskExecutionId}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/task/executions/${taskExecutionId}?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getResultTaskActionSync(
  taskExecutionId: string | undefined,
): Promise<TaskActionsDashboardResponse> {
  const result = await dashboardInstance.get<TaskActionsDashboardResponse>(
    `/task/actions?taskExecutionId=${taskExecutionId}`,
  );

  return result.data;
}

export async function getTaskActionById(
  taskActionId: string | undefined,
): Promise<TaskActionDashboardResponse> {
  const result = await dashboardInstance.get<TaskActionDashboardResponse>(
    `/task/actions/${taskActionId}`,
  );

  return result.data;
}

export async function getTaskActionsId(
  taskExecutionId: string | undefined,
): Promise<TaskActionDashboardResponse> {
  const result = await dashboardInstance.get<TaskActionDashboardResponse>(
    `/task/actions/${taskExecutionId}`,
  );

  return result.data;
}

export async function getArtifactsId(
  taskExecutionId: string | undefined,
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
): Promise<ArtifactsResponse> {
  const result = await vmInstance.get<ArtifactsResponse>(
    `artifacts?taskExecutionId=${taskExecutionId}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/task/executions/${taskExecutionId}?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getTaskLogs(
  taskExecutionId: string | undefined,
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
): Promise<LogsResponse> {
  const result = await dashboardInstance.get<LogsResponse>(
    `/logs?taskExecutionId=${taskExecutionId}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });
        history.replace({
          pathname: `/lk/task/executions/${taskExecutionId}?${stringifiedParams}`,
        });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getLdapGr(
  page: IPageState,
  ldapId: string,
  searchName?: string,
): Promise<LdapGroupsResponse> {
  const result = await vmInstance.get<LdapGroupsResponse>(`/ldaps/${ldapId}/groups?`, {
    params: {
      skip: page.skip,
      limit: page.take,
    },

    paramsSerializer: (params) => {
      const stringifiedParams = qs.stringify(params, { encode: false });

      return searchName ? `${stringifiedParams}&name=${searchName}` : stringifiedParams;
    },
  });

  return result.data;
}
