import { useLocation } from 'react-router';
import { localizeDrawerMenu } from '../../utils/helpers/localize-drawer-menu';

interface IDrawerMenuChecker {
  auditLocationSelected: () => void;
  setSelectedItem: (pathName: string) => null | {
    text: string;
    route: string | undefined;
  };
  groupLocationSelected: () => void;
  assetLocationSelected: () => void;
  sensorLocationSelected: () => void;
  imageLocationSelected: () => void;
  deviceLocationSelected: () => void;
  settingLocationSelected: () => void;
  systemlogLocationSelected: () => void;
  usersLocationSelected: () => void;
  credentialsLocationSelected: () => void;
  whitelistLocationSelected: () => void;
}

export function useDrawerMenuChecker(): IDrawerMenuChecker {
  const location = useLocation();

  const currentLocale =
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN';

  const ITEMS_MENU = localizeDrawerMenu(currentLocale);

  const auditLocationSelected = (): void => {
    const domEl = document.getElementById('audit-history');
    if (
      domEl &&
      (location.pathname.includes('/lk/task/executions/') ||
        location.pathname.includes('/history') ||
        location.pathname.includes('/lk/task/actions/'))
    ) {
      domEl?.classList.add('k-state-selected');
    } else {
      domEl?.classList.remove('k-state-selected');
    }
  };

  const groupLocationSelected = (): void => {
    const domEl = document.getElementById('group-list');
    if (
      domEl &&
      (location.pathname.includes('/lk/assets/groups') ||
        location.pathname.includes('/lk/assets/groups/'))
    ) {
      domEl?.classList.add('k-state-selected');
    } else {
      domEl?.classList.remove('k-state-selected');
    }
  };

  const assetLocationSelected = (): void => {
    const domEl = document.getElementById('host-list');
    if (domEl && location.pathname.includes('/lk/assets/hosts')) {
      domEl?.classList.add('k-state-selected');
    } else {
      domEl?.classList.remove('k-state-selected');
    }
  };

  const sensorLocationSelected = (): void => {
    const domEl = document.getElementById('sensor-list');
    if (domEl && location.pathname.includes('/lk/sensors')) {
      domEl?.classList.add('k-state-selected');
    } else {
      domEl?.classList.remove('k-state-selected');
    }
  };

  const deviceLocationSelected = (): void => {
    const domEl = document.getElementById('devices');
    if (domEl && location.pathname.includes('/lk/assets/devices')) {
      domEl?.classList.add('k-state-selected');
    } else {
      domEl?.classList.remove('k-state-selected');
    }
  };

  const imageLocationSelected = (): void => {
    const domEl = document.getElementById('image-list');
    if (
      domEl &&
      (location.pathname.includes('/lk/assets/images') ||
        location.pathname.includes('/lk/assets/registries'))
    ) {
      domEl?.classList.add('k-state-selected');
    } else {
      domEl?.classList.remove('k-state-selected');
    }
  };

  const setSelectedItem = (
    pathName: string,
  ): null | { text: string; route: string | undefined } => {
    auditLocationSelected();
    const currentPath = ITEMS_MENU.find((item) => item.route === pathName);
    if (currentPath?.text)
      return {
        text: currentPath.text,
        route: currentPath.route,
      };

    return null;
  };

  const settingLocationSelected = (): void => {
    const domEl = document.getElementById('settings-list');
    if (
      domEl &&
      (location.pathname.includes('/lk/settings/license') ||
        location.pathname.includes('/lk/settings/update') ||
        location.pathname.includes('/lk/settings/system') ||
        location.pathname.includes('/lk/settings/smtp') ||
        location.pathname.includes('/lk/settings/tokens'))
    ) {
      domEl?.classList.add('k-state-selected');
    } else {
      domEl?.classList.remove('k-state-selected');
    }
  };

  const systemlogLocationSelected = (): void => {
    const domEl = document.getElementById('systemlog');
    if (
      domEl &&
      (location.pathname.includes('/lk/systemlog/users') ||
        location.pathname.includes('/lk/systemlog/tasks'))
    ) {
      domEl?.classList.add('k-state-selected');
    } else {
      domEl?.classList.remove('k-state-selected');
    }
  };

  const usersLocationSelected = (): void => {
    const domEl = document.getElementById('users-list');
    if (
      domEl &&
      (location.pathname.includes('/lk/users/list') ||
        location.pathname.includes('/lk/accounts') ||
        location.pathname.includes('/lk/users/roles') ||
        location.pathname.includes('/lk/users/servers'))
    ) {
      domEl?.classList.add('k-state-selected');
    } else {
      domEl?.classList.remove('k-state-selected');
    }
  };

  const credentialsLocationSelected = (): void => {
    const domEl = document.getElementById('credentials');
    if (
      domEl &&
      (location.pathname.includes('/lk/credentials/list') ||
        location.pathname.includes('/credentials/vaults'))
    ) {
      domEl?.classList.add('k-state-selected');
    } else {
      domEl?.classList.remove('k-state-selected');
    }
  };

  const whitelistLocationSelected = (): void => {
    const domEl = document.getElementById('whitelist');
    if (domEl && location.pathname.includes('/audit/exceptions')) {
      domEl?.classList.add('k-state-selected');
    } else {
      domEl?.classList.remove('k-state-selected');
    }
  };

  return {
    auditLocationSelected,
    setSelectedItem,
    groupLocationSelected,
    assetLocationSelected,
    sensorLocationSelected,
    imageLocationSelected,
    deviceLocationSelected,
    settingLocationSelected,
    systemlogLocationSelected,
    usersLocationSelected,
    credentialsLocationSelected,
    whitelistLocationSelected,
  };
}
