import { ChangeEvent, useEffect, useState } from 'react';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import locale from '../../../utils/i18n/filterComponent/inputFilter.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;
export function FilterInputInteger(props: TextFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  const [value, setValue] = useState<number | string>('');

  function isInteger(num: number): boolean {
    return num % 1 === 0;
  }

  useEffect(() => {
    setValue(filter.value || '');
  }, [filter.field]);

  const changeDate = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value.length === 0) {
      onFilterChange({
        nextFilter: { ...filter, ...{ value: '', text: '' } },
      });
      setValue('');
    }
    if (
      event.target.value &&
      event.target.value.length > 0 &&
      Number(event.target.value) >= 0 &&
      isInteger(Number(event.target.value))
    ) {
      onFilterChange({
        nextFilter: { ...filter, ...{ value: event.target.value, text: '' } },
      });
      setValue(Number(event.target.value));
    } else if (
      event.target.value &&
      event.target.value.length > 0 &&
      Number(event.target.value) < 0 &&
      isInteger(Number(event.target.value))
    ) {
      onFilterChange({
        nextFilter: { ...filter, ...{ value: '', text: '' } },
      });
      setValue('');
    }
  };

  return (
    <div className="example-wrapper">
      <div className="col-xs-12 col-sm-6 example-col">
        <input
          className="k-input"
          type="number"
          placeholder={locale[currentLocale].inputInteger}
          value={value}
          onChange={(e): void => changeDate(e)}
        />
      </div>
    </div>
  );
}
