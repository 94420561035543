import React, { useEffect, useState, type ReactElement } from 'react';
import {
  DropDownList,
  DropDownListChangeEvent,
  ListItemProps,
} from '@progress/kendo-react-dropdowns';
import { useParams } from 'react-router';
import { LoadingPanel } from '../../common/tableLoader/LoadingPanel';
import localeSensor from '../../../utils/i18n/sensorLocales/sensor.json';
import styles from '../../common/AssetDescription.module.scss';
import { useAssetCreatePropContext } from '../../../hooks/useAssetCreatePropContext';
import { Button } from '../../common/baseElements/Button';
import { useSensorContext } from '../../../hooks/useSensorContext';
import { useSensorActions } from '../../../hooks/components/useSensorActions';
import { useHostActions } from '../../../hooks/components/useHostActions';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSensor;

const itemRender = (
  li: React.ReactElement<HTMLLIElement>,
  itemProps: ListItemProps,
): ReactElement<HTMLLIElement, string | React.JSXElementConstructor<any>> => {
  const { index, dataItem } = itemProps;
  const itemChildren = (
    <span key={index}>
      <div>{dataItem.name || '-'}</div>
      <div style={{ fontSize: '11px' }}>
        {/* {dataItem.ip ? dataItem.ip : <i>{localeSensor[currentLocale].noIp}</i>} */}
        {dataItem.ip}
      </div>
    </span>
  );

  return React.cloneElement(li, li.props, itemChildren);
};

const listNoDataRender = (element: React.ReactElement<HTMLDivElement>): ReactElement => {
  const noData = <LoadingPanel />;

  return React.cloneElement(element, { ...element.props }, noData);
};

const mainServer = {
  name: localeSensor[currentLocale].mainServer,
  ip: '',
  sensorId: '',
};

export function SensorDropdownList({
  isTd,
  patchFunction,
  onChangeDropdown,
}: {
  isTd?: boolean;
  patchFunction?: (sensorId: string) => void;
  onChangeDropdown?: () => void;
}): ReactElement {
  const { sensorId } = useParams<{ sensorId: string }>();

  const { isShowConnectedBy, setShowConnectedBy } = useAssetCreatePropContext();
  const { editSensor } = useSensorActions();

  const {
    creationOpen,
    reinstallOpen,
    queryAllSensors,
    dropdownParentId,
    installerQuery,
    setAllAvailableSensors,
    setDropdownParentId,
  } = useSensorContext();
  // if (!allAvailableSensors.length) queryAllSensors.refetch();
  useEffect(() => {
    // if (!queryAllSensors.data?.data) queryAllSensors.refetch();
    queryAllSensors.refetch();

    return () => {
      setAllAvailableSensors([]);
    };
  }, []);

  return (
    <DropDownList
      data={queryAllSensors.data?.data.filter(
        (s) => s.sensorId !== sensorId && s.sensorId !== installerQuery.data?.sensor.sensorId,
      )}
      name="sensorList"
      // id="val"
      textField="name"
      defaultItem={mainServer}
      value={queryAllSensors.data?.data.find((s) => s.sensorId === dropdownParentId) || mainServer}
      opened={isShowConnectedBy}
      style={{ width: isTd ? '180px' : '100%' }}
      onChange={(event: DropDownListChangeEvent): void => {
        setShowConnectedBy(false);
        if (patchFunction) patchFunction(event.target.value.sensorId);
        if ((creationOpen || reinstallOpen) && installerQuery.data?.sensor.sensorId) {
          editSensor(
            installerQuery.data.sensor.sensorId,
            {
              parentId: event.target.value.sensorId || null,
            },
            true,
            true,
          );
        }
        if (onChangeDropdown) onChangeDropdown();
      }}
      onClose={(): void => setShowConnectedBy(false)}
      onOpen={(): void => setShowConnectedBy(true)}
      itemRender={itemRender}
      listNoDataRender={listNoDataRender}
    />
  );
}

export function SensorDropdownListTd({
  assetId,
  assetType,
  parentId,
}: {
  assetId: string | undefined;
  assetType: 'switch' | 'host' | 'router' | 'firewall' | 'hypervisor' | undefined;
  parentId?: string | null;
}): ReactElement {
  const { queryAllSensors, setDropdownParentId, setAllAvailableSensors } = useSensorContext();

  const { handleEditHost } = useHostActions();

  const patchFunction = (sensorId: string): void => {
    if (assetType)
      handleEditHost(assetId, {
        type: assetType,
        sensorId: sensorId || null,
      });
  };

  const { isShowConnectedBy, setShowConnectedBy } = useAssetCreatePropContext();

  useEffect(() => {
    queryAllSensors.refetch();
    setDropdownParentId(parentId || '');

    return () => {
      setAllAvailableSensors([]);
      setDropdownParentId('');
    };
  }, []);

  return !assetType ? (
    <td>-</td>
  ) : (
    <td className={styles.tdAssetMutableVals}>
      {!isShowConnectedBy ? (
        <Button
          fill="modal"
          onClick={(): void => {
            setShowConnectedBy(true);
          }}
        >
          {/* {value?.name || localeSensor[currentLocale].mainServer} */}
          {parentId
            ? queryAllSensors.data?.data.find((s) => s.sensorId === parentId)?.name || '-'
            : mainServer.name}
        </Button>
      ) : (
        <SensorDropdownList isTd patchFunction={patchFunction} />
      )}
      {/* <div className={styles.assetMutableValsSaved}>
        <span>test</span>
      </div> */}
    </td>
  );
}
