import { useCallback, useState } from 'react';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Dialog } from '@progress/kendo-react-dialogs';
import { hostname } from 'os';
import { Button } from '../../../common/baseElements/Button';
import styles from './AddAssetForm.module.scss';
import { useHostCreateContext } from '../../../../hooks/useHostCreateContext';
import {
  BACKEND_DOWNLOAD_URL,
  STEPPER_LINUX_ASSET,
  STEPPER_WINDOWS_ASSET,
} from '../../../../utils/helpers/constants';
import { HostNotification } from '../hostComponents/HostNotification';
import localeDashboardForm from '../../../../utils/i18n/dashboardLocale/addDashboardForm.json';
import localeWindowsForm from '../../../../utils/i18n/dashboardLocale/addWindowsAssetForm.json';
import { SensorDropdownList } from '../../Sensor/SensorDropdownList';
import { useHostActions } from '../../../../hooks/components/useHostActions';
import { getAccountId } from '../../../../services/local-storage-service';
import { useHostDescription } from '../../../../hooks/components/useHostDescription';

export function ReinstallHostForm(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeDashboardForm;

  const accountId = getAccountId();
  const { hostCreateData, setHostCreateData, handleLinkDowload } = useHostCreateContext();
  const { handleEditHost } = useHostActions();
  const { queryDashboardAssetId } = useHostDescription();

  const [step, setStep] = useState(0);

  const [bit, setBit] = useState('x64');

  const [copied, setCopied] = useState(false);

  const bits = ['x32', 'x64'];

  const handleCancel = (): void => {
    setHostCreateData(null);
  };

  const handleCopied = (e: number | StepperChangeEvent): void => {
    if (typeof e === 'number') {
      setStep(e);
      const timeout = setTimeout(() => {
        setCopied(false);
        clearTimeout(timeout);
      }, 900);
    } else {
      setStep(e.value);
    }
  };

  const handleDownloadBinary = (): void => {
    handleLinkDowload(`${BACKEND_DOWNLOAD_URL}/v1/agent/agent-windows-installer-${bit}-latest.msi`);
    setStep(1);
  };

  const handleDownloadConf = (): void => {
    setStep(2);
    handleLinkDowload(hostCreateData?.url);
  };

  const handleBit = useCallback((e: DropDownListChangeEvent): void => {
    const selectedBit = e.target.value as string;
    setBit(selectedBit);
  }, []);

  const patchFunction = (sensorId: string): void => {
    handleEditHost(
      hostCreateData?.assetId,
      { type: 'host', sensorId: sensorId || null },
      hostCreateData?.assetType === 'linux'
        ? {
            assetType: 'linux',
            accountId,
            hostname:
              queryDashboardAssetId?.data?.type === 'host'
                ? queryDashboardAssetId.data.latestInventorization?.hostname || ''
                : undefined,
          }
        : undefined,
    );
  };

  return (
    <>
      {hostCreateData?.assetType === 'linux' && hostCreateData && (
        <Dialog
          className="create-asset modal small"
          title={`${localeWindowsForm[currentLocale].agentLinModalTitle} ${hostCreateData.hostname}`}
          onClose={handleCancel}
        >
          <div className="create-linux-asset k-form">
            <div className="form-content">
              <div className="create-linux-asset__header" style={{ paddingTop: '8px' }}>
                <span> {localeDashboardForm[currentLocale].header.ulText}</span>
                <ul>
                  <li>
                    {localeDashboardForm[currentLocale].header.li1.text}{' '}
                    <i>{localeDashboardForm[currentLocale].header.li1.li1Italic}</i>
                  </li>
                  <li>{localeDashboardForm[currentLocale].header.li2}</li>
                </ul>
                <p>
                  {localeDashboardForm[currentLocale].header.content2}{' '}
                  <a href="#top">{localeDashboardForm[currentLocale].header.content2Link}</a>
                </p>
              </div>
              <div className="create-linux-asset__content">
                <div className={styles.asset_form}>
                  <Stepper
                    value={step}
                    onChange={handleCopied}
                    items={STEPPER_LINUX_ASSET}
                    orientation="vertical"
                    className="linux-install-agent-stepper"
                  />
                  <div className="asset-form__content">
                    <div className="asset-form__parent">
                      <p style={{ marginTop: '8px' }}>
                        {localeDashboardForm[currentLocale].form.connectingByField}:
                      </p>
                      <SensorDropdownList patchFunction={patchFunction} />
                    </div>
                    <div className="asset-form__download">
                      <p style={{ marginTop: '8px' }}>
                        {localeDashboardForm[currentLocale].form.step1.content1}
                      </p>
                      <Button
                        className="btn-download"
                        icon="download"
                        onClick={(): void => handleLinkDowload(hostCreateData.url)}
                      >
                        {localeDashboardForm[currentLocale].btnDownload}
                      </Button>
                      <p>{localeDashboardForm[currentLocale].form.step1.content2}</p>
                      <CopyToClipboard
                        text={`curl -k -O ${hostCreateData.url}`}
                        onCopy={(): void => setCopied(true)}
                      >
                        <div className="download-container code-container">
                          <span>curl -k -O {hostCreateData.url}</span>
                          <div className={styles.btn_block}>
                            <Button fill="copy" onClick={(): void => handleCopied(1)}>
                              {/* {localeDashboardForm[currentLocale].btnCopy} */}
                            </Button>
                            {step === 1 && <HostNotification copied={copied} />}
                          </div>
                        </div>
                      </CopyToClipboard>
                    </div>
                    <div className="asset-form__rights">
                      <p>{localeDashboardForm[currentLocale].form.step2}</p>
                      <CopyToClipboard
                        text={`sudo chmod u+x ${hostCreateData.url.split('/')[6]}`}
                        onCopy={(): void => setCopied(true)}
                      >
                        <div className="rights-container code-container">
                          <div className="text-container">
                            <span>sudo chmod u+x {hostCreateData.url.split('/')[6]}</span>
                          </div>
                          <div className={styles.btn_block}>
                            <Button fill="copy" onClick={(): void => handleCopied(3)}>
                              {/* {localeDashboardForm[currentLocale].btnCopy} */}
                            </Button>
                            {step === 3 && <HostNotification copied={copied} />}{' '}
                          </div>
                        </div>
                      </CopyToClipboard>
                    </div>
                    <div className="asset-form__rights">
                      <p>{localeDashboardForm[currentLocale].form.step3}</p>
                      <CopyToClipboard
                        text={`sudo ./${hostCreateData.url.split('/')[6]}`}
                        onCopy={(): void => setCopied(true)}
                      >
                        <div className="rights-container code-container">
                          <div className="text-container">
                            <span>sudo ./{hostCreateData.url.split('/')[6]}</span>
                          </div>
                          <div className={styles.btn_block}>
                            <Button fill="copy" onClick={(): void => handleCopied(4)}>
                              {/* {localeDashboardForm[currentLocale].btnCopy} */}
                            </Button>
                            {step === 4 && <HostNotification copied={copied} />}{' '}
                          </div>
                        </div>
                      </CopyToClipboard>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="k-form-buttons">
              <Button onClick={handleCancel}>{localeWindowsForm[currentLocale].closeModal}</Button>
            </div>
          </div>
        </Dialog>
      )}
      {hostCreateData?.assetType === 'windows' && hostCreateData && (
        <Dialog
          className="create-asset modal small"
          title={`${localeWindowsForm[currentLocale].agentWinModalTitle} ${hostCreateData.hostname}`}
          onClose={handleCancel}
        >
          <div className="create-windows-asset k-form">
            <div className="form-content">
              <div className="create-windows-asset__header" style={{ paddingTop: '8px' }}>
                <span> {localeWindowsForm[currentLocale].header.ulText}</span>
                <ul>
                  <li>{localeWindowsForm[currentLocale].header.li1} </li>
                  <li>{localeWindowsForm[currentLocale].header.li2}</li>
                  <li>{localeWindowsForm[currentLocale].header.li3}</li>
                </ul>
                <span>
                  {localeWindowsForm[currentLocale].header.span}{' '}
                  <a href="#top">{localeWindowsForm[currentLocale].header.spanLink}</a>
                </span>
              </div>
              <div className="create-windows-asset__content">
                <div className={styles.asset_form}>
                  <Stepper
                    value={step}
                    onChange={handleCopied}
                    items={STEPPER_WINDOWS_ASSET}
                    orientation="vertical"
                    className="windows-install-agent-stepper"
                  />
                  <div className="asset-form__content">
                    <div className="asset-form__parent">
                      <p style={{ marginTop: '8px' }}>
                        {localeWindowsForm[currentLocale].form.connectingByField}:
                      </p>
                      <SensorDropdownList patchFunction={patchFunction} />
                    </div>
                    <div className="asset-form__download">
                      <p style={{ marginTop: '8px' }}>
                        {localeWindowsForm[currentLocale].form.step0}
                      </p>
                      <DropDownList data={bits} value={bit} onChange={handleBit} />
                      <p>{localeWindowsForm[currentLocale].form.step1}</p>
                      <Button
                        className="btn-download"
                        icon="download"
                        onClick={handleDownloadBinary}
                      >
                        {localeWindowsForm[currentLocale].btnDownloadMsi} {bit}
                      </Button>
                      <p>{localeWindowsForm[currentLocale].form.step2}</p>
                      <Button className="btn-download" icon="download" onClick={handleDownloadConf}>
                        {localeWindowsForm[currentLocale].btnDownloadConf}
                      </Button>
                      <p>{localeWindowsForm[currentLocale].form.step3}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="create-windows-asset__actions"> */}
            </div>
            <div className="k-form-buttons">
              <Button onClick={handleCancel}>{localeWindowsForm[currentLocale].closeModal}</Button>
            </div>
          </div>
          {/* </div> */}
        </Dialog>
      )}
    </>
  );
}
