import type { GridCellProps } from '@progress/kendo-react-grid';
import type { TreeListCellProps } from '@progress/kendo-react-treelist';
import type { ReactElement } from 'react';
import {
  getStatusAgentName,
  setColorValAgent,
} from '../../../../utils/helpers/host-list-cell-helper';
import localeHost from '../../../../utils/i18n/assetLocales/hostList.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function CellSensorStatus(props: GridCellProps | TreeListCellProps): ReactElement {
  const { dataItem } = props;

  const colorValue = setColorValAgent(dataItem?.status);

  return (
    <td>
      {dataItem.status || dataItem.softwareVersion ? (
        <div title={dataItem.status && getStatusAgentName(dataItem.status, currentLocale)}>
          <div className="agent-status">
            <span
              style={{
                backgroundColor: colorValue[0],
              }}
            />
            {dataItem.softwareVersion || ''}
          </div>
        </div>
      ) : (
        <span>{localeHost[currentLocale].form.waitingForData}</span>
      )}
    </td>
  );
}
