import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { useCallback, useState, type ReactElement } from 'react';
import { useParams } from 'react-router';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import { AllUserLogList } from '../../Settings/Logs/AllUserLogList';
import { AllTaskLogList } from '../../Settings/Logs/AllTaskLogList';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

export function LogsTab(): ReactElement {
  const { accountId } = useParams<{ accountId: string }>();

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  return (
    <TabStrip
      selected={selected}
      onSelect={handleSelect}
      style={{
        marginTop: '4px',
      }}
    >
      <TabStripTab title={localeSettings[currentLocale].logs.tabs.userActions}>
        <AllUserLogList accountId={accountId} />
      </TabStripTab>
      <TabStripTab title={localeSettings[currentLocale].logs.tabs.taskLogs}>
        <AllTaskLogList accountId={accountId} />
      </TabStripTab>
    </TabStrip>
  );
}
