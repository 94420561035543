import { useCallback, useEffect, useRef, useState, type ReactElement } from 'react';
import { useParams } from 'react-router';
import { useMutation } from 'react-query';
import { Loader } from '@progress/kendo-react-indicators';
import { Button } from '../../common/baseElements/Button';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getSensorLogs } from '../../../services/sensor-service';
import {
  useWebSocketContext,
  type IWebSocketResultCommand,
} from '../../../hooks/useWebSocketContext';
import type { SensorLogsResponse } from '../../../types/__generated/on-premise-solution/api/sensorLogsResponse.v1';
import styles from '../Task/TaskDescription.module.scss';
import { useSensorContext } from '../../../hooks/useSensorContext';
import { useSensorDescription } from '../../../hooks/components/useSensorDescription';
import localeButtons from '../../../utils/i18n/commonLocales/buttons.json';

interface ILog {
  time: string;
  level: string;
  pid: number;
  message: string;
  caller: string;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeButtons;

export function LogsTab(): ReactElement {
  const { sensorId } = useParams<{ sensorId: string }>();
  const { sensorLogsInfo, sensorName, logs } = useSensorContext();
  const { commandLogsMutation } = useSensorDescription();

  const { ws } = useWebSocketContext();
  // const ws = useRef<WebSocket | null>(null);

  const logsChunkId = useRef<string>('');
  const [currentLogs, setCurrentLogs] = useState<ILog[]>([]);
  const childRef = useRef<HTMLHeadingElement>(null);

  // const query = useQuery<OnpremSensorCommandRequestListLogs, ApiError>(
  //   ['sensorLogs', sensorId],
  //   () => requestCentrifugoForSensorLogs(sensorId, 0, 30),
  //   {
  //     onSuccess: (resp) => {
  //       // sensorName.current = resp;
  //       console.log(resp);
  //       // hostname.current =
  //       //   resp.type === 'host' ? resp.latestInventorization?.hostname || undefined : undefined;
  //       // setAccountOfAsset(resp.account);
  //       // setMaintenanceWindowsList(resp.maintenanceWindows);
  //       // entityId.current = assetId;
  //       // entityType.current = 'asset';
  //       // entityName.current = hostname.current;
  //       // setSelectedAccountId(resp.accountId);
  //     },
  //     refetchOnWindowFocus: false,
  //     keepPreviousData: true,
  //     // enabled: document.location.pathname.includes('/assets/hosts/'),
  //   },
  // );

  // const commandMutation = useMutation<OnpremSensorCommandRequestListLogs, ApiError>(
  //   () =>
  //     requestCentrifugoForSensorLogs(
  //       sensorId,
  //       // { skip: 0, limit: 10 }
  //     ),
  //   {
  //     onSuccess: () => {},
  //   },
  // );

  const mutation = useMutation<SensorLogsResponse, ApiError>(
    () => getSensorLogs(sensorId, logsChunkId.current),
    {
      onSuccess: (resp) => {
        // console.log(resp);
        setCurrentLogs(
          resp.data.map((log) => {
            const data: ILog = JSON.parse(log as string);

            return data;
          }),
        );
        if (resp.data) logs.current.push(...(resp.data as string[]));
        console.log(logs);
      },
    },
  );

  const handleWebsocketMessage = useCallback((wsMessage) => {
    const data: IWebSocketResultCommand = JSON.parse(wsMessage?.data || '{}');
    const queryData = data?.result.data.data;
    if (queryData.success && 'logsChunkId' in queryData.result) {
      if (logsChunkId.current && logsChunkId.current !== queryData.result.logsChunkId)
        setCurrentLogs([]);
      logsChunkId.current = (queryData.result.logsChunkId as string) || '';
      mutation.mutateAsync();
      // .then(() => {
      //   ws.current?.removeEventListener('message', handleWebsocketMessage);
      // });
    }
  }, []);

  const exportLogs = (): void => {
    const fileData = logs.current
      .map((log) => {
        const parsedLog = JSON.parse(log);

        return `[${new Date(parsedLog.time).toLocaleString()}]: ${parsedLog.message}`;
      })
      .join('\n');
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `sensor_${sensorName.current || sensorId}_logs.txt`;
    link.href = url;
    link.click();
  };

  useEffect(() => {
    ws.current?.addEventListener('message', handleWebsocketMessage);

    const websocketMessage = ws.current;
    websocketMessage?.addEventListener('message', handleWebsocketMessage);

    return () => websocketMessage?.removeEventListener('message', handleWebsocketMessage);
  }, [ws, handleWebsocketMessage]);

  useEffect(() => {
    // if (sensorId !== sensorLogsInfo.current?.sensorId) commandMutation.mutateAsync();
    // ws.current = new WebSocket(WEBSOCKET_URL);
    // ws.current.onopen = async (): Promise<void> => {
    //   console.log('connection opened');
    // ws.current?.addEventListener('message', (message) => {});
    // commandMutation.mutateAsync();
    commandLogsMutation.mutateAsync({
      limit: 50,
      sortOrder: 'desc',
    });
    // };
    // ws.current.onmessage = (message): void => {
    // };
    // ws.current.onopen('connection', function connection(ws) {
    //   console.log('Соединение установлено');
    //   ws.on('message', function incoming(message) {
    //     console.log(`Получено сообщение: ${message}`);
    //   });
    //   ws.on('close', function close() {
    //     console.log('Соединение закрыто');
    //   });
    // });
  }, []);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '24px',
        }}
      >
        <Button
          icon="refresh"
          onClick={(): void => {
            logs.current = [];
            setCurrentLogs([]);
            commandLogsMutation.mutateAsync({
              limit: 50,
              sortOrder: 'desc',
            });
          }}
        >
          {localeButtons[currentLocale].refresh}
        </Button>
        <Button excel fill="flat" onClick={exportLogs}>
          {localeButtons[currentLocale].export}
        </Button>
      </div>
      <div className={styles.taskKbLog}>
        {/* <div className={styles.title}>
          {dataState &&
          dataState.current &&
          dataState.current[0] &&
          dataState.current[0].type === 'package_update' &&
          'command' in dataState.current[0] ? (
            <span>{dataState.current[0].command}</span>
          ) : (
            <span>{resultCommand.current}</span>
          )}
        </div> */}

        <div
          // ref={parentRef}
          className={styles.wrapper}
        >
          {/* {dataState && dataState.current ? (
            <div className={styles.content}>
              {dataState.current.map((d) => (
                <span key={nanoid()}>
                  {d.type === 'package_update' && 'output' in d && d.output}
                </span>
              ))}
            </div>
          ) : ( */}
          <div className={styles.content}>
            <span>
              {currentLogs.map((log) => (
                <div>
                  <span
                    style={{
                      fontWeight: 'bold',
                    }}
                  >
                    {new Date(log.time).toLocaleString()}
                  </span>
                  : {log.message}
                </div>
              ))}
            </span>
            {!logsChunkId.current && (
              <Loader style={{ color: '#555', marginLeft: '50%' }} size="large" />
            )}
          </div>
          {/* )} */}
          <div ref={childRef} style={{ padding: '4px', margin: '16px' }} />
        </div>
        <div
          style={{
            marginTop: '20px',
            display: 'flex',
            gap: '20px',
          }}
          className="buttons"
        >
          <Button
            onClick={(): void => {
              const cursor = JSON.stringify(currentLogs[0]);
              const cursorIndex = logs.current.findIndex((log) => cursor === log);
              if (cursorIndex) {
                setCurrentLogs(
                  logs.current
                    .slice(Math.max(0, cursorIndex - 51), Math.max(0, cursorIndex - 51) + 50)
                    .map((log) => JSON.parse(log)),
                );
              }
            }}
          >
            Prev
          </Button>
          <Button
            onClick={(): void => {
              commandLogsMutation.mutateAsync({
                limit: 50,
                sortOrder: 'desc',
                cursor: JSON.stringify(currentLogs[currentLogs.length - 1]),
              });
            }}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}
