/* eslint-disable react/jsx-pascal-case */
import { Dialog } from '@progress/kendo-react-dialogs';
import { useEffect, type ReactElement } from 'react';
import { Button } from '../../common/baseElements/Button';
import { useSensorContext } from '../../../hooks/useSensorContext';
import type localeDashboardForm from '../../../utils/i18n/dashboardLocale/addDashboardForm.json';
import localeButtons from '../../../utils/i18n/commonLocales/buttons.json';
import localeSensor from '../../../utils/i18n/sensorLocales/sensor.json';
import { CreateSensorBlock } from './CreateSensorBlock';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDashboardForm;

export function ReinstallSensorModal({ sensorId }: { sensorId: string }): ReactElement {
  const { sensorIdContext, installerQuery, setReinstallOpen, setDropdownParentId } =
    useSensorContext();

  useEffect(() => {
    installerQuery
      .mutateAsync(sensorId)
      .then((data) => setDropdownParentId(data.sensor.parentId || undefined));

    // setDropdownParentId(sensorId);

    return () => {
      sensorIdContext.current = undefined;
      installerQuery.reset();
    };
  }, []);

  return (
    <Dialog
      title={localeSensor[currentLocale].installModal.reinstallTitle}
      className="reinstall-sensor small"
      onClose={(): void => setReinstallOpen(undefined)}
    >
      <div className="create-linux-asset__content">
        <CreateSensorBlock data={installerQuery.data} isReinstall />
      </div>
      <div className="k-form-buttons">
        <Button
          onClick={
            // handleCancelSensor
            (): void => setReinstallOpen(undefined)
          }
        >
          {localeButtons[currentLocale].close}
        </Button>
      </div>
    </Dialog>
  );
}
