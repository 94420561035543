/* eslint-disable react/jsx-pascal-case */
import { useState, type ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Typography } from '@progress/kendo-react-common';
import { useQueryClient } from 'react-query';
import { Switch, SwitchChangeEvent } from '@progress/kendo-react-inputs';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import localeSensor from '../../../utils/i18n/sensorLocales/sensor.json';
import localeButtons from '../../../utils/i18n/commonLocales/buttons.json';
import { Button } from '../../common/baseElements/Button';
import { IOpenedFromComponentInfo, useSensorContext } from '../../../hooks/useSensorContext';
import { DeleteConfirmModal } from '../../common/modal/DeleteConfirmModal';
import { useSensorActions } from '../../../hooks/components/useSensorActions';
import { SensorTreeList } from './SensorTreeList';
import { SensorClassicList } from './SensorClassicList';
import styles from './Sensor.module.scss';
import { SensorEditModal } from './SensorEditModal';
import { SensorNotEmptyModal } from './SensorNotEmptyModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSensor;

export function SensorList(): ReactElement {
  const queryClient = useQueryClient();
  const {
    editOpen,
    sensorIdContext,
    sensorName,
    deleteOpen,
    isSensorNotEmptyModal,
    setDeleteOpen,
  } = useSensorContext();
  const { deleteSensor } = useSensorActions();

  const [isTreeView, setTreeView] = useState<boolean>(false);
  const openedFromInfo: IOpenedFromComponentInfo = {
    rootComponentName: isTreeView ? 'SensorTreeList' : 'SensorClassicList',
  };

  const { createSensor } = useSensorActions();

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      {editOpen && <SensorEditModal />}
      {isSensorNotEmptyModal && <SensorNotEmptyModal />}
      {deleteOpen && (
        <DeleteConfirmModal
          type="sensor"
          onClose={(): void => setDeleteOpen(undefined)}
          handleDelete={(): void => {
            if (sensorIdContext.current)
              deleteSensor(sensorIdContext.current, () => {
                setDeleteOpen(undefined);
                queryClient.invalidateQueries(isTreeView ? 'sensorTreeList' : 'sensorList');
              });
          }}
          name={sensorName.current}
        />
      )}
      <div className="sensor-list">
        <div className="common-header-page">
          <Typography.h3>{localeSensor[currentLocale].mainList.title}</Typography.h3>
          <div className={styles.sensorButtonBlock}>
            <div className="topactions">
              <Button
                fill="action"
                onClick={(): void => {
                  createSensor(undefined, openedFromInfo);
                }}
              >
                {localeSensor[currentLocale].mainList.addBtn}
              </Button>
              <Button
                icon="refresh"
                onClick={(): void => {
                  queryClient.invalidateQueries(isTreeView ? 'sensorTreeList' : 'sensorList');
                }}
              >
                {localeButtons[currentLocale].refresh}
              </Button>
            </div>
            <div className={styles.viewSwitch}>
              {localeSensor[currentLocale].classicView}
              <Switch
                size="small"
                onLabel=""
                offLabel=""
                checked={isTreeView}
                onChange={(e: SwitchChangeEvent): void => setTreeView(e.target.value)}
              />
              {localeSensor[currentLocale].treeView}
            </div>
          </div>
        </div>
        {isTreeView ? <SensorTreeList /> : <SensorClassicList />}
      </div>
    </ErrorBoundary>
  );
}
