import '../../../Host/hostComponents/HostDescription.module.scss';
// import localeHostDescription from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import { NavLink } from 'react-router-dom';
import localeMaintenance from '../../../../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';
import type { AssetRouterDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetRouterDashboardResponse.v1';
import type {
  AssetDeviceDashboardResponse,
  AssetHypervisorDashboardResponse,
} from '../../../../../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import type { AssetSwitchDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetDeviceDashboardResponse.v1';
import { MaintenanceTd } from '../../../Host/MaintenanceWindows/MaintenanceTableListButton';
import localeSensor from '../../../../../utils/i18n/sensorLocales/sensor.json';
import { SensorDropdownListTd } from '../../../Sensor/SensorDropdownList';

export function TableFourthDeviceMetaBlock({
  data,
  currentLocale,
}: {
  data:
    | AssetRouterDashboardResponse
    | AssetDeviceDashboardResponse
    | AssetSwitchDashboardResponse
    | AssetHypervisorDashboardResponse
    | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  return (
    <table className="column">
      <tbody>
        <tr>
          <td>{localeMaintenance[currentLocale].maintenanceWindows}</td>
          <MaintenanceTd />
        </tr>
        <tr>
          <td>{localeSensor[currentLocale].mainList.connectedTo}</td>
          {/* <SensorDropdownListTd connectedById={data?.sensorId} /> */}
          {/* <td>
            {data?.sensor?.sensorId ? (
              <NavLink to={`/lk/sensors/${data?.sensor?.sensorId}`}>{data?.sensor.name}</NavLink>
            ) : (
              localeSensor[currentLocale].mainServer
            )}
          </td> */}
          <SensorDropdownListTd
            assetType={data?.type}
            assetId={data?.assetId}
            parentId={data?.sensor?.sensorId}
          />
        </tr>
        {/* <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.added}</span>
          </td>
          <td>
            <span>{new Date(String(data?.createdAt)).toLocaleString()}</span>{' '}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.addedBy}</span>
          </td>
          <td>
            {data?.createdBy.source === 'user' ? (
              <span>{data?.createdBy.name}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr> */}
      </tbody>
    </table>
  );
}
