/* eslint-disable react/jsx-pascal-case */
import { Typography } from '@progress/kendo-react-common';
import {
  Grid,
  GridColumn,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import { IntlProvider, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import { useQuery } from 'react-query';
import { useCallback, useState, useMemo, useRef, useEffect } from 'react';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { useLocation } from 'react-router';
import { ScoreCvssCell } from '../../common/cells/ScoreCvssCell';
import { CellSecurityCwe } from '../Security/cells/CellSecurityCwe';
import { CellSecurityTitle } from '../Security/cells/CellSecurityTitle';
import { CellSecurityVulnsId } from '../Security/cells/CellSecurityVulnsId';
import { CellPublished } from '../Task/taskVmDetails/vmDetailsComponents/cells/CellVulnerabilitiesListPublished';
import { CellVulnListAlias } from '../Task/taskVmDetails/vmDetailsComponents/cells/CellVulnListAlias';
import styles from './VDB.module.scss';
import locale from '../../../utils/i18n/security/vulnerabilities.json';
import localeAuditTask from '../../../utils/i18n/taskLocale/auditTask.json';
import localeVulnDatabase from '../../../utils/i18n/taskLocale/vulnDatabase.json';
import { useAuditModalContext } from '../../../hooks/useAuditModalContext';
import { VulnModal } from '../../common/modal/vulnModal/VulnModal';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import type { BulletinsMetaWidgetResponse } from '../../../types/__generated/on-premise-solution/api/bulletinsMetaWidgetResponse.v1';
import type { BulletinCountGroupedByTypeWidget } from '../../../types/__generated/on-premise-solution/api/bulletinCountGroupedByTypeWidget.v1';
import type { BulletinCountPerMonthWidget } from '../../../types/__generated/on-premise-solution/api/bulletinCountPerMonthWidget.v1';
import {
  getMetaWidgets,
  getCountByTypeWidget,
  getCountPerMonthWidget,
  getBulletinsPost,
} from '../../../services/vdb-service';
import type { DashboardBulletinsResponse } from '../../../types/__generated/on-premise-solution/api/dashboardBulletinsResponse.v1';
import type { IPageState } from '../../../utils/helpers/types';
import { FILTER_INITIAL, PAGEABLE_DATA } from '../../../utils/helpers/constants';
import { handleFilteringTest, IFilterVal } from '../../../utils/filtering-in-table-test';
import { usePageChange } from '../../../hooks/components/usePageChange';
import { getUrlVulnRelated } from '../../../utils/helpers/vuln-related-list-helper';
import { handleSort } from '../../../utils/sorting-in-table';
import { collectSecurityVulns } from '../../../utils/helpers/security-vulns-helper';
import { Button } from '../../common/baseElements/Button';
import { StandartDashboard } from './StandartDashboard';
import { CountsByTypeDashboard } from './CountsByTypeDashboard';
import { CountsByPerMonthDashboard } from './CountsByPerMonthDashboard';
import { wordDeclension } from '../../../lib/axios/helpers';
import { FILTER_VDB } from '../../../utils/helpers/constant-serv-filter-task';
import { Input } from '../../common/baseElements/Input';
import { useFilter } from '../../../hooks/components/useFilter';
import { useCustomSearch } from '../../../hooks/components/useCustomSearch';
import { handleError } from '../../../utils/errors';
import { useNotificationContext } from '../../../hooks/useNotificationContext';
import { AddVulnToWLForm } from '../WhiteList/form/AddVulnToWLForm';
import { useAssetCreatePropContext } from '../../../hooks/useAssetCreatePropContext';
import { LoadingPanel } from '../../common/tableLoader/LoadingPanel';
import { AddWhiteListForm } from '../WhiteList/form/AddWhiteListForm';
import { useWhiteListContext } from '../../../hooks/useWhiteListContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

loadMessages(locale[currentLocale], currentLocale);

const dataForHooks = {
  name: 'bulletinId',
  componentName: 'VDB',
};

export function VdbList(): React.ReactElement {
  const urlParams = getUrlVulnRelated(dataForHooks.componentName);
  const location = useLocation();
  const { cveName } = useAuditModalContext();

  const { createNotification } = useNotificationContext();

  const [page, setPage] = useState<IPageState>(urlParams.page);
  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);
  // const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);
  const [sort, setSort] = useState<SortDescriptor[]>([{ field: 'date.published', dir: 'desc' }]);
  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);
  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const [filterStatus, setFilterStatus] = useState(false);
  const [customSearch, setCustomSearch] = useState('');
  const filterValue = useRef<CompositeFilterDescriptor | null>(null);
  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  const queryWidgetsMeta = useQuery<BulletinsMetaWidgetResponse, ApiError>(['metaWidgets'], () =>
    getMetaWidgets(),
  );

  const queryCountByTypeWidget = useQuery<BulletinCountGroupedByTypeWidget, ApiError>(
    ['byTypeWidget'],
    () => getCountByTypeWidget(),
  );

  const queryCountPerMonthWidget = useQuery<BulletinCountPerMonthWidget, ApiError>(
    ['perMonthWidget'],
    () => getCountPerMonthWidget(),
  );

  const query = useQuery<DashboardBulletinsResponse, ApiError>(
    ['vdbBulletinList', page, filterVal, sort],
    () => getBulletinsPost(page, filterVal, sort),
  );

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter.filters, page.skip, location]);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [
    query.isError,
    query.error,
    queryWidgetsMeta.isError,
    queryWidgetsMeta.error,
    queryCountByTypeWidget.isError,
    queryCountByTypeWidget.error,
    queryCountPerMonthWidget.isError,
    queryCountPerMonthWidget.error,
    createNotification,
  ]);

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const handleRefresh = useCallback((): void => {
    queryWidgetsMeta.refetch();
    queryCountByTypeWidget.refetch();
    queryCountPerMonthWidget.refetch();
    query.refetch();
  }, [queryWidgetsMeta, queryCountByTypeWidget, queryCountPerMonthWidget, query]);

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  const securityVulns = collectSecurityVulns(query.data);

  const countsByTypeData = queryCountByTypeWidget.data
    ? [
        {
          name: currentLocale === 'ru-RU' ? 'ФСТЭК' : 'FSTEC',
          count: queryCountByTypeWidget.data.fstec,
          explode: true,
        },
        {
          name: 'Advisory',
          count: queryCountByTypeWidget.data.advisory,
          explode: true,
        },
        {
          name: 'CVE',
          count: queryCountByTypeWidget.data.cve,
          explode: true,
        },
        {
          name: 'KB',
          count: queryCountByTypeWidget.data.kb,
          explode: true,
        },
      ]
    : [];
  const countsPerMonthData = queryCountPerMonthWidget.data?.map((monthData) => {
    return {
      name: localeVulnDatabase[currentLocale].charts.byMonth[
        monthData.name.slice(0, 3) as
          | 'jan'
          | 'feb'
          | 'mar'
          | 'apr'
          | 'may'
          | 'jun'
          | 'jul'
          | 'aug'
          | 'sep'
          | 'oct'
          | 'nov'
          | 'dec'
      ],
      count: monthData.count,
      total: monthData.total,
    };
  });

  const { isAddVulnToWLForm } = useAssetCreatePropContext();

  const { isWhiteListForm } = useWhiteListContext();

  return (
    <div className="vdb-list">
      <div className="common-header-page">
        <Typography.h3>{localeVulnDatabase[currentLocale].title}</Typography.h3>
        <div className="topactions">
          <Button icon="refresh" onClick={handleRefresh}>
            {localeVulnDatabase[currentLocale].refresh}
          </Button>
        </div>
      </div>
      <div className={styles.dashboard_content}>
        {useMemo(() => {
          return (
            <>
              <StandartDashboard
                count={queryWidgetsMeta.data?.counts.bulletins}
                label={
                  currentLocale === 'ru-RU'
                    ? wordDeclension(
                        queryWidgetsMeta.data?.counts.bulletins,
                        localeVulnDatabase[currentLocale].dashboards.vulns,
                      )
                    : localeVulnDatabase[currentLocale].dashboards.vulns
                }
              />
              <StandartDashboard
                count={queryWidgetsMeta.data?.counts.updateChannels}
                label={
                  currentLocale === 'ru-RU'
                    ? wordDeclension(
                        queryWidgetsMeta.data?.counts.updateChannels,
                        localeVulnDatabase[currentLocale].dashboards.updateChannels,
                      )
                    : localeVulnDatabase[currentLocale].dashboards.updateChannels
                }
              />
              <StandartDashboard
                count={queryWidgetsMeta.data?.counts.bulletinSources}
                label={
                  currentLocale === 'ru-RU'
                    ? wordDeclension(
                        queryWidgetsMeta.data?.counts.bulletinSources,
                        localeVulnDatabase[currentLocale].dashboards.sources,
                      )
                    : localeVulnDatabase[currentLocale].dashboards.sources
                }
              />
              <StandartDashboard
                count={[
                  queryWidgetsMeta.data?.counts.newBulletinsPerDay,
                  queryWidgetsMeta.data?.counts.newBulletinsPerWeek,
                ]}
                label={[
                  localeVulnDatabase[currentLocale].dashboards.perDay,
                  localeVulnDatabase[currentLocale].dashboards.perWeek,
                ]}
              />
              <StandartDashboard
                count={
                  queryWidgetsMeta.data?.latestBulletinsUpdate
                    ? new Date(queryWidgetsMeta.data.latestBulletinsUpdate).toLocaleString()
                    : '-'
                }
                label={localeVulnDatabase[currentLocale].dashboards.lastUpdate}
              />
            </>
          );
        }, [queryWidgetsMeta.data])}
      </div>
      <div className="charts" style={{ display: 'flex', gap: '12px' }}>
        {/* <CountsByTypeDashboard data={countsByTypeData} /> */}
        {/* <CountsByPerMonthDashboard data={countsPerMonthData} /> */}
        {useMemo(() => {
          return <CountsByTypeDashboard data={countsByTypeData} />;
        }, [queryCountByTypeWidget.data])}
        {useMemo(() => {
          return <CountsByPerMonthDashboard data={countsPerMonthData} />;
        }, [queryCountPerMonthWidget.data])}
      </div>
      <div className={styles.vdbList}>
        {query.isLoading && <LoadingPanel />}
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter value={filter} onChange={onFilterChange} fields={FILTER_VDB[currentLocale]} />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={locale[currentLocale].customSearchName}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
            <Grid
              data={securityVulns}
              pageable={PAGEABLE_DATA}
              sortable
              sort={sort}
              skip={page.skip}
              take={page.take}
              total={query?.data?.total}
              onPageChange={pageChange}
              onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
              scrollable="none"
            >
              <GridColumn
                title={locale[currentLocale].vulnsTable.cvssScore}
                filter="text"
                field="maxScore"
                width="96px"
                cell={ScoreCvssCell}
                sortable={false}
                headerClassName="no-cursor"
              />
              <GridColumn
                title={locale[currentLocale].vulnsTable.id}
                filter="text"
                field="bulletinId"
                // width="140px"
                cell={CellSecurityVulnsId}
                sortable={false}
                headerClassName="no-cursor"
                width="220px"
              />
              <GridColumn
                field="aliases"
                title={localeAuditTask[currentLocale].vulnsList.table.aliases}
                cell={CellVulnListAlias}
                width="220px"
                // width={query.data?.data.find((a) => a.aliases) ? '180px' : '160px'}
                sortable={false}
                headerClassName="no-cursor"
              />
              <GridColumn
                title={locale[currentLocale].vulnsTable.title}
                filter="text"
                field="title"
                // width="140px"
                cell={CellSecurityTitle}
                sortable={false}
                headerClassName="no-cursor"
              />
              <GridColumn
                field="cwe"
                title={locale[currentLocale].vulnsTable.cwe}
                // width={}
                cell={CellSecurityCwe}
                sortable={false}
                headerClassName="no-cursor"
                width="440px"
              />
              {/* <GridColumn
            field="vector"
            title={locale[currentLocale].vulnsTable.cvssVector}
            width="320px"
            filter="text"
            cell={CellSecurityVector}
            sortable={false}
            headerClassName="no-cursor"
          /> */}
              <GridColumn
                title={locale[currentLocale].vulnsTable.published}
                width="152px"
                filter="text"
                field="date.published"
                cell={CellPublished}
                sortable={false}
                headerClassName="no-cursor"
              />
              {/* <GridColumn
            title={locale[currentLocale].vulnsTable.hosts}
            width="88px"
            filter="text"
            field="totalAssets"
            cell={CellSecurityAsset}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            title={locale[currentLocale].vulnsTable.totalImageAssets}
            width="96px"
            filter="text"
            field="totalImageAssets"
            cell={CellSecurityImage}
            sortable={false}
            headerClassName="no-cursor"
          />
          <GridColumn
            title={locale[currentLocale].vulnsTable.netDevices}
            filter="text"
            field="totalRouterAssets"
            cell={CellSecurityNetDev}
            width="128px"
            sortable={false}
            headerClassName="no-cursor"
          /> */}
            </Grid>
          </IntlProvider>
        </LocalizationProvider>
      </div>
      {cveName && <VulnModal />}
      {isAddVulnToWLForm && <AddVulnToWLForm />}
      {isWhiteListForm && !isAddVulnToWLForm && <AddWhiteListForm />}
    </div>
  );
}
