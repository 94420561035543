/* eslint-disable react/jsx-props-no-spreading */
import { useLocation, useParams } from 'react-router-dom';
import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { DeviceMetaBlock } from './DeviceMetaBlock';
import { TabsDeviceDescription } from './TabsDeviceDescription';
import styles from '../../Host/hostComponents/HostDescription.module.scss';
import { useCreateTask } from '../../../../hooks/components/useCreateTask';
import localeDevices from '../../../../utils/i18n/devices/devices.json';
import localeHostDescription from '../../../../utils/i18n/assetLocales/hostDescription.json';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { EditPortModal } from '../../../common/modal/EditPortModal';
import { useDifferenceModalContext } from '../../../../hooks/useDifferenceModalContext';
import { DifferenceAuditModal } from '../../Host/DifferenceModal/Audit/DifferenceAuditModal';
import { DifferenceInvModal } from '../../Host/DifferenceModal/Inventorization/DifferenceInvModal';
import { useDeviceDescription } from '../../../../hooks/components/useDeviceDescription';
import { getUserData } from '../../../../services/local-storage-service';
import { Button } from '../../../common/baseElements/Button';
import { HostConfirmDel } from '../../Host/hostComponents/HostConfirmDel';
import { useNetDeviceContext } from '../../../../hooks/useNetDeviceContext';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';
import { MaintenanceListModal } from '../../Host/MaintenanceWindows/MaintenanceListModal';
import { useMaintenanceWindowsContext } from '../../../../hooks/useMaintenanceWindowsContext';
import { useHostActions } from '../../../../hooks/components/useHostActions';
import type { MaintenanceWindowsField } from '../../../../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';
import { EntityDescription } from '../../../templates/EntityDescription';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDevices;

export function DeviceDescription(): React.ReactElement {
  const queryClient = useQueryClient();
  const { assetId } = useParams<{ assetId: string }>();

  const { devicename } = useDeviceDescription();

  const { createReportDevice } = useCreateTask();

  const { setDeviceForm, deviceForm } = useNetDeviceContext();

  const { pathname } = useLocation();

  const assetType: 'firewall' | 'router' | 'switch' | 'hypervisor' = pathname
    .replace('/lk/assets/devices/', '')
    .split('/')[0] as 'firewall' | 'router' | 'switch' | 'hypervisor';

  const {
    isAuditOpen: isDiffAuditOpen,
    setAuditOpen: setDiffAuditOpen,
    isInvOpen: isDiffInvOpen,
    setInvOpen: setDiffInvOpen,
  } = useDifferenceModalContext();

  const currentUser = getUserData();

  const [isConfirm, setConfirm] = useState(false);

  const { queryDashboardAssetId, queryInventorizationId } = useDeviceDescription();

  const { createTaskForAsset } = useCreateTask();

  const { isEditPort } = useHostModalContext();
  const { mutation } = useHostActions();

  const { isTableOpen, entityId, entityName, entityType, setPatchFunction, closeWindow } =
    useMaintenanceWindowsContext();

  const { setSelectedVulns } = useAssetCreatePropContext();

  const onSelect = (e: MenuSelectEvent): void => {
    if (e.item.data === 'audit') setDiffAuditOpen(true);
    else if (e.item.data === 'inventorization') setDiffInvOpen(true);
  };

  useEffect(() => {
    if (devicename && !deviceForm) setDeviceForm([{ assetId, hostname: devicename }]);
  }, [assetId, devicename, setDeviceForm, deviceForm]);

  useEffect(() => {
    entityId.current = assetId;
    entityType.current = 'asset';
    setPatchFunction((maintenancePayload: MaintenanceWindowsField | undefined): void => {
      mutation
        .mutateAsync({
          payloadAssetId: assetId,
          payload: {
            type: assetType,
            maintenanceWindows: maintenancePayload,
          },
        })
        .then(() => {
          queryClient.invalidateQueries('deviceAssetId');
          closeWindow();
        });
    });

    return () => {
      entityId.current = undefined;
      entityType.current = undefined;
      entityName.current = undefined;
      setPatchFunction(undefined);
      setDeviceForm(null);
      setSelectedVulns(null);
    };
  }, []);

  return (
    <EntityDescription
      isLoading={queryDashboardAssetId.isLoading}
      isNotFound={queryDashboardAssetId.error?.code === '404'}
      entity={{
        id: assetId,
        type: 'device',
        name: devicename,
      }}
      topactions={
        <>
          <div className={`button-group ${styles.host_group}`}>
            <Button
              className="action"
              onClick={(): void => {
                createTaskForAsset({
                  val: 'inventorization',
                  data: queryDashboardAssetId.data,
                  assetId: queryDashboardAssetId.data?.assetId,
                });
              }}
            >
              {localeDevices[currentLocale].btnCreateTask}
            </Button>
            <Button onClick={(): void => createReportDevice(assetId, devicename || '-')}>
              {localeDevices[currentLocale].btnCreateReport}
            </Button>
          </div>
          <div>
            <Menu hoverOpenDelay={0} onSelect={onSelect} className="btn-menu">
              <MenuItem text={localeHostDescription[currentLocale].changes.differenceControl}>
                <MenuItem
                  text={localeHostDescription[currentLocale].changes.auditTitle}
                  data="audit"
                />
                <MenuItem
                  text={localeHostDescription[currentLocale].changes.invTitle}
                  data="inventorization"
                />
              </MenuItem>
            </Menu>
          </div>
          {currentUser?.role !== 'reader_user' && (
            <Button onClick={(): void => setConfirm(true)} fill="delete">
              {localeHostDescription[currentLocale].btnDelete}
            </Button>
          )}
        </>
      }
      className="devicedescription"
    >
      {isConfirm && <HostConfirmDel setConfirm={setConfirm} assetName={devicename || assetId} />}
      {isTableOpen && <MaintenanceListModal assetId={assetId} />}
      {isDiffAuditOpen && (
        <DifferenceAuditModal
          deviceType={queryDashboardAssetId.data?.type}
          system={
            queryDashboardAssetId.data?.latestInventorization.os
              ? `${queryDashboardAssetId.data?.latestInventorization.os?.name} ${queryDashboardAssetId.data?.latestInventorization.os?.version}`
              : '-'
          }
          ip={queryDashboardAssetId.data?.latestInventorization.ip}
        />
      )}
      {isDiffInvOpen && (
        <DifferenceInvModal
          deviceType={queryDashboardAssetId.data?.type}
          system={
            queryDashboardAssetId.data?.latestInventorization.os
              ? `${queryDashboardAssetId.data?.latestInventorization.os?.name} ${queryDashboardAssetId.data?.latestInventorization.os?.version}`
              : '-'
          }
          ip={queryDashboardAssetId.data?.latestInventorization.ip}
        />
      )}
      {queryDashboardAssetId.data && <DeviceMetaBlock {...queryDashboardAssetId} />}
      <TabsDeviceDescription
        auditId={
          (queryDashboardAssetId.data?.latestAudit &&
            queryDashboardAssetId.data?.latestAudit.auditId &&
            queryDashboardAssetId.data?.latestAudit.auditId) ||
          ''
        }
        invData={queryInventorizationId.data}
        hostName={queryDashboardAssetId.data?.latestInventorization?.hostname}
        inventorizationId={queryDashboardAssetId.data?.latestInventorization?.inventorizationId}
        maxVFstec={queryDashboardAssetId.data?.latestAudit?.maxVFstec}
      />
      {isEditPort && (
        <EditPortModal
          assetId={assetId}
          assetType={assetType}
          sshPortCurrent={queryDashboardAssetId.data?.sshPort}
        />
      )}
    </EntityDescription>
  );
}
