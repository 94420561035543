import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { useState, type ReactElement } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import type { AccountsDashboardResponse } from '../../../types/__generated/on-premise-solution/api/accountsDashboardResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getAccountsForList } from '../../../services/account-service';
import { getAccountId, getAccountName, getUserData } from '../../../services/local-storage-service';
import localeAccountList from '../../../utils/i18n/accountLocales/accountList.json';
import { useSoftwareManagementContext } from '../../../hooks/useSoftwareManagementContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccountList;

export function SoftwareAccountsDropDownLst({
  mainParentName,
}: {
  mainParentName?: string;
}): ReactElement {
  const currentUser = getUserData();
  const queryClient = useQueryClient();

  const { selectedAccountId, setSelectedAccountId } = useSoftwareManagementContext();

  const [accountList, setAccountList] = useState<{ text: string; id: string }[]>([]);

  const query = useQuery<AccountsDashboardResponse, ApiError>(
    ['accountList'],
    () => getAccountsForList(),
    {
      keepPreviousData: true,
      enabled: currentUser?.role === 'super_admin',
      onSuccess: (resp) => {
        setAccountList(
          resp.data.map((accountInfo) => ({ text: accountInfo.name, id: accountInfo.accountId })),
        );
      },
    },
  );

  const accountValue = accountList.filter((accountInfo) => accountInfo.id === selectedAccountId);

  return currentUser?.role === 'super_admin' && mainParentName !== 'AccountDescription' ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        padding: '0 12px',
      }}
    >
      <span>{localeAccountList[currentLocale].titleForColumnGrid.name}:</span>
      <DropDownList
        style={{
          width: '180px',
        }}
        value={
          accountValue.length ? accountValue[0] : { text: getAccountName(), id: getAccountId() }
        }
        onChange={(event: DropDownListChangeEvent): void => {
          setSelectedAccountId(event.target.value.id);
          queryClient.invalidateQueries('softwareInfo');
        }}
        textField="text"
        data={accountList}
      />
    </div>
  ) : (
    <></>
  );
}
