/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect } from 'react';
import { loadMessages } from '@progress/kendo-react-intl';
import { Typography } from '@progress/kendo-react-common';
import { ErrorBoundary } from 'react-error-boundary';
import { useQueryClient } from 'react-query';
import locale from '../../../utils/i18n/security/vulnerabilities.json';
import { TabsVulnsDetails } from './TabsVulnsDetails';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import { Button } from '../../common/baseElements/Button';
import { useAssetCreatePropContext } from '../../../hooks/useAssetCreatePropContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

loadMessages(locale[currentLocale], currentLocale);
export function VulnerabilitiesDetails(): React.ReactElement {
  const queryClient = useQueryClient();

  const handleRefresh = useCallback((): void => {
    queryClient.invalidateQueries('SecurityVulnsList');
  }, [queryClient]);

  const { setSelectedVulns } = useAssetCreatePropContext();

  useEffect(() => {
    return () => setSelectedVulns(null);
  }, []);

  return (
    <div className="security-description">
      <div className="common-header-page">
        <Typography.h3 className="title">
          <span>{locale[currentLocale].title}</span>
        </Typography.h3>
        <div className="topactions">
          <Button icon="refresh" onClick={handleRefresh}>
            {locale[currentLocale].btnRefresh}
          </Button>
        </div>
      </div>
      <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
        <TabsVulnsDetails />
      </ErrorBoundary>
    </div>
  );
}
