/* eslint-disable react/jsx-props-no-spreading */
import { Filter } from '@progress/kendo-react-data-tools';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Grid, GridColumn as Column, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { BACKEND_BASE_URL, PAGEABLE_DATA } from '../../utils/helpers/constants';
import localeTaskDescription from '../../utils/i18n/taskLocale/taskDescription.json';
import type { ICommonLogs } from '../../utils/helpers/types';
import { handleSort } from '../../utils/sorting-in-table';
import { СommonTableLoader } from './tableLoader/CommonTableLoader';
import { FILTER_ALL_TASK_LOGS, FILTER_LOGS } from '../../utils/helpers/constant-serv-filter-task';
import { CellCreatedAt } from '../features/Task/taskDescriptionComponents/cellResult/CellCreatedAt';
import { CellHostname } from '../features/Task/taskDescriptionComponents/cellResult/CellHostName';
import { CellActionLogs } from '../features/Task/taskDescriptionComponents/cellResult/CellActionLogs';
import { CellMessage } from '../features/Task/taskDescriptionComponents/cellResult/CellMessage';
import { CellTaskname } from '../features/Task/taskDescriptionComponents/cellResult/CellTaskName';
import { Input } from './baseElements/Input';
import { ActionHeaderCell } from '../features/Task/cellsTaskList/ActionHeaderCell';
import { CellLogsMenu } from '../features/Task/taskDescriptionComponents/cellResult/CellLogsMenu';
import { Button } from './baseElements/Button';
import { saveLogList2Json } from '../../utils/helpers/task-helpers';
import styles from './CommonLogs.module.scss';
import { useHostCreateContext } from '../../hooks/useHostCreateContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTaskDescription;

export function CommonLogs(props: ICommonLogs): React.ReactElement {
  const {
    filter,
    onFilterChange,
    customSearch,
    handleCustomSearch,
    page,
    sort,
    data,
    pageChange,
    filterStatus,
    isLoading,
    setSort,
    componentName,
    taskExecutionId,
  } = props;

  const { handleLinkDowload } = useHostCreateContext();

  const onSelect = async (e: MenuSelectEvent): Promise<void> => {
    if (e.itemId === '0_0') {
      saveLogList2Json(data?.data, data?.data[0].taskId);
    }
    if (e.itemId === '0_1') {
      const URL = `${BACKEND_BASE_URL}/task/executions/${taskExecutionId}/logs/archive.zip`;
      handleLinkDowload(URL);
    }
  };

  return (
    <div className={styles.commonLogWrap}>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={
                componentName === 'AllTaskLogs'
                  ? FILTER_ALL_TASK_LOGS[currentLocale]
                  : FILTER_LOGS[currentLocale]
              }
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={localeTaskDescription[currentLocale].formLogs.hostnameSearch}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
          {(document.location.pathname.includes('systemlog/tasks') ||
            document.location.pathname.includes('/accounts/')) && (
            <div className={styles.commonLogBtnWrap}>
              <Button
                excel
                fill="flat"
                onClick={(): void => saveLogList2Json(data?.data, 'currentLogPage')}
              >
                {localeTaskDescription[currentLocale].titleExportLogList}
              </Button>
            </div>
          )}
          {!document.location.pathname.includes('systemlog') &&
            !document.location.pathname.includes('/accounts/') && (
              <div className={styles.commonLogMenuWrap}>
                <Menu hoverOpenDelay={0} onSelect={onSelect}>
                  <MenuItem
                    text={localeTaskDescription[currentLocale].menuTitle}
                    cssStyle={{ padding: '4px' }}
                  >
                    <MenuItem
                      text={localeTaskDescription[currentLocale].menuItem1}
                      cssStyle={{ fontSize: '13px' }}
                    />
                    <MenuItem
                      text={localeTaskDescription[currentLocale].menuItem2}
                      cssStyle={{ fontSize: '13px' }}
                    />
                  </MenuItem>
                </Menu>
              </div>
            )}
        </IntlProvider>
      </LocalizationProvider>
      <div className="logs-list">
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <Grid
              pageable={data?.total && data?.total > 15 ? PAGEABLE_DATA : false}
              skip={page.skip}
              take={page.take}
              sortable
              sort={sort}
              data={data}
              total={data?.total}
              onPageChange={pageChange}
              onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
              scrollable="none"
            >
              <Column
                field="createdAt"
                title={localeTaskDescription[currentLocale].formLogs.createdAt}
                cell={CellCreatedAt}
                filter="text"
                width="180px"
              />
              <Column
                field="level"
                title={localeTaskDescription[currentLocale].formLogs.level}
                width="120px"
              />
              <Column
                field="asset.latestInventorization.hostname"
                title={localeTaskDescription[currentLocale].formLogs.assetName}
                cell={CellHostname}
                width="180px"
              />
              {componentName === 'AllTaskLogs' && (
                <Column
                  field="taskExecution.taskName"
                  title={localeTaskDescription[currentLocale].formLogs.taskName}
                  cell={CellTaskname}
                  width="160px"
                />
              )}
              <Column
                field="taskAction.type"
                title={localeTaskDescription[currentLocale].formResult.actions}
                cell={CellActionLogs}
                width="132px"
                headerCell={ActionHeaderCell}
              />
              <Column
                field="message"
                title={localeTaskDescription[currentLocale].formLogs.message}
                cell={CellMessage}
                // width="898px"
              />
              <Column field=" " sortable={false} cell={CellLogsMenu} width="40px" />
            </Grid>
            <СommonTableLoader queryData={isLoading} filteringData={filterStatus} />
          </IntlProvider>
        </LocalizationProvider>
      </div>
    </div>
  );
}
