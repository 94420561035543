import { useState } from 'react';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button } from '../../../../common/baseElements/Button';
import styles from '../AddAssetForm.module.scss';
import { useHostCreateContext } from '../../../../../hooks/useHostCreateContext';
import { HostNotification } from '../../hostComponents/HostNotification';
import localeDashboardForm from '../../../../../utils/i18n/dashboardLocale/addDashboardForm.json';
import { STEPPER_LINUX_ASSET } from '../../../../../utils/helpers/constants';
import { SensorDropdownList } from '../../../Sensor/SensorDropdownList';
import { useHostActions } from '../../../../../hooks/components/useHostActions';
import { getAccountId } from '../../../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeDashboardForm;
export function CreateLinuxHost(): React.ReactElement {
  const accountId = getAccountId();
  const { hostCreateData, handleLinkDowload } = useHostCreateContext();
  const { handleEditHost } = useHostActions();

  const [step, setStep] = useState(0);

  const [copied, setCopied] = useState(false);

  const handleCopied = (e: number | StepperChangeEvent): void => {
    if (typeof e === 'number') {
      setStep(e);
      const timeout = setTimeout(() => {
        setCopied(false);
        clearTimeout(timeout);
      }, 900);
    } else {
      setStep(e.value);
    }
  };

  const patchFunction = (sensorId: string): void => {
    handleEditHost(
      hostCreateData?.assetId,
      { type: 'host', sensorId: sensorId || null },
      { assetType: 'linux', accountId },
    );
  };

  return (
    <div className="create-linux-asset__content">
      <div className={styles.asset_form}>
        <Stepper
          value={step}
          onChange={handleCopied}
          items={STEPPER_LINUX_ASSET}
          orientation="vertical"
          className="linux-install-agent-stepper"
        />
        <div className="asset-form__content">
          <div className="asset-form__parent">
            <p style={{ marginTop: '8px' }}>
              {localeDashboardForm[currentLocale].form.connectingByField}:
            </p>
            <SensorDropdownList patchFunction={patchFunction} />
          </div>
          <div className="asset-form__download">
            <p style={{ marginTop: '8px' }}>
              {localeDashboardForm[currentLocale].form.step1.content1}
            </p>
            <Button
              className="btn-download"
              icon="download"
              onClick={(): void => handleLinkDowload(hostCreateData?.url)}
            >
              {localeDashboardForm[currentLocale].btnDownload}
            </Button>
            <p>{localeDashboardForm[currentLocale].form.step1.content2}</p>
            <CopyToClipboard
              text={`curl -k -O ${hostCreateData?.url}`}
              onCopy={(): void => setCopied(true)}
            >
              <div className="code-container">
                <span>curl -k -O {hostCreateData?.url}</span>
                <div className={styles.btn_block}>
                  <Button fill="copy" onClick={(): void => handleCopied(1)}>
                    {/* {localeDashboardForm[currentLocale].btnCopy} */}
                  </Button>
                  {step === 1 && <HostNotification copied={copied} />}
                </div>
              </div>
            </CopyToClipboard>
          </div>
          <div className="asset-form__rights">
            <p>{localeDashboardForm[currentLocale].form.step2}</p>
            <CopyToClipboard
              text={`sudo chmod u+x ${hostCreateData?.url.split('/')[6]}`}
              onCopy={(): void => setCopied(true)}
            >
              <div className="code-container">
                <div className="text-container">
                  <span>sudo chmod u+x {hostCreateData?.url.split('/')[6]}</span>
                </div>
                <div className={styles.btn_block}>
                  <Button fill="copy" onClick={(): void => handleCopied(3)}>
                    {/* {localeDashboardForm[currentLocale].btnCopy} */}
                  </Button>
                  {step === 3 && <HostNotification copied={copied} />}{' '}
                </div>
              </div>
            </CopyToClipboard>
          </div>
          <div className="asset-form__rights">
            <p>{localeDashboardForm[currentLocale].form.step3}</p>
            <CopyToClipboard
              text={`sudo ./${hostCreateData?.url.split('/')[6]}`}
              onCopy={(): void => setCopied(true)}
            >
              <div className="code-container">
                <div className="text-container">
                  <span>sudo ./{hostCreateData?.url.split('/')[6]}</span>
                </div>
                <div className={styles.btn_block}>
                  <Button fill="copy" onClick={(): void => handleCopied(4)}>
                    {/* {localeDashboardForm[currentLocale].btnCopy} */}
                  </Button>
                  {step === 4 && <HostNotification copied={copied} />}{' '}
                </div>
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
}
