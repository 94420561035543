import type { ReactElement } from 'react';
import type { AccountDashboardResponse } from '../../../types/__generated/on-premise-solution/api/accountsDashboardResponse.v1';
import localeAccount from '../../../utils/i18n/accountLocales/accountDescription.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;

export function AccountMetaBlock({ data }: { data: AccountDashboardResponse }): ReactElement {
  return (
    <div className="common-summary-page">
      <table className="column">
        <tbody>
          <tr>
            <td>{localeAccount[currentLocale].metablock.name}</td>
            <td>{data.name}</td>
          </tr>
          <tr>
            <td>ID</td>
            <td>{data.accountId}</td>
          </tr>
        </tbody>
      </table>
      <table className="column">
        <tbody>
          <tr>
            <td>{localeAccount[currentLocale].metablock.users}</td>
            <td>{data.totalUsers !== undefined ? data.totalUsers : '-'}</td>
          </tr>
          <tr>
            <td>{localeAccount[currentLocale].metablock.assets}</td>
            <td>{data.totalAssets !== undefined ? data.totalAssets : '-'}</td>
          </tr>
        </tbody>
      </table>
      <table className="column">
        <tbody>
          <tr>
            <td>{localeAccount[currentLocale].metablock.sensors}</td>
            <td>{data.totalSensors !== undefined ? data.totalSensors : '-'}</td>
          </tr>
          <tr>
            <td>{localeAccount[currentLocale].metablock.servers}</td>
            <td>{data.totalLdaps !== undefined ? data.totalLdaps : '-'}</td>
          </tr>
        </tbody>
      </table>
      <table className="column">
        <tbody>
          <tr>
            <td>{localeAccount[currentLocale].metablock.createdAt}</td>
            <td>{data.createdAt ? new Date(data.createdAt).toLocaleString() : '-'}</td>
          </tr>
          <tr>
            <td>{localeAccount[currentLocale].metablock.createdBy}</td>
            <td>{data.createdBy.source}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
