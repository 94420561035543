import { useQuery } from 'react-query';
import { loadMessages } from '@progress/kendo-react-intl';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { Button } from '../../../common/baseElements/Button';
import localeCommonTable from '../../../../utils/i18n/commonLocales/table.json';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import {
  getDynamicGroupDescription,
  getDynamicGroupDescriptionRefresh,
} from '../../../../services/asset-service';
import { EditGroupForm } from '../../Group/form/EditGroup';
import { useGroupContext } from '../../../../hooks/useGroupContext';
import locale from '../../../../utils/i18n/assetLocales/assetDescriptionGroup.json';
import { GroupListDescription } from '../../Group/groupComponents/GroupListDescription';
import type { AssetDynamicGroupAssetsRefreshResponse } from '../../../../types/__generated/on-premise-solution/api/assetDynamicGroupAssetsRefreshResponse.v1';
import { DynamicGroupMetaBlock } from './DynamicGroupMetaBlock';
import type {
  AssetDynamicGroup,
  AssetDynamicGroupDashboardResponse,
} from '../../../../types/__generated/on-premise-solution/api/assetDynamicGroupsDashboardResponse.v1';
import { DynamicGroupDescriptionFilters } from './DynamicGroupDescriptionFilters';
import { useGroupHook } from '../../../../hooks/components/useGroupHook';
import { DeleteConfirmModal } from '../../../common/modal/DeleteConfirmModal';
import { useTaskForGroup } from '../../../../hooks/components/useTaskForGroup';
import { useMaintenanceWindowsContext } from '../../../../hooks/useMaintenanceWindowsContext';
import { MaintenanceListModal } from '../../Host/MaintenanceWindows/MaintenanceListModal';
import { EntityDescription } from '../../../templates/EntityDescription';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

loadMessages(localeCommonTable[currentLocale], currentLocale);

export function DynamicGroupDescription(): React.ReactElement {
  const [dataOfFilters, setDataOfFilters] = useState<AssetDynamicGroup['filters'] | null>(null);

  const dynamicGroupName = useRef<string>('');

  const dynamicGroupDescription = useRef<string | null>(null);

  const { assetDynamicGroupId } = useParams<{ assetDynamicGroupId: string }>();

  const { isConfirm, setConfirm, handleDeleteDynamicGr, isError, errorMessage } = useGroupHook(
    dynamicGroupName.current,
    dynamicGroupDescription.current,
    assetDynamicGroupId,
  );

  const { entityId, entityName, entityType, isTableOpen, setMaintenanceWindowsList } =
    useMaintenanceWindowsContext();

  const {
    isOpen: isOpenGroup,
    filterInDynamicGrInListForEdit,
    setFilterInDynamicGrInListForEdit,
    prepareDataForDynamicGroup,
    isRefreshAssets,
    setRefreshAssets,
    dataAsset,
    setDataAsset,
    setGroupName,
    setGroupDescription,
  } = useGroupContext();

  const query = useQuery<AssetDynamicGroupDashboardResponse, ApiError>(
    ['hostDynamicGroupDescription'],
    () => getDynamicGroupDescription(assetDynamicGroupId),
    {
      keepPreviousData: true,
      onSuccess: (resp) => {
        setDataOfFilters(resp.filters);
        dynamicGroupName.current = resp.name;
        dynamicGroupDescription.current = resp.description;
        entityId.current = resp.assetDynamicGroupId;
        entityName.current = resp.name;
        entityType.current = 'group';
        setMaintenanceWindowsList(resp.maintenanceWindows);
        setDataAsset(resp.latestAssets.assetIds);
        if (resp.filters) setFilterInDynamicGrInListForEdit(resp.filters);
      },
    },
  );

  const queryRefresh = useQuery<AssetDynamicGroupAssetsRefreshResponse, ApiError>(
    ['hostDynamicGroupDescriptionRefresh'],
    () => getDynamicGroupDescriptionRefresh(assetDynamicGroupId),
    {
      keepPreviousData: true,
      onSuccess: (resp) => {
        setDataAsset(resp.latestAssets.assetIds);
        setRefreshAssets(false);
      },
      enabled: isRefreshAssets,
    },
  );

  useEffect(() => {
    setRefreshAssets(true);
  }, []);

  const { data } = query;

  const latestAssetsCount = queryRefresh.data?.latestAssets.count;

  const createTaskForGroup = useTaskForGroup(
    dynamicGroupName.current,
    assetDynamicGroupId,
    'dynamic',
  );

  useEffect(() => {
    return () => {
      setGroupName('');
      setGroupDescription(null);
      entityId.current = undefined;
      entityName.current = undefined;
      entityType.current = undefined;
      setMaintenanceWindowsList(undefined);
    };
  }, []);

  return (
    <EntityDescription
      isLoading={query.isLoading}
      isNotFound={query.error?.code === '404'}
      entity={{
        id: assetDynamicGroupId,
        type: 'dynamicGroup',
        name: dynamicGroupName.current,
      }}
      topactions={
        <>
          <Button
            onClick={(): void => {
              createTaskForGroup('default');
            }}
            className="action"
          >
            {locale[currentLocale].btns.btnCreateTask}
          </Button>
          <div className="button-group">
            <Button
              onClick={(): Promise<void> =>
                prepareDataForDynamicGroup(
                  assetDynamicGroupId,
                  dynamicGroupName.current,
                  dynamicGroupDescription.current,
                  filterInDynamicGrInListForEdit || [],
                )
              }
              disabled={!filterInDynamicGrInListForEdit}
            >
              {locale[currentLocale].btns.edit}
            </Button>
            <Button onClick={(): void => setConfirm(true)} fill="delete">
              {locale[currentLocale].btns.delete}
            </Button>
          </div>
        </>
      }
      className="asset-group-description"
    >
      {isTableOpen && <MaintenanceListModal />}
      {isConfirm && (
        <DeleteConfirmModal
          name={dynamicGroupName.current}
          type="group"
          onClose={(): void => setConfirm(false)}
          handleDelete={(): void => handleDeleteDynamicGr(assetDynamicGroupId)}
          errorMessage={isError ? errorMessage : undefined}
        />
      )}
      <DynamicGroupMetaBlock data={data} latestAssetsCount={latestAssetsCount} />
      {dataOfFilters && (
        <DynamicGroupDescriptionFilters
          assetDynamicGroupId={assetDynamicGroupId}
          dataFilters={dataOfFilters}
          name={data?.name}
          description={data?.description}
          setDataOfFilters={setDataOfFilters}
        />
      )}
      {dataAsset && <GroupListDescription dataAsset={dataAsset} />}
      {isOpenGroup && <EditGroupForm />}
    </EntityDescription>
  );
}
