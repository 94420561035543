import type { GridCellProps } from '@progress/kendo-react-grid';
import type { TreeListCellProps } from '@progress/kendo-react-treelist';

export function CellCommonDate(props: GridCellProps | TreeListCellProps): React.ReactElement {
  const { dataItem } = props;

  if (dataItem.createdAt)
    return <td>{dataItem.createdAt ? new Date(dataItem.createdAt).toLocaleString() : '-'}</td>;
  if (dataItem.createdBy)
    return (
      <td>
        {dataItem.createdBy.source === 'user' && dataItem.createdBy.name
          ? dataItem.createdBy.name
          : '-'}
      </td>
    );
  if (dataItem.modifiedAt)
    return <td>{dataItem.modifiedAt ? new Date(dataItem.modifiedAt).toLocaleString() : '-'}</td>;

  if (dataItem.addedAt)
    return <td>{dataItem.addedAt ? new Date(dataItem.addedAt).toLocaleString() : '-'}</td>;

  if (dataItem.modifiedBy)
    return (
      <td>
        {dataItem.modifiedBy &&
        dataItem.modifiedBy.source &&
        dataItem.modifiedBy.source === 'user' &&
        dataItem.modifiedBy.name
          ? dataItem.modifiedBy.name
          : '-'}
      </td>
    );

  return <td />;
}
