/* eslint-disable react/jsx-pascal-case */
import { ErrorBoundary } from 'react-error-boundary';
import { Typography } from '@progress/kendo-react-common';
import type { ReactElement } from 'react';
import { BoundaryErrorComponent } from '../common/BoundaryErrorComponent';
import localeEntity from '../../utils/i18n/commonLocales/entityDescription.json';
import localeNames from '../../utils/i18n/commonLocales/names.json';
import type { CabinetEntities } from '../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNames;

function getNotFoundText(type: CabinetEntities): string {
  if (currentLocale === 'ru-RU') {
    if (type === 'group' || type === 'dynamicGroup' || type === 'account')
      return localeEntity[currentLocale].notFound[1];
    if (type === 'device') return localeEntity[currentLocale].notFound[2];

    return localeEntity[currentLocale].notFound[0];
  }

  return localeEntity[currentLocale].notFound;
}

export function EntityNotFound({
  entityId,
  type,
}: {
  entityId: string;
  type: CabinetEntities;
}): ReactElement {
  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="entity-not-found">
        <div className="common-header-page">
          <Typography.h3 className="title">
            {currentLocale === 'ru-RU'
              ? localeNames[currentLocale][type][0]
              : localeNames[currentLocale][type]}{' '}
            {entityId} {getNotFoundText(type)}
            {/* <span className="k-i-computer" /> {localeHostDescription[currentLocale].title} */}
            {/* {hostname || ''} */}
          </Typography.h3>
        </div>
      </div>
    </ErrorBoundary>
  );
}
