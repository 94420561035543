/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Typography } from '@progress/kendo-react-common';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { useQuery, useQueryClient } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { Button } from '../../common/baseElements/Button';
import { FILTER_INITIAL, PAGEABLE_DATA } from '../../../utils/helpers/constants';
import type { IDataHooks, IPageState } from '../../../utils/helpers/types';
import localeTask from '../../../utils/i18n/taskLocale/task.json';
import './TaskList.module.scss';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getTaskHistoryList } from '../../../services/task-service';
import { СommonTableLoader } from '../../common/tableLoader/CommonTableLoader';
import { handleError } from '../../../utils/errors';
import { useNotificationContext } from '../../../hooks/useNotificationContext';
import { handleSort } from '../../../utils/sorting-in-table';
import { Status } from './cellsTaskList/Status';
import { ActionHeaderCell } from './cellsTaskList/ActionHeaderCell';
import { Actions } from './cellsTaskList/Actions';
import { HistoryTime } from './cellsTaskList/HistoryTime';
import { HistoryTaskName } from './cellsTaskList/HistoryTaskName';
import { HistoryScope } from './cellsTaskList/HistoryScope';
import { HistoryMenu } from './cellsTaskList/HistoryMenu';
import { getUrlTaskHistory } from '../../../utils/helpers/task-history-helper';
import { fillFiltersTaskHistory } from '../../../utils/helpers/task-history-fill-filter-helper';
import { handleFilteringTest, IFilterVal } from '../../../utils/filtering-in-table-test';
import { useCustomSearch } from '../../../hooks/components/useCustomSearch';
import { usePageChange } from '../../../hooks/components/usePageChange';
import { useFilter } from '../../../hooks/components/useFilter';
import { FILTER_OPERATORS } from '../../../utils/helpers/constant-filter-operators';
import { FILTER_TASK_HISTORY } from '../../../utils/helpers/constant-serv-filter-task';
import type { TaskExecutionsDashboardResponse } from '../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import { useTaskContext } from '../../../hooks/useTaskContext';
import { Input } from '../../common/baseElements/Input';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

export function TaskHistory(): React.ReactElement {
  const queryClient = useQueryClient();
  const urlParams = getUrlTaskHistory('TaskHistory');

  const [customSearch, setCustomSearch] = useState('');

  const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const dataForHooks: IDataHooks = {
    name: 'taskName',
    componentName: 'TaskHistory',
  };

  const { createNotification } = useNotificationContext();

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const { setOpenDialog } = useTaskContext();

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<TaskExecutionsDashboardResponse, ApiError>(
    ['historyTasks', page, filterVal, sort],
    () => getTaskHistoryList(page, sort, filterVal),
    {
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    fillFiltersTaskHistory(urlParams, filterValue, setFilter, customSearch);
  }, []);

  useEffect(() => {
    // searchStringUrl(setSort, setPage, setFilterVal);
    queryClient.invalidateQueries(['historyTasks']);
  }, [queryClient]);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 1800);
    }

    return () => clearTimeout(delaySearch);
  }, [filter, page.skip]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error, createNotification]);

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleRefresh = useCallback((): void => {
    setFilterStatus(true);
    // query.refetch().then(() => setFilterStatus(false));
    queryClient.invalidateQueries('historyTasks').then(() => setFilterStatus(false));
  }, []);

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="task">
        <div className="common-header-page">
          <Typography.h3>{localeTask[currentLocale].taskHistoryTitle}</Typography.h3>
          <div className="topactions">
            <Button
              fill="action"
              onClick={(): void => {
                setOpenDialog(true);
              }}
            >
              {localeTask[currentLocale].btnCreateTask}
            </Button>
            <Button icon="refresh" onClick={handleRefresh}>
              {localeTask[currentLocale].btnRefresh}
            </Button>
          </div>
        </div>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_TASK_HISTORY[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={localeTask[currentLocale].customSearchName}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <div className="task-list">
          <LocalizationProvider language={currentLocale}>
            <IntlProvider locale={currentLocale.substring(0, 2)}>
              <Grid
                resizable
                pageable={Number(query.data?.total) > 15 ? PAGEABLE_DATA : false}
                sortable
                sort={sort}
                data={query?.data?.data}
                skip={page.skip}
                take={page.take}
                total={Number(query.data?.total)}
                filterOperators={FILTER_OPERATORS}
                onPageChange={pageChange}
                onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
                scrollable="none"
                className={
                  Number(query?.data?.total) && Number(query?.data?.total) < 15
                    ? 'no-pageable-k-grid'
                    : ''
                }
              >
                <Column
                  cell={Status}
                  title={localeTask[currentLocale].form.status}
                  filter="text"
                  field="status"
                  width="144px"
                />
                <Column
                  cell={HistoryTime}
                  title={localeTask[currentLocale].form.timeHistoryStart}
                  filter="text"
                  field="startedAt"
                  width="180px"
                />
                <Column
                  field="actions"
                  cell={Actions}
                  title={localeTask[currentLocale].form.actions}
                  width="220px"
                  headerCell={ActionHeaderCell}
                />
                <Column
                  field="taskName"
                  title={localeTask[currentLocale].form.taskName}
                  filter="text"
                  cell={HistoryTaskName}
                  width="320px"
                />
                <Column
                  cell={HistoryScope}
                  title={localeTask[currentLocale].form.scope}
                  filter="text"
                  field="scope.assets"
                />

                <Column
                  cell={HistoryMenu}
                  // title={localeTask[currentLocale].form.menu}
                  width="40px"
                />
              </Grid>

              <СommonTableLoader queryData={query.isLoading} filteringData={filterStatus} />
              {query.isError && (
                <div className="info alert alert-danger">Error: {query.error.message}</div>
              )}
            </IntlProvider>
          </LocalizationProvider>
        </div>
      </div>
    </ErrorBoundary>
  );
}
