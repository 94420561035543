/* eslint-disable no-nested-ternary */
import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  ReactElement,
  JSXElementConstructor,
  useRef,
} from 'react';
import { useMutation, UseMutationResult, useQuery, UseQueryResult } from 'react-query';
import type { ApiError } from '../types/__generated/on-premise-solution/api/apiError.v1';
import { getAllSensors } from '../services/dashboard-service';
import { getSensorInstaller } from '../services/sensor-service';
import type { SensorsResponse } from '../types/__generated/on-premise-solution/api/sensorsResponse.v1';
import localeSensor from '../utils/i18n/sensorLocales/sensor.json';
import type { SensorInstallerDownloadUrlResponse } from '../types/__generated/on-premise-solution/api/sensorInstallerDownloadUrlResponse.v1';

export interface IOpenedFromComponentInfo {
  rootComponentName: string;
  subcomponentName?: string;
}

export interface ISensorContext {
  sensorName: React.MutableRefObject<string | undefined>;
  sensorDescription: React.MutableRefObject<string | undefined>;
  sensorIdContext: React.MutableRefObject<string | undefined>;
  deleteOpen: IOpenedFromComponentInfo | undefined;
  setDeleteOpen: React.Dispatch<React.SetStateAction<IOpenedFromComponentInfo | undefined>>;
  creationOpen: IOpenedFromComponentInfo | undefined;
  setCreationOpen: React.Dispatch<React.SetStateAction<IOpenedFromComponentInfo | undefined>>;
  reinstallOpen: IOpenedFromComponentInfo | undefined;
  setReinstallOpen: React.Dispatch<React.SetStateAction<IOpenedFromComponentInfo | undefined>>;
  editOpen: IOpenedFromComponentInfo | undefined;
  setEditOpen: React.Dispatch<React.SetStateAction<IOpenedFromComponentInfo | undefined>>;
  isSensorNotEmptyModal: boolean;
  setSensorNotEmptyModal: React.Dispatch<React.SetStateAction<boolean>>;
  availableSensors: { name: string; id: string }[];
  setAvailableSensors: (data: SensorsResponse['data'] | undefined) => void;
  queryAllSensors: UseQueryResult<SensorsResponse, ApiError>;
  allAvailableSensors: (IEntityInfo & {
    ip?: string | null;
  })[];
  setAllAvailableSensors: React.Dispatch<
    React.SetStateAction<
      (IEntityInfo & {
        ip?: string | null;
      })[]
    >
  >;
  dropdownParentId: string | undefined;
  setDropdownParentId: React.Dispatch<React.SetStateAction<string | undefined>>;
  handleLinkDowload: (url: string | undefined) => void;
  sensorLogsInfo: React.MutableRefObject<
    | {
        sensorId: string;
        logsChunkId?: string;
      }
    | undefined
  >;
  installerQuery: UseMutationResult<
    SensorInstallerDownloadUrlResponse,
    ApiError,
    string | undefined,
    unknown
  >;
  sensorNameLastCells: React.MutableRefObject<{
    [key: number]: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  }>;
  logs: React.MutableRefObject<string[]>;
}

interface IEntityInfo {
  name: string;
  id: string;
}

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSensor;

const SensorContext = createContext<ISensorContext | null>(null);

export function SensorProvider({
  children,
}: {
  children: ReactElement<string | JSXElementConstructor<string>>;
}): React.ReactElement {
  // Variables
  const sensorName = useRef<string | undefined>(undefined);
  const sensorDescription = useRef<string | undefined>(undefined);
  const sensorIdContext = useRef<string | undefined>(undefined);

  // States
  const [deleteOpen, setDeleteOpen] = useState<IOpenedFromComponentInfo | undefined>(undefined);
  const [creationOpen, setCreationOpen] = useState<IOpenedFromComponentInfo | undefined>(undefined);
  const [reinstallOpen, setReinstallOpen] = useState<IOpenedFromComponentInfo | undefined>(
    undefined,
  );
  const [editOpen, setEditOpen] = useState<IOpenedFromComponentInfo | undefined>(undefined);
  const [isSensorNotEmptyModal, setSensorNotEmptyModal] = useState<boolean>(false);
  const [availableSensors, setAvailableSensorFunction] = useState<IEntityInfo[]>([]);
  const [allAvailableSensors, setAllAvailableSensors] = useState<
    (IEntityInfo & { ip?: string | null })[]
  >([]);
  const [dropdownParentId, setDropdownParentId] = useState<string | undefined>(undefined);
  const sensorLogsInfo = useRef<
    | {
        sensorId: string;
        logsChunkId?: string;
      }
    | undefined
  >();

  const sensorNameLastCells = useRef<{ [key: number]: ReactElement }>({});
  const logs = useRef<string[]>([]);

  // Queries
  const queryAllSensors = useQuery<SensorsResponse, ApiError>(
    ['allSensors'],
    () => getAllSensors(),
    {
      keepPreviousData: true,
      enabled: false,
      onSuccess: (resp) => {
        const mainServer = {
          name: localeSensor[currentLocale].mainServer,
          ip: '',
          id: '',
        };
        const sList = resp.data
          // .filter((s) => s.sensorId !== sensorId)
          .map((s) => ({ name: s.name, id: s.sensorId, ip: s.ip }));
        if (sList.length) sList.unshift(mainServer);
        setAllAvailableSensors(sList.length ? sList : [mainServer]);
      },
    },
  );

  // Mutations
  const installerQuery = useMutation<
    SensorInstallerDownloadUrlResponse,
    ApiError,
    string | undefined
  >((sensorId) => getSensorInstaller(sensorId));

  // Functions
  const setAvailableSensors = (data: SensorsResponse['data'] | undefined): void => {
    const defaultData = [{ name: 'Server', id: '' }];
    setAvailableSensorFunction(
      defaultData.concat(
        ...(data?.map((sensor) => ({ name: sensor.name, id: sensor.sensorId, ip: sensor.ip })) ||
          []),
      ),
    );
  };

  const handleLinkDowload = (url: string | undefined): void => {
    if (url) {
      const link = document.createElement('a');
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const value = useMemo(
    () => ({
      sensorIdContext,
      sensorName,
      sensorDescription,
      deleteOpen,
      setDeleteOpen,
      creationOpen,
      setCreationOpen,
      reinstallOpen,
      setReinstallOpen,
      editOpen,
      setEditOpen,
      isSensorNotEmptyModal,
      setSensorNotEmptyModal,
      availableSensors,
      setAvailableSensors,
      queryAllSensors,
      allAvailableSensors,
      setAllAvailableSensors,
      dropdownParentId,
      setDropdownParentId,
      handleLinkDowload,
      sensorLogsInfo,
      installerQuery,
      sensorNameLastCells,
      logs,
    }),
    [
      allAvailableSensors,
      availableSensors,
      creationOpen,
      deleteOpen,
      dropdownParentId,
      editOpen,
      installerQuery,
      isSensorNotEmptyModal,
      queryAllSensors,
      reinstallOpen,
    ],
  );

  return <SensorContext.Provider value={value}>{children}</SensorContext.Provider>;
}

export const useSensorContext = (): ISensorContext => {
  const context = useContext(SensorContext);
  if (context === null) {
    throw new Error('useSensorContext must be used inside the SensorContext.Provider.');
  }

  return context;
};
