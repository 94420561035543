import { Field, Form, FormElement } from '@progress/kendo-react-form';
import type React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { Switch, type SwitchChangeEvent } from '@progress/kendo-react-inputs';
import { ROLE_VALUES } from '../../../../utils/helpers/constants';
import { useUserModalContext } from '../../../../hooks/useUserModalContext';
import localeUser from '../../../../utils/i18n/userLocales/userList.json';
import localeButtons from '../../../../utils/i18n/commonLocales/buttons.json';
import { checkEmail, checkLength } from '../../../../utils/helpers/validation';
import { useAccounts } from '../../../../hooks/components/useAccounts';
import { DropDownSelect } from '../../../common/form/DropDownSelect';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import { FormInput } from '../../../common/form/FormInput';
import { Button } from '../../../common/baseElements/Button';
import styles from './AddUserForm.module.scss';
import type { LdapsDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/ldapsDashboardResponse.v1';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { disableTfa, getSrvListForSelect } from '../../../../services/user-service';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { AccountTypeDropDown } from './AccountTypeDropDown';
import type { CurrentUser } from '../../../../services/local-storage-service';
import { IdInfo } from './IdInfo';
import { TfaConnectModal } from '../../../common/modal/2fa/TfaConnectModal';
import type { TwoFactorAuthUserDisableResponse } from '../../../../types/__generated/on-premise-solution/api/twoFactorAuthUserDisableResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function AddLdapUserForm({
  currentUser,
}: {
  currentUser: CurrentUser | null;
}): React.ReactElement {
  const { type } = useAuthContext();

  const query = useQuery<LdapsDashboardResponse, ApiError>(
    ['SrvListForSelect', type],
    () => getSrvListForSelect(type),
    {
      keepPreviousData: true,
    },
  );

  const {
    handleSubmit,
    handleClose,
    name,
    setName,
    role,
    email,
    setEmail,
    password,
    confirmPassword,
    isTfa,
    setTfa,
    editUserData,
    changeDropDownRole,
    userStatus,
    changeDropDownStatus,
    userStatusValues,
    dn,
    setDn,
    uid,
    setUid,
    changeDropDownSrvList,
    serverListData,
    errorMutation,
    setErrorMutation,
  } = useUserModalContext();

  const [isModifiedTfa, setModifiedTfa] = useState(false);

  useEffect(() => {
    return () => setErrorMutation('');
  }, []);

  const queryClient = useQueryClient();

  const { destructuringAccounts } = useAccounts();

  const { changeDropDownAccount, accountId } = useAccountModalContext();

  const destructuredAccounts = destructuringAccounts();

  const [isConnectingTfaModal, setConnectionTfaModal] = useState(false);

  const mutationDisableTfa = useMutation<TwoFactorAuthUserDisableResponse, ApiError, string>(
    (userId) => disableTfa(userId),
    {
      onSuccess: (resp) => {
        queryClient.invalidateQueries('userList');
        setTfa(false);
        setModifiedTfa(true);
      },
      onError: (resp) => {
        setErrorMutation(resp.message);
      },
    },
  );

  const onSwitch = (e: SwitchChangeEvent): void => {
    setConnectionTfaModal(e.target.value);
    if (!e.target.value) mutationDisableTfa.mutateAsync(editUserData?.userId || '');
  };

  return (
    <div className={`add-ldap-user-form ${styles.addLdapUser}`}>
      {isConnectingTfaModal && (
        <TfaConnectModal
          onClose={(): void => setConnectionTfaModal(false)}
          uid={currentUser?.id || ''}
          userId={currentUser?.id || ''}
          setModifiedTfa={setModifiedTfa}
        />
      )}
      <Form
        onSubmit={handleSubmit}
        render={(): JSX.Element => (
          <FormElement>
            {editUserData && <IdInfo editUserData={editUserData} />}
            <div className="form-content">
              {currentUser?.role !== 'user' && currentUser?.role !== 'reader_user' && (
                <>
                  <AccountTypeDropDown />
                  {currentUser?.role === 'super_admin' && (
                    <Field
                      name="accountId"
                      component={DropDownSelect}
                      label={localeUser[currentLocale].modal.accountName}
                      onChange={changeDropDownAccount}
                      data={destructuredAccounts?.filter((a) => a.id !== accountId.id)}
                      textField="name"
                      dataItemKey="id"
                      defaultValue={{
                        id: '000',
                        name: localeUser[currentLocale].modal.placeholderAccountName,
                      }}
                      cls="form-field-wrapper"
                      currentValue={accountId}
                      disabled={!destructuredAccounts}
                    />
                  )}
                  <Field
                    name="role"
                    component={DropDownSelect}
                    label={localeUser[currentLocale].modal.role}
                    onChange={changeDropDownRole}
                    data={
                      currentUser?.role === 'super_admin'
                        ? ROLE_VALUES.filter((r) => r.role !== role).map(
                            (userRole) => userRole.role,
                          )
                        : ROLE_VALUES.filter(
                            (r) => r.role !== role && r.role !== 'super_admin',
                          ).map((userRole) => userRole.role)
                    }
                    defaultValue={{
                      role: localeUser[currentLocale].modal.placeholderRole,
                      description: '000',
                    }}
                    cls="form-field-wrapper"
                    currentValue={role}
                    classForLabel="required-label"
                  />
                  <Field
                    name="isDisabled"
                    component={DropDownSelect}
                    label={localeUser[currentLocale].modal.status}
                    onChange={changeDropDownStatus}
                    data={userStatusValues
                      .filter((s) => s.text !== userStatus.text)
                      .map((v) => v.text)}
                    defaultValue={localeUser[currentLocale].modal.status}
                    cls="form-field-wrapper"
                    currentValue={userStatus.text}
                  />
                  <Field
                    name="ldapList"
                    component={DropDownSelect}
                    label={localeUser[currentLocale].modal.listOfServers}
                    onChange={changeDropDownSrvList}
                    data={query.data?.data.filter((s) => s.ldapId !== serverListData.ldapId)}
                    textField="name"
                    dataItemKey="ldapId"
                    defaultValue={serverListData}
                    cls="form-field-wrapper"
                    currentValue={
                      editUserData
                        ? query.data?.data.find((s) => s.ldapId === editUserData.ldapId)
                        : serverListData
                    }
                    classForLabel="required-label"
                  />
                </>
              )}
              <Field
                name="name"
                component={FormInput}
                id="name"
                label={localeUser[currentLocale].modal.name}
                onChange={(e): void => {
                  setName(e.target.value);
                }}
                inputValue={name}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="text"
                classForLabel="required-label"
              />
              <Field
                name="dn"
                component={FormInput}
                id="dn"
                label="Distinguished name:"
                onChange={(e): void => {
                  setDn(e.target.value);
                }}
                inputValue={dn}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="text"
                tooltipText={localeUser[currentLocale].modal.phDistinguishedName}
                classForLabel="required-label"
              />
              <Field
                name="uid"
                component={FormInput}
                id="uid"
                label="UID:"
                onChange={(e): void => {
                  setUid(e.target.value);
                }}
                inputValue={uid}
                className="form-field-wrapper"
                customValidator={checkLength}
                type="text"
                tooltipText={localeUser[currentLocale].modal.phUid}
                classForLabel="required-label"
              />
              <Field
                name="email"
                component={FormInput}
                id="email"
                label={localeUser[currentLocale].modal.email}
                onChange={(e): void => {
                  setEmail(e.target.value);
                }}
                inputValue={email}
                className="form-field-wrapper"
                customValidator={email && checkEmail}
                type="email"
              />
              {password && confirmPassword && password !== confirmPassword && (
                <div className="error-common-field">
                  <span>{localeUser[currentLocale].passNotMatch}</span>
                </div>
              )}
            </div>
            {typeof isTfa === 'boolean' && (
              <div className={styles.idInfoWrap}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                  <span>{localeUser[currentLocale].tfaLabel}:</span>
                  <span className={styles.idInfoValue}>
                    {isTfa ? (
                      <b>{localeUser[currentLocale].tfaEnabled}</b>
                    ) : (
                      localeUser[currentLocale].tfaDisabled
                    )}
                  </span>
                  {((currentUser?.id && currentUser.id === editUserData?.userId) ||
                    currentUser?.role === 'super_admin') && (
                    <Switch
                      size="small"
                      onLabel=""
                      offLabel=""
                      checked={isTfa}
                      onChange={onSwitch}
                      disabled={
                        !!(!currentUser?.id || (currentUser?.id !== editUserData?.userId && !isTfa))
                      }
                    />
                  )}
                  {isModifiedTfa && (
                    <span
                      style={{
                        color: '#999',
                      }}
                    >
                      <i>
                        {isTfa
                          ? localeUser[currentLocale].modal.tfaConnected
                          : localeUser[currentLocale].modal.tfaDisabled}
                      </i>
                    </span>
                  )}
                  {/* {currentUser?.id && currentUser.id === editUserData?.userId && !isTfa && (
                    <Button onClick={(): void => setConnectionTfaModal(true)}>
                      {localeUser[currentLocale].btnEnableTfa}
                    </Button>
                  )}
                  {((currentUser?.id && currentUser.id === editUserData?.userId) ||
                    currentUser?.role === 'super_admin') &&
                    isTfa && (
                      <Button
                        onClick={(): void => {
                          mutationDisableTfa.mutateAsync(editUserData?.userId || '');
                        }}
                      >
                        {localeUser[currentLocale].btnDisableTfa}
                      </Button>
                    )} */}
                </div>
              </div>
            )}
            <div className="error-common-field">
              <span>{errorMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button
                type="submit"
                disabled={
                  (currentUser?.role === 'super_admin' && accountId.id === '000') ||
                  role === localeUser[currentLocale].modal.placeholderRole ||
                  userStatus.text === localeUser[currentLocale].chooseStatus ||
                  serverListData.ldapId === '000' ||
                  !dn ||
                  !uid ||
                  !name ||
                  (currentUser?.role === 'super_admin' && !destructuredAccounts)
                }
              >
                {editUserData
                  ? localeButtons[currentLocale].save
                  : localeButtons[currentLocale].create}
              </Button>
              <Button onClick={handleClose}>{localeButtons[currentLocale].cancel}</Button>
            </div>
          </FormElement>
        )}
      />
    </div>
  );
}
