import React, { useState, useEffect, useRef } from 'react';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useHistory, useParams } from 'react-router-dom';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { useQuery, useQueryClient } from 'react-query';
import {
  DATA_CACHE_STALETIME,
  FILTER_INITIAL,
  DATA_CACHE_CACHETIME,
  PAGEABLE_DATA_LOCAL,
} from '../../../utils/helpers/constants';
import type { IDataHooks, IPageState } from '../../../utils/helpers/types';
import localeTask from '../../../utils/i18n/taskLocale/task.json';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getHistoryByAsset, getHistoryBySensor } from '../../../services/task-service';
import { СommonTableLoader } from '../../common/tableLoader/CommonTableLoader';
import { handleError } from '../../../utils/errors';
import { useNotificationContext } from '../../../hooks/useNotificationContext';
import { handleSort } from '../../../utils/sorting-in-table';
import { Status } from '../Task/cellsTaskList/Status';
import { HistoryTime } from '../Task/cellsTaskList/HistoryTime';
import { HistoryTaskName } from '../Task/cellsTaskList/HistoryTaskName';
import { getUrlTaskHostHistory } from '../../../utils/helpers/task-history-host-helper';
import { fillFiltersTaskHostHistory } from '../../../utils/helpers/task-history-host-fill-filter-helper';
import { handleFilteringTest, IFilterVal } from '../../../utils/filtering-in-table-test';
import { Actions } from '../Task/cellsTaskList/Actions';
import { usePageChange } from '../../../hooks/components/usePageChange';
import { useCustomSearch } from '../../../hooks/components/useCustomSearch';
import { useFilter } from '../../../hooks/components/useFilter';
import { FILTER_OPERATORS } from '../../../utils/helpers/constant-filter-operators';
import { FILTER_HOST_TASK_HISTORY } from '../../../utils/helpers/constant-serv-filter-task';
import type { TaskExecutionsDashboardResponse } from '../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import { Input } from '../../common/baseElements/Input';
import { ActionHeaderCell } from '../Task/cellsTaskList/ActionHeaderCell';
import { HistoryScope } from '../Task/cellsTaskList/HistoryScope';

export function SensorTaskHistory(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeTask;

  const { createNotification } = useNotificationContext();

  const urlParams = getUrlTaskHostHistory('SensorTaskHistory');

  const history = useHistory();

  const queryClient = useQueryClient();

  const [customSearch, setCustomSearch] = useState('');

  const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const [staleTimeVal, setStaleTimeVal] = useState(DATA_CACHE_STALETIME);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const { sensorId } = useParams<{ sensorId: string }>();

  const dataForHooks: IDataHooks = {
    name: 'taskName',
    componentName: 'SensorTaskHistory',
    urlPath: `/lk/sensors/${sensorId}`,
  };

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<TaskExecutionsDashboardResponse, ApiError>(
    ['sensorHistoryTasks', page, filterVal, sort, sensorId],
    () => getHistoryBySensor(page, sort, filterVal, sensorId),
    {
      staleTime: staleTimeVal,
      cacheTime: DATA_CACHE_CACHETIME,
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    fillFiltersTaskHostHistory(urlParams, filterValue, setFilter, customSearch);
    queryClient.invalidateQueries(['sensorHistoryTasks']);
  }, []);

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;

    if (!filter || filter.filters.length === 0) {
      setStaleTimeVal(DATA_CACHE_STALETIME);
      // setPage({ skip: 0, take: 10 });
      handleFilteringTest(filter, setFilterVal);
      filterRef.current = filter;
      // history.replace({
      //   pathname: `/lk/assets/hosts/${assetId}?skip=0&limit=10&sortOrder=asc&%24or[0][status]=success&%24or[1][status]=fail&%24or[2][status]=timeout`,
      // });
      setFilterStatus(false);
    }

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [sensorId, filter, page.skip]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error, createNotification]);

  return (
    <div className="task">
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={FILTER_HOST_TASK_HISTORY[currentLocale]}
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={localeTask[currentLocale].customSearchName}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            pageable={
              Number(query.data?.total) && Number(query.data?.total) > 15
                ? PAGEABLE_DATA_LOCAL
                : false
            }
            sortable
            sort={sort}
            className="table task-table"
            data={query?.data?.data}
            skip={page.skip}
            take={page.take}
            total={Number(query.data?.total)}
            filterOperators={FILTER_OPERATORS}
            onPageChange={pageChange}
            onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
            scrollable="none"
          >
            <Column
              cell={Status}
              title={localeTask[currentLocale].form.status}
              filter="text"
              field="status"
              width="180px"
            />
            <Column
              cell={HistoryTime}
              title={localeTask[currentLocale].form.timeHistoryStart}
              filter="text"
              field="startedAt"
              width="190px"
            />
            <Column
              field="actions"
              cell={Actions}
              title={localeTask[currentLocale].form.actions}
              width="160px"
              headerCell={ActionHeaderCell}
            />
            <Column
              field="taskName"
              title={localeTask[currentLocale].form.taskName}
              filter="text"
              cell={HistoryTaskName}
            />
            <Column
              cell={HistoryScope}
              title={localeTask[currentLocale].form.scope}
              filter="text"
              field="sensorScope"
            />
          </Grid>
          <СommonTableLoader queryData={query.isLoading} filteringData={filterStatus} />
          {query.isError && (
            <div className="info alert alert-danger">Error: {query.error.message}</div>
          )}
        </IntlProvider>
      </LocalizationProvider>
    </div>
  );
}
