import { UseMutationResult, UseQueryResult, useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useEffect, useRef } from 'react';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import { getSensor } from '../../services/dashboard-service';
import { useMaintenanceWindowsContext } from '../useMaintenanceWindowsContext';
import type { SensorDashboardResponse } from '../../types/__generated/on-premise-solution/api/sensorDashboardResponse.v1';
import type { OnpremSensorCommandRequestListLogs } from '../../types/__generated/on-premise-solution/api/onpremSensorCommandRequest.v1';
import { requestCentrifugoForSensorLogs } from '../../services/sensor-service';
import { useSensorContext } from '../useSensorContext';

interface IUseHostDescription {
  querySensorId: UseQueryResult<SensorDashboardResponse, ApiError>;
  commandLogsMutation: UseMutationResult<
    OnpremSensorCommandRequestListLogs,
    ApiError,
    OnpremSensorCommandRequestListLogs['payload'],
    unknown
  >;
}
export function useSensorDescription(): IUseHostDescription {
  const { sensorId } = useParams<{ sensorId: string }>();

  // Variables
  // const sensorName = useRef<string>('');
  const { entityId, entityName, entityType } = useMaintenanceWindowsContext();
  const { sensorName } = useSensorContext();

  // Queries
  const querySensorId = useQuery<SensorDashboardResponse, ApiError>(
    ['sensorDescription', sensorId],
    () => getSensor(sensorId),
    {
      onSuccess: (resp) => {
        sensorName.current = resp.name;
      },
    },
  );

  const commandLogsMutation = useMutation<
    OnpremSensorCommandRequestListLogs,
    ApiError,
    OnpremSensorCommandRequestListLogs['payload']
  >((payload) => requestCentrifugoForSensorLogs(sensorId, payload), {
    onSuccess: () => {},
  });

  useEffect(() => {
    return () => {
      entityId.current = undefined;
      entityName.current = undefined;
      entityType.current = undefined;
    };
  }, []);

  return {
    querySensorId,
    commandLogsMutation,
  };
}
