import { useCallback, useEffect, useRef, useState, type ReactElement } from 'react';
import { NumericTextBox, RadioButton } from '@progress/kendo-react-inputs';
import { useParams } from 'react-router';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { useQueryClient } from 'react-query';
import { Button } from '../../../common/baseElements/Button';
import localeButtons from '../../../../utils/i18n/commonLocales/buttons.json';
import localeAccount from '../../../../utils/i18n/accountLocales/accountDescription.json';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import type { AccountDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/accountsDashboardResponse.v1';
import settingsStyles from '../../Settings/Settings.module.scss';
import styles from '../AccountList.module.scss';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import type { AccountUpdateRequest } from '../../../../types/__generated/on-premise-solution/api/accountUpdateRequest.v1';
import type { ConfigResponse } from '../../../../types/__generated/on-premise-solution/api/configResponse.v1';
import { Input } from '../../../common/baseElements/Input';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;

export function SystemTab({
  data,
  config,
}: {
  data: AccountDashboardResponse['settings']['system'] | undefined;
  config: ConfigResponse | undefined;
}): ReactElement {
  const { accountId } = useParams<{ accountId: string }>();
  const queryClient = useQueryClient();

  const { mutationEditAccount } = useAccountModalContext();

  const [isInherited, setInherited] = useState(!!data?.useInherited);
  const [taskTimeout, setTaskTimeout] = useState(
    (data?.value?.taskTimeout || config?.api.user_task_timeout || '').toString(),
  );

  const timer = useRef<NodeJS.Timeout | null>(null);
  const [isShowSavedMessage, setShowSavedMessage] = useState(false);

  useEffect(() => {
    setInherited(!!data?.useInherited);
    setTaskTimeout((data?.value?.taskTimeout || '').toString());
  }, [data]);

  const timeoutValidator = (value: string): string => {
    if (/^[1-9]{1}[0-9]*$/.test(value)) return '';

    return localeAccount[currentLocale].settingsTab.NaNerror;
  };

  const showSavedMessage = (): void => {
    setShowSavedMessage(true);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setShowSavedMessage(false);
    }, 3000);
  };

  const handleSave = (): void => {
    const payload: AccountUpdateRequest = {
      'settings.system.useInherited': isInherited,
    };
    if (!isInherited) {
      payload['settings.system.value.taskTimeout'] = parseInt(taskTimeout, 10);
    }
    mutationEditAccount
      .mutateAsync({
        accountId,
        payload,
      })
      .then(() => {
        showSavedMessage();
        queryClient.invalidateQueries('accountDescription');
      });
  };

  const isDisabled = useCallback((): boolean => {
    if (
      data?.useInherited !== isInherited ||
      (!isInherited && (data.value?.taskTimeout || '').toString() !== taskTimeout)
    )
      return false;

    return true;
  }, [data?.useInherited, data?.value?.taskTimeout, isInherited, taskTimeout]);

  return (
    <Form
      onSubmit={handleSave}
      initialValues={{
        default_task_timeout: config?.api.user_task_timeout,
        taskTimeout,
      }}
      render={(formRenderProps: FormRenderProps): JSX.Element => (
        <FormElement className="full-size">
          <fieldset className="k-form-fieldset">
            {/* <h2>{localeAccount[currentLocale].settingsTab.passwordPoliсy}</h2> */}
            <table className={styles.settingsTable}>
              <tr>
                <td />
                <td>
                  <Field
                    name="use_global_config"
                    checked={isInherited}
                    onChange={(): void => setInherited(true)}
                    label={localeAccount[currentLocale].settingsTab.useGlobalParameters}
                    component={RadioButton}
                  />
                </td>
                <td>
                  <Field
                    name="use_manual_config"
                    onChange={(): void => setInherited(false)}
                    value
                    checked={!isInherited}
                    label={localeAccount[currentLocale].settingsTab.useCustomParameters}
                    component={RadioButton}
                  />
                </td>
              </tr>
              <tr>
                <td>{localeAccount[currentLocale].settingsTab.taskTimeout}</td>
                <td>
                  <span>
                    <Input disabled value={config?.api.user_task_timeout} />
                  </span>
                </td>
                <td>
                  <span>
                    <Field
                      name="taskTimeout"
                      component={Input}
                      value={taskTimeout}
                      disabled={isInherited}
                      onChange={(e): void => setTaskTimeout(e.target.value as string)}
                      defaultValue={taskTimeout}
                      validator={!isInherited ? timeoutValidator : undefined}
                      valid={!isInherited}
                      required={!isInherited}
                    />
                  </span>
                </td>
              </tr>
            </table>
          </fieldset>
          <div className={`k-form-buttons ${settingsStyles.save_button_block}`}>
            <Button
              fill="action"
              type="submit"
              disabled={!formRenderProps.allowSubmit || !formRenderProps.valid || isDisabled()}
            >
              {localeButtons[currentLocale].save}
            </Button>
            {isShowSavedMessage && (
              <span className={settingsStyles.saved_message}>
                <i>{localeSettings[currentLocale].formSmtp.savedMessage}</i>
              </span>
            )}
          </div>
        </FormElement>
      )}
    />
  );
}
