import { Filter } from '@progress/kendo-react-data-tools';
import type { ReactElement } from 'react';
import { Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import { useParams } from 'react-router';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { getUserData } from '../../../services/local-storage-service';
import { getUrlParamsAddHosts } from '../../../utils/helpers/add-host-list-helper';
import { INITIAL_SORT_HOST } from '../../../utils/helpers/constants';
import { useHostList } from '../../../hooks/components/useHostList';
import { Input } from '../../common/baseElements/Input';
import { CommonHostList } from '../../common/CommonHostList';
import localeNewTask from '../../../utils/i18n/taskLocale/newTask.json';
import { FILTER_HOSTLIST } from '../../../utils/helpers/constant-serv-filter-host';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNewTask;

export function SensorHostListTab({ hostsIds }: { hostsIds?: string[] }): ReactElement {
  const dataForHooks = {
    name: '',
    componentName: 'SensorHostListTab',
  };

  const { sensorId } = useParams<{ sensorId: string }>();
  const currentUser = getUserData();
  const urlParams = getUrlParamsAddHosts(dataForHooks.componentName, INITIAL_SORT_HOST);

  const {
    handleAsset,
    filter,
    onFilterChange,
    customSearch,
    sort,
    dataDashboard,
    page,
    handleCustomSearch,
    total,
    pageChange,
    setSort,
  } = useHostList(urlParams, dataForHooks, hostsIds);

  return (
    <>
      <div
        className="topactions"
        style={{
          marginBottom: '24px',
        }}
      >
        {currentUser?.role !== 'reader_user' && (
          <Menu
            hoverOpenDelay={0}
            onSelect={(e: MenuSelectEvent): Promise<void> => handleAsset(e, sensorId)}
            className="action"
          >
            <MenuItem
              // text={localeHost[currentLocale].btnCreateHost}
              text="Добавить хост"
            >
              <MenuItem cssStyle={{ width: '140px' }} text="linux" />
              <MenuItem cssStyle={{ width: '140px' }} text="windows" />
            </MenuItem>
          </Menu>
        )}
      </div>
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <div className="filter-line">
            <Filter
              value={filter}
              onChange={onFilterChange}
              fields={FILTER_HOSTLIST[currentLocale]}
            />
            {filter.filters.length === 0 && (
              <Input
                name="customSearch"
                placeholder={localeNewTask[currentLocale].customSearchIp}
                value={customSearch}
                onChange={(e): void => handleCustomSearch(e)}
              />
            )}
          </div>
        </IntlProvider>
      </LocalizationProvider>
      <CommonHostList
        sort={sort}
        dataDashboard={hostsIds ? dataDashboard : []}
        page={page}
        total={hostsIds?.length ? total : 0}
        pageChange={pageChange}
        setSort={setSort}
        dataForHooks={dataForHooks}
        conditionSort={15}
      />
    </>
  );
}
