import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
import { history } from '../utils/history';
import type { IPageState } from '../utils/helpers/types';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import { prepareFilterVal } from './filterVal-for-service';
import type { LogsResponse } from '../types/__generated/on-premise-solution/api/logsResponse.v1';
import { urlFilterString } from '../utils/helpers/url-filter-string-helper';

export async function getAllTaskLogs(
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
  accountId?: string,
): Promise<LogsResponse> {
  // const queryString = urlFilterString(filterVal);

  // const paramsData = {
  //   skip: page.skip,
  //   limit: page.take,
  //   sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
  //   sortOrder: sort[0].dir,
  //   str: queryString,
  // };

  const result = await dashboardInstance.get<LogsResponse>(
    `/logs?type=task${accountId ? `&accountId=${accountId}` : ''}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      // params: paramsData,

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });

        if (!document.location.pathname.includes('/accounts/'))
          history.replace({
            pathname: `/lk/systemlog/tasks?${stringifiedParams}`,
          });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}

export async function getAllUserLogs(
  page: IPageState,
  sort: Array<SortDescriptor>,
  filterVal: IFilterVal[],
  accountId?: string,
): Promise<LogsResponse> {
  // const queryString = urlFilterString(filterVal);

  // const paramsData = {
  //   skip: page.skip,
  //   limit: page.take,
  //   sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
  //   sortOrder: sort[0].dir,
  //   str: queryString,
  // };

  const result = await dashboardInstance.get<LogsResponse>(
    `/logs?type=user${accountId ? `&accountId=${accountId}` : ''}`,
    {
      params: {
        skip: page.skip,
        limit: page.take,
        sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
        sortOrder: sort[0].dir,
        ...prepareFilterVal(filterVal),
      },

      // params: paramsData,

      paramsSerializer: (params) => {
        const stringifiedParams = qs.stringify(params, { encode: false });

        if (!document.location.pathname.includes('/accounts/'))
          history.replace({
            pathname: `/lk/systemlog/users?${stringifiedParams}`,
          });

        return stringifiedParams;
      },
    },
  );

  return result.data;
}
