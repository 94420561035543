/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getApiKeyId } from '../../../../services/settings-service';
import { ApiKeyDescriptionMetaBlock } from './description/ApiKeyDescriptionMetaBlock';
import { ApiKeyDescriptionContent } from './description/ApiKeyDescriptionContent';
import type { ApiKeyDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/apiKeyDashboardResponse.v1';
import { EntityDescription } from '../../../templates/EntityDescription';

export function ApiKeyDescription(): React.ReactElement {
  const { apiKeyId } = useParams<{ apiKeyId: string }>();

  const query = useQuery<ApiKeyDashboardResponse, ApiError>(['tokenId', apiKeyId], () =>
    getApiKeyId(apiKeyId),
  );

  return (
    <EntityDescription
      isLoading={query.isLoading}
      isNotFound={query.error?.code === '404'}
      entity={{
        id: apiKeyId,
        type: 'apiKey',
        name: query.data?.name,
      }}
      className="apikey-description"
    >
      <ApiKeyDescriptionMetaBlock data={query.data} />
      <ApiKeyDescriptionContent scope={query.data?.scope} />
    </EntityDescription>
  );
}
