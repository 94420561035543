/* eslint-disable react/jsx-pascal-case */
import { ErrorBoundary } from 'react-error-boundary';
import { useCallback, useEffect, type ReactElement } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { useQuery, useQueryClient } from 'react-query';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import { TabsSoftwareManagement } from './TabsSoftwareManagement';
import type { SoftwareUpdatesStatsResponse } from '../../../types/__generated/on-premise-solution/api/softwareUpdatesStatsResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getSoftManagementStats } from '../../../services/softManagement-service';
import { Button } from '../../common/baseElements/Button';
import softManagementLocale from '../../../utils/i18n/assetLocales/softwareManagement.json';
import { useCreateTask } from '../../../hooks/components/useCreateTask';
import { transformBytesToHuman } from '../../../lib/axios/helpers';
import { useSoftwareManagementContext } from '../../../hooks/useSoftwareManagementContext';
import { getAccountId } from '../../../services/local-storage-service';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof softManagementLocale;

export function SoftwareManagement({ accountId }: { accountId?: string }): ReactElement {
  const { createTaskForAsset } = useCreateTask();
  const { selectedSoftwareForUpdate, setSelectedAccountId } = useSoftwareManagementContext();
  const queryClient = useQueryClient();

  const query = useQuery<SoftwareUpdatesStatsResponse, ApiError>(
    ['softwareStats'],
    () => getSoftManagementStats(),
    {
      // staleTime: DATA_CACHE_STALETIME,
      // cacheTime: DATA_CACHE_CACHETIME,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const createTaskHandler = useCallback(() => {
    createTaskForAsset({
      val: 'windows_software_management',
      data: undefined,
      assetId: undefined,
      hostname: undefined,
    });
  }, []);

  useEffect(() => {
    setSelectedAccountId(accountId || getAccountId());
  }, []);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="software-management">
        <div className={`common-header-page${accountId ? ' no-header-top-margin' : ''}`}>
          {!accountId && (
            <Typography.h3 className="title">
              <div className="device-title">{softManagementLocale[currentLocale].title}</div>
            </Typography.h3>
          )}
          <div className="topactions">
            <Button
              fill="action"
              onClick={createTaskHandler}
              disabled={
                !selectedSoftwareForUpdate || Object.keys(selectedSoftwareForUpdate).length === 0
              }
            >
              {softManagementLocale[currentLocale].buttons.createTask}
            </Button>
            <Button
              //  onClick={handleRefresh}
              icon="refresh"
              onClick={(): void => {
                queryClient.invalidateQueries('savedSoftwareList');
              }}
            >
              {softManagementLocale[currentLocale].buttons.refresh}
            </Button>
          </div>
        </div>
        <div className="common-summary-page">
          <table className="column column-first">
            <tbody>
              <tr>
                <td>{softManagementLocale[currentLocale].metablock.availableSoftwareCount}</td>
                <td>
                  {typeof query.data?.availableSoftwareUpdateCount === 'number'
                    ? query.data?.availableSoftwareUpdateCount
                    : '-'}
                </td>
              </tr>
              <tr>
                <td>{softManagementLocale[currentLocale].metablock.approvedCount}</td>
                <td>
                  {typeof query.data?.softwareUpdateApproveCount === 'number'
                    ? query.data?.softwareUpdateApproveCount
                    : '-'}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="column column-second">
            <tbody>
              <tr>
                <td>{softManagementLocale[currentLocale].metablock.channelUpdateDate}</td>
                <td>
                  {query.data?.latestUpdatePublishedAt
                    ? new Date(query.data?.latestUpdatePublishedAt).toLocaleString()
                    : '-'}
                </td>
              </tr>
              <tr>
                <td>{softManagementLocale[currentLocale].metablock.storageSize}</td>
                <td>
                  {typeof query.data?.storageSize === 'number'
                    ? transformBytesToHuman(query.data?.storageSize, 2, currentLocale)
                    : '-'}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <TabsSoftwareManagement mainParentName={accountId ? 'AccountDescription' : undefined} />
      </div>
    </ErrorBoundary>
  );
}
