import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import styles from '../HostDescription.module.scss';
import localeHostDescription from '../../../../../utils/i18n/assetLocales/hostDescription.json';
import localeSensor from '../../../../../utils/i18n/sensorLocales/sensor.json';
import type { AssetHostDashboardResponse } from '../../../../../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import { CommonTooltip } from '../../../../common/CommonTooltip';
import { SensorDropdownListTd } from '../../../Sensor/SensorDropdownList';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';

export function TableFHostMetaBlock({
  data,
  currentLocale,
}: {
  data: AssetHostDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const { setShowConnectedBy } = useAssetCreatePropContext();

  useEffect(() => {
    return () => {
      setShowConnectedBy(false);
    };
  }, []);

  return (
    <table className="column column-first">
      <tbody>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.hostname}</span>
          </td>
          <td>
            {data?.type === 'host' &&
            data.latestInventorization &&
            data?.latestInventorization?.hostname ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span className={styles.hostname_bold} style={{ marginRight: '8px' }}>
                  {data?.latestInventorization?.hostname}
                </span>
                <div>
                  {data.latestAudit?.isRebootPending && (
                    <CommonTooltip icon="reboot">
                      {localeHostDescription[currentLocale].rebootTooltip}
                    </CommonTooltip>
                  )}
                </div>
              </div>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.ip}</span>
          </td>
          <td>
            {data?.type === 'host' &&
            data?.latestInventorization &&
            data?.latestInventorization?.ip ? (
              <span> {data?.latestInventorization?.ip}</span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.system}</span>
          </td>
          <td>
            {data?.type === 'host' &&
            data?.latestInventorization &&
            data?.latestInventorization?.os ? (
              <span>
                {data?.latestInventorization?.os.name} {data?.latestInventorization?.os.version}
              </span>
            ) : (
              <span>-</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.idAsset}</span>
          </td>
          <td>
            <span> {data?.type === 'host' ? data?.assetId : '-'}</span>
          </td>
        </tr>
        <tr>
          <td>{localeSensor[currentLocale].mainList.connectedTo}</td>
          {/* <SensorDropdownListTd connectedById={data?.sensorId} /> */}
          {/* <td>
            {data?.sensor?.sensorId ? (
              <NavLink to={`/lk/sensors/${data?.sensor?.sensorId}`}>{data?.sensor.name}</NavLink>
            ) : (
              localeSensor[currentLocale].mainServer
            )}
          </td> */}
          <SensorDropdownListTd
            assetId={data?.assetId}
            assetType="host"
            parentId={data?.sensor?.sensorId}
          />
        </tr>
      </tbody>
    </table>
  );
}
