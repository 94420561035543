import { useCallback, useEffect, useState } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useQueryClient } from 'react-query';
import { Button } from '../../../common/baseElements/Button';
import { useHostCreateContext } from '../../../../hooks/useHostCreateContext';
import { HostConfirm } from './HostConfirm';
import localeWindowsForm from '../../../../utils/i18n/dashboardLocale/addWindowsAssetForm.json';
import localeDashboardForm from '../../../../utils/i18n/dashboardLocale/addDashboardForm.json';
import localeErrorMessage from '../../../../utils/i18n/commonLocales/errorMessage.json';
import localeSensor from '../../../../utils/i18n/sensorLocales/sensor.json';
import { SetCredentialForHost } from './childComponents/SetCredentialForHost';
import { CreateWindowsHost } from './childComponents/CreateWindowsHost';
import { useHostActions } from '../../../../hooks/components/useHostActions';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import { useCredentialContext } from '../../../../hooks/useCredentialContext';
import { useCommonContext } from '../../../../hooks/useCommonContext';
import { AssetParamForm } from './AssetParamForm';
import { useAssetCreatePropContext } from '../../../../hooks/useAssetCreatePropContext';
import { CreateLinuxHost } from './childComponents/CreateLinuxHost';
import { useSensorContext } from '../../../../hooks/useSensorContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeWindowsForm;

export function AddHostFormWrap(): React.ReactElement {
  const queryClient = useQueryClient();

  const [selected, setSelected] = useState<number>(0);

  const { hostCreateData } = useHostCreateContext();

  const { errCreateHostMutation } = useAssetCreatePropContext();

  const {
    credentialHost,
    setCredentialData,
    setCredentialHost,
    setCheckCredentilsResp,
    setCheckingCredential,
  } = useCredentialContext();

  const { ipHost } = useHostModalContext();

  const { resultCheckIp } = useCommonContext();

  const { setDropdownParentId } = useSensorContext();

  const { cancelCreateHost, handleAddHost, isConfirm, setConfirm, handleCancelHost } =
    useHostActions();

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  useEffect(() => {
    setCheckCredentilsResp(undefined);
    setCheckingCredential(false);
    queryClient.cancelMutations();

    return (): void => {
      setCredentialData(null);
      setCredentialHost(undefined);
      setDropdownParentId('');
    };
  }, []);

  return (
    <>
      {isConfirm && <HostConfirm setConfirm={setConfirm} handleCancel={cancelCreateHost} />}
      {hostCreateData?.assetType === 'windows' && hostCreateData && (
        <Dialog
          title={localeWindowsForm[currentLocale].title}
          onClose={handleCancelHost}
          className="modal small"
        >
          <div className="create-linux-asset k-form">
            <div className="form-content">
              <div className="create-windows-asset__header">
                <p>{localeWindowsForm[currentLocale].header.content1}</p>
                <span> {localeWindowsForm[currentLocale].header.ulText}</span>
                <ul>
                  <li>{localeWindowsForm[currentLocale].header.li1} </li>
                  <li>{localeWindowsForm[currentLocale].header.li2}</li>
                  <li>{localeWindowsForm[currentLocale].header.li3}</li>
                </ul>
                <p>
                  {localeDashboardForm[currentLocale].header.content2}{' '}
                  <a href="/lk/doc/#/?id=Автоматизированная-установка" target="_blank">
                    {localeDashboardForm[currentLocale].header.content2Link}
                  </a>
                </p>
              </div>
              <TabStrip selected={selected} onSelect={handleSelect} className="asset-tabstrip">
                <TabStripTab title={localeDashboardForm[currentLocale].tabs.installAgentTitle}>
                  <CreateWindowsHost />
                </TabStripTab>
                <TabStripTab title={localeDashboardForm[currentLocale].tabs.installCredential}>
                  <SetCredentialForHost type={hostCreateData?.assetType} />
                </TabStripTab>
              </TabStrip>
            </div>
            <AssetParamForm />
            <div className="error-common-field">
              <span>{errCreateHostMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button
                onClick={handleAddHost}
                type="submit"
                disabled={
                  selected === 1 &&
                  (!credentialHost ||
                    ipHost === '' ||
                    resultCheckIp === localeErrorMessage[currentLocale].form.useName)
                }
              >
                {localeWindowsForm[currentLocale].btnAddAsset}
              </Button>
              <Button className="btn-asset-cancel" onClick={handleCancelHost}>
                {localeWindowsForm[currentLocale].btnCancelAsset}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      {hostCreateData?.assetType === 'linux' && hostCreateData && (
        <Dialog
          title={localeDashboardForm[currentLocale].title}
          onClose={handleCancelHost}
          className="modal small"
        >
          <div className="create-linux-asset k-form">
            <div className="form-content">
              <div className="create-linux-asset__header">
                <p>{localeDashboardForm[currentLocale].header.content1}</p>
                <span> {localeDashboardForm[currentLocale].header.ulText}</span>
                <ul>
                  <li>
                    {localeDashboardForm[currentLocale].header.li1.text}{' '}
                    <i>{localeDashboardForm[currentLocale].header.li1.li1Italic}</i>
                  </li>
                  <li>{localeDashboardForm[currentLocale].header.li2}</li>
                </ul>
                <p>
                  {localeDashboardForm[currentLocale].header.content2}{' '}
                  <a href="/lk/doc/#/?id=Автоматизированная-установка" target="_blank">
                    {localeDashboardForm[currentLocale].header.content2Link}
                  </a>
                </p>
              </div>
              <TabStrip selected={selected} onSelect={handleSelect} className="asset-tabstrip">
                <TabStripTab title={localeDashboardForm[currentLocale].tabs.installAgentTitle}>
                  <CreateLinuxHost />
                </TabStripTab>
                <TabStripTab title={localeDashboardForm[currentLocale].tabs.installCredential}>
                  <SetCredentialForHost />
                </TabStripTab>
              </TabStrip>
            </div>
            <AssetParamForm />
            <div className="error-common-field">
              <span>{errCreateHostMutation}</span>
            </div>
            <div className="k-form-buttons">
              <Button
                onClick={handleAddHost}
                type="submit"
                disabled={
                  selected === 1 &&
                  (!credentialHost ||
                    ipHost === '' ||
                    resultCheckIp === localeErrorMessage[currentLocale].form.useName ||
                    errCreateHostMutation.length > 0)
                }
              >
                {localeDashboardForm[currentLocale].btnAddAsset}
              </Button>
              <Button onClick={handleCancelHost}>
                {localeDashboardForm[currentLocale].btnCancelAsset}
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </>
  );
}
