/* eslint-disable react-hooks/exhaustive-deps */
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import type { TextFilterProps } from '@progress/kendo-react-data-tools/dist/npm/filter/filters/TextFilter';
import type localeTask from '../../../utils/i18n/taskLocale/task.json';
import type { IDaraFilterSelect } from '../../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

let dataStatus = [] as IDaraFilterSelect[];
export function FilterStatusAndType(props: TextFilterProps): React.ReactElement {
  const { filter, onFilterChange } = props;

  if (document.location.pathname.includes('/lk/audit/tasks')) {
    dataStatus = [
      { text: currentLocale === 'ru-RU' ? 'Выбрать статус...' : 'Select status...', val: '' },
      { text: currentLocale === 'ru-RU' ? 'Ожидание' : 'Waiting', val: 'waiting' },
      { text: currentLocale === 'ru-RU' ? 'Выполняется' : 'Processing', val: 'processing' },
    ];
  } else if (document.location.pathname.includes('/lk/assets/registries')) {
    dataStatus = [
      { text: currentLocale === 'ru-RU' ? 'Выбрать тип...' : 'Select type...', val: '' },
      { text: currentLocale === 'ru-RU' ? 'private' : 'private', val: 'private' },
      {
        text: currentLocale === 'ru-RU' ? 'hub.docker.com' : 'hub.docker.com',
        val: 'hub.docker.com',
      },
    ];
  } else if (document.location.pathname.includes('/lk/audit/history')) {
    dataStatus = [
      { text: currentLocale === 'ru-RU' ? 'Выбрать статус...' : 'Select status...', val: '' },
      { text: currentLocale === 'ru-RU' ? 'Выполнено' : 'Success', val: 'success' },
      { text: currentLocale === 'ru-RU' ? 'Таймаут' : 'Timeout', val: 'timeout' },
      { text: currentLocale === 'ru-RU' ? 'Не выполнено' : 'Fail', val: 'fail' },
    ];
  } else if (document.location.pathname.includes('/assets/devices')) {
    dataStatus = [
      { text: currentLocale === 'ru-RU' ? 'Тип устройства...' : 'Type of device...', val: '' },
      { text: currentLocale === 'ru-RU' ? 'Маршрутизатор' : 'Router', val: 'router' },
      { text: currentLocale === 'ru-RU' ? 'Межсетевой экран' : 'Firewall', val: 'firewall' },
      { text: currentLocale === 'ru-RU' ? 'Коммутатор' : 'Switch', val: 'switch' },
      { text: currentLocale === 'ru-RU' ? 'Гипервизор' : 'Hypervisor', val: 'hypervisor' },
    ];
  } else if (document.location.pathname.includes('/package_update')) {
    dataStatus = [
      { text: currentLocale === 'ru-RU' ? 'Выбрать тип...' : 'Select type"...', val: '' },
      { text: currentLocale === 'ru-RU' ? 'Удален' : 'Deleted', val: 'deleted' },
      { text: currentLocale === 'ru-RU' ? 'Установлен' : 'Installed', val: 'installed' },
      { text: currentLocale === 'ru-RU' ? 'Не обновлен' : 'Not updated', val: 'notUpdated' },
      { text: currentLocale === 'ru-RU' ? 'Обновлен' : 'Updated', val: 'updated' },
    ];
  } else if (document.location.pathname.includes('/software_management')) {
    dataStatus = [
      { text: currentLocale === 'ru-RU' ? 'Выбрать тип...' : 'Select type"...', val: '' },
      { text: currentLocale === 'ru-RU' ? 'Установлен' : 'Installed', val: 'installedSoftware' },
      { text: currentLocale === 'ru-RU' ? 'Удалён' : 'Uninstalled', val: 'uninstalledSoftware' },
      { text: currentLocale === 'ru-RU' ? 'Обновлено' : 'Updated', val: 'updatedSoftware' },
      {
        text: currentLocale === 'ru-RU' ? 'Не обновлено' : 'Not updated',
        val: 'notUpdatedSoftware',
      },
      {
        text: currentLocale === 'ru-RU' ? 'Нет доступных обновлений' : 'No available updates',
        val: 'noAvailableUpdatesForSoftware',
      },
    ];
  } else if (document.location.pathname.includes('/users/list')) {
    dataStatus = [
      { text: currentLocale === 'ru-RU' ? 'Выбрать тип...' : 'Select type"...', val: '' },
      { text: currentLocale === 'ru-RU' ? 'Локальная' : 'Local', val: 'local' },
      { text: currentLocale === 'ru-RU' ? 'LDAP' : 'LDAP', val: 'ldap' },
      { text: currentLocale === 'ru-RU' ? 'AD' : 'AD', val: 'activedirectory' },
    ];
  } else if (document.location.pathname.includes('/audit/exceptions')) {
    dataStatus = [
      { text: currentLocale === 'ru-RU' ? 'Тип устройства...' : 'Type of device...', val: '' },
      { text: currentLocale === 'ru-RU' ? 'Хост' : 'Host', val: 'host' },
      { text: currentLocale === 'ru-RU' ? 'Образ' : 'Image', val: 'image' },
      { text: currentLocale === 'ru-RU' ? 'Маршрутизатор' : 'Router', val: 'router' },
      { text: currentLocale === 'ru-RU' ? 'Межсетевой экран' : 'Firewall', val: 'firewall' },
      { text: currentLocale === 'ru-RU' ? 'Коммутатор' : 'Switch', val: 'switch' },
    ];
  } else if (document.location.pathname.includes('/sensors')) {
    dataStatus = [
      { text: currentLocale === 'ru-RU' ? 'Статус сенсора...' : 'Sensor status...', val: '' },
      { text: currentLocale === 'ru-RU' ? 'Активирован' : 'Activated', val: 'activated' },
      { text: currentLocale === 'ru-RU' ? 'Отключен' : 'Disabled', val: 'disabled' },
      { text: currentLocale === 'ru-RU' ? 'Не активирован' : 'Not activated', val: 'notActivated' },
    ];
  }

  const handleChange = (event: DropDownListChangeEvent): void => {
    onFilterChange({
      nextFilter: { ...filter, ...{ value: event.target.value.val, text: '' } },
    });
  };

  return (
    <DropDownList
      defaultValue={dataStatus[0]}
      data={dataStatus}
      textField="text"
      dataItemKey="val"
      onChange={handleChange}
    />
  );
}
