import type { GridCellProps } from '@progress/kendo-react-grid';
import type { TreeListCellProps } from '@progress/kendo-react-treelist';
import type { ReactElement } from 'react';
import localeHost from '../../../../utils/i18n/assetLocales/hostList.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeHost;

export function CellSensorDomain(props: GridCellProps | TreeListCellProps): ReactElement {
  const { dataItem } = props;

  return <td>{dataItem.hostname || localeHost[currentLocale].form.waitingForData}</td>;
}
