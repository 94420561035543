import { useState } from 'react';
import { useMutation, useQueryClient, UseMutationResult, UseQueryResult } from 'react-query';
import type { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import {
  deleteAgent,
  deleteAsset,
  editAsset,
  getAgentInstaller,
} from '../../services/asset-service';
import type { Agent } from '../../types/__generated/on-premise-solution/api/agent.v1';
import type { AgentDeletionResponse } from '../../types/__generated/on-premise-solution/api/agentDeletionResponse.v1';
import type { ApiError } from '../../types/__generated/on-premise-solution/api/apiError.v1';
import type { AssetDeletionResponse } from '../../types/__generated/on-premise-solution/api/assetDeletionResponse.v1';
import type { AssetUpdateRequest } from '../../types/__generated/on-premise-solution/api/assetUpdateRequest.v1';
import type { Asset } from '../../types/__generated/on-premise-solution/api/asset.v1';
import type { ISelectedCredential } from '../../utils/helpers/types';
import { useHostCreateContext } from '../useHostCreateContext';
import { useHostModalContext } from '../useHostModalContext';
import { useCredentialContext } from '../useCredentialContext';
import { useAssetCreatePropContext } from '../useAssetCreatePropContext';
import type { Tag } from '../../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import { getAccountId } from '../../services/local-storage-service';
import { useCommonContext } from '../useCommonContext';
import { useTaskAddContext } from '../useTaskAddContext';
import { useMaintenanceWindowsContext } from '../useMaintenanceWindowsContext';
import { useSensorContext } from '../useSensorContext';

export interface IUseHostActions {
  handleEditHost: (
    assetId: string | undefined,
    payload: AssetUpdateRequest,
    needUpdateInstallerInfo?: {
      assetType: 'linux' | 'windows';
      accountId: string | null;
      hostname?: string;
    },
  ) => void;
  handleDelCredential: (assetId: string, data: AssetUpdateRequest) => void;
  selectCredential: (credential: ISelectedCredential | undefined) => void;
  cancelCreateHost: () => void;
  mutation: UseMutationResult<
    AssetUpdateRequest,
    ApiError,
    {
      payloadAssetId: string;
      payload: AssetUpdateRequest;
    },
    unknown
  >;
  mutationHost: UseMutationResult<AssetDeletionResponse | null, ApiError, string, unknown>;
  mutationAgent: UseMutationResult<AgentDeletionResponse | null, ApiError, string, unknown>;
  handleAddHost: () => void;
  isConfirm: boolean;
  setConfirm: (v: boolean) => void;
  handleCancelHost: () => void;
  handlePort: (val: ISelectedCredential) => void;
  changeImportance: (event: DropDownListChangeEvent) => void;
  changeHostType: (event: DropDownListChangeEvent) => void;
  changePerimeterImpactText: (event: DropDownListChangeEvent) => void;
  deleTagFromHost: (
    tag: string,
    tags: Tag[] | null | undefined,
    tagIds: string[] | null | undefined,
    assetId: string,
  ) => void;
  editIpHost: (val: string) => void;
}

export interface ICredentialAndIp {
  ip: string | undefined;
  port: number | undefined;
  сredentialId: string | null | undefined;
  hostname?: string | undefined;
  typeOfPort?: string | undefined;
  url?: string;
  baseDN?: string;
  ldapId?: string;
  authType?: string;
}

export function useHostActions(): IUseHostActions {
  const [isConfirm, setConfirm] = useState(false);

  const { setIpHost, setAssetName, setEditPort, setHostName } = useHostModalContext();

  const {
    setSelectedCredential,
    setCredentialData,
    setCheckCredentilsResp,
    setIdValue,
    setCheckErrorMutation,
    setCredentialDev,
    setCredentialHost,
    setCredentialVault,
    setErrorMutation,
  } = useCredentialContext();

  const { hostCreateData, setHostCreateData } = useHostCreateContext();
  const { closeWindow } = useMaintenanceWindowsContext();
  const { dropdownParentId, setDropdownParentId } = useSensorContext();

  const {
    clearAddHost,
    setErrCreateHostMutation,
    setSelectedHostType,
    setPerimeterImpact,
    setSelectedImportance,
    setShowImportance,
    setShowHostType,
    setShowPerimeterImpact,
    mutableAssetProps,
    setSavedImportance,
    setSavedHostType,
    setSavedPerimeterImpact,
    selectedHostType,
    selectedImportance,
    perimeterImpact,
  } = useAssetCreatePropContext();

  const { handleCommonCreds } = useTaskAddContext();

  const { setResultCheckIp } = useCommonContext();

  const queryClient = useQueryClient();

  const mutation = useMutation<
    AssetUpdateRequest,
    ApiError,
    { payloadAssetId: string; payload: AssetUpdateRequest }
  >(({ payloadAssetId, payload }) => editAsset(payloadAssetId, payload), {
    onSuccess: (response) => {
      queryClient.invalidateQueries('dashboardAssetId');
      queryClient.invalidateQueries('sensorDescription');
      if (response.type !== 'image') setDropdownParentId(response.sensorId || undefined);
      closeWindow();
      setCheckCredentilsResp(undefined);
      if (
        response.type === 'router' ||
        response.type === 'firewall' ||
        response.type === 'switch' ||
        response.type === 'hypervisor'
      ) {
        queryClient.invalidateQueries('deviceAssetId');
        setCredentialDev(undefined);
      }
      // if (payload.payload.importance) setSavedImportance(true);
      // if (payload.payload.subType) setSavedHostType(true);
      // if (payload.payload.perimeterImpact) setSavedPerimeterImpact(true);
    },
    onError: (response) => {
      setErrCreateHostMutation(response.message);
    },
  });

  const handleEditHost = (
    assetId: string | undefined,
    payload: AssetUpdateRequest,
    needUpdateInstallerInfo?: {
      assetType: 'linux' | 'windows';
      accountId: string | null;
      hostname?: string;
    },
  ): void => {
    mutation
      .mutateAsync({
        payloadAssetId: assetId || '',
        payload,
      })
      .then(async () => {
        if (needUpdateInstallerInfo) {
          const dataAssetDashboard = await getAgentInstaller(
            needUpdateInstallerInfo.assetType,
            assetId || null,
          );
          setHostCreateData({
            ...dataAssetDashboard,
            ...needUpdateInstallerInfo,
          });
        }
      });
  };

  const handlePort = (val: ISelectedCredential): void => {
    setSelectedCredential(val);
    setEditPort(true);
  };

  const mutationHost = useMutation<AssetDeletionResponse | null, ApiError, Asset['assetId']>(
    (payload) => deleteAsset(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('hostList');
      },
    },
  );

  const mutationAgent = useMutation<AgentDeletionResponse | null, ApiError, Agent['agentId']>(
    (payload) => deleteAgent(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('hostList');
      },
    },
  );

  const cancelCreateHost = (): void => {
    if (hostCreateData?.assetId && hostCreateData?.agentId) {
      mutationHost.mutate(hostCreateData.assetId);
      mutationAgent.mutate(hostCreateData.agentId);
    }
    setHostCreateData(null);
    setIpHost('');
    setHostName('');
    setCheckCredentilsResp(undefined);
    hostCreateData?.queryFn?.refetch();
    setCredentialData(null);
    setSelectedCredential(null);
  };

  const handleDelCredential = (assetId: string, data: AssetUpdateRequest): void => {
    const payload = {
      accountId: data.accountId,
      type: data.type,
      credentialId: null,
    };
    mutation.mutateAsync({
      payloadAssetId: assetId,
      payload,
    });
    setCredentialHost(undefined);
  };

  const selectCredential = (credential: ISelectedCredential | undefined): void => {
    if (
      (credential?.assetType === 'commonwinrm' && credential.type) ||
      (credential?.assetType === 'commonssh' && credential.type)
    ) {
      handleCommonCreds(credential);
    }
    setCheckCredentilsResp(undefined);
    setCheckErrorMutation('');
    setSelectedCredential(credential);
    if (
      credential?.type === 'hashicorp_vault_userpass' ||
      credential?.type === 'hashicorp_vault_token'
    ) {
      setCredentialVault({
        credentialId: credential.credentialId || undefined,
        credentialName: credential.credentialName,
        credentialType: credential.type,
        credentialSshPort: credential.sshPort,
      });
    }
  };

  const handleAddHost = (): void => {
    setHostCreateData(null);
    setDropdownParentId('');
    hostCreateData?.queryFn?.refetch();
    // setIpHost('');
    setHostName('');
    setCredentialData(null);
    setAssetName(false);
    // setSelectedHostType(hostSubType[0]);
    // setSelectedImportance(hostImportance[1]);
    const payload = {
      type: 'host',
      accountId: getAccountId() || '',
      importance: selectedImportance?.val || undefined,
      subType: selectedHostType?.val || undefined,
      perimeterImpact: perimeterImpact ? 1 : 0.5,
      sensorId: dropdownParentId || undefined,
      // ip: ipHost,
    } as AssetUpdateRequest;
    handleEditHost(hostCreateData?.assetId, payload);
    setIpHost('');
    setErrCreateHostMutation('');
  };

  const changeImportance = (event: DropDownListChangeEvent): void => {
    setSelectedImportance(event.target.value);
    setShowImportance(false);
    if (mutableAssetProps) {
      const payload = {
        type: mutableAssetProps.type,
        accountId: mutableAssetProps.accountId,
        importance: event.target.value.val,
      } as AssetUpdateRequest;
      handleEditHost(mutableAssetProps.assetId, payload);
      setSavedImportance(true);
    }
  };

  const changeHostType = (event: DropDownListChangeEvent): void => {
    setSelectedHostType(event.target.value);
    setShowHostType(false);
    if (mutableAssetProps) {
      const payload = {
        type: mutableAssetProps.type,
        accountId: mutableAssetProps.accountId,
        subType: event.target.value.val,
      } as AssetUpdateRequest;
      handleEditHost(mutableAssetProps.assetId, payload);
      setSavedHostType(true);
    }
  };

  const changePerimeterImpactText = (event: DropDownListChangeEvent): void => {
    setPerimeterImpact(event.target.value);
    setShowPerimeterImpact(false);
    if (mutableAssetProps) {
      const payload = {
        type: mutableAssetProps.type,
        accountId: mutableAssetProps.accountId,
        perimeterImpact: event.target.value.val,
      } as AssetUpdateRequest;
      handleEditHost(mutableAssetProps.assetId, payload);
      setSavedPerimeterImpact(true);
    }
  };

  const handleCancelHost = (): void => {
    queryClient.cancelMutations();
    setDropdownParentId('');
    setConfirm(true);
    setIdValue(null);
    clearAddHost();
    setErrCreateHostMutation('');
    setCheckErrorMutation('');
    setResultCheckIp(null);
    setAssetName(false);
    setResultCheckIp(null);
  };

  const deleTagFromHost = (
    tag: string,
    tags: Tag[] | null | undefined,
    tagIds: string[] | null | undefined,
    assetId: string,
  ): void => {
    let tagIdsData: string[] | undefined = [];

    if (tagIds && tagIds.length === 1 && tagIds[0] === tag) {
      tagIdsData = [];
    } else if (tagIds && tagIds.length > 1) {
      tagIdsData = tagIds?.filter((t) => t !== tag);
    }

    const val = {
      tagIds: tagIdsData && tagIdsData.length > 0 ? [...tagIdsData] : null,
      type: 'host',
    } as AssetUpdateRequest;
    handleEditHost(assetId, val);
  };

  const editIpHost = (val: string): void => {
    setResultCheckIp(null);
    setErrorMutation('');
    setCheckErrorMutation('');
    setErrCreateHostMutation('');
    setIpHost(val);
  };

  return {
    handleEditHost,
    handleDelCredential,
    selectCredential,
    cancelCreateHost,
    mutationHost,
    mutationAgent,
    handleAddHost,
    isConfirm,
    setConfirm,
    handleCancelHost,
    handlePort,
    changeImportance,
    changeHostType,
    changePerimeterImpactText,
    deleTagFromHost,
    editIpHost,
    mutation,
  };
}
