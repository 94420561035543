/* eslint-disable react/jsx-pascal-case */
import { useState, type ReactElement } from 'react';
import { Stepper, StepperChangeEvent } from '@progress/kendo-react-layout';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Button } from '../../common/baseElements/Button';
import { useSensorContext } from '../../../hooks/useSensorContext';
import styles from '../Host/forms/AddAssetForm.module.scss';
import localeDashboardForm from '../../../utils/i18n/dashboardLocale/addDashboardForm.json';
import localeButtons from '../../../utils/i18n/commonLocales/buttons.json';
import localeSensor from '../../../utils/i18n/sensorLocales/sensor.json';
import { STEPPER_SENSOR } from '../../../utils/helpers/constants';
import { SensorDropdownList } from './SensorDropdownList';
import { HostNotification } from '../Host/hostComponents/HostNotification';
import { CommonTooltip } from '../../common/CommonTooltip';
import type { SensorInstallerDownloadUrlResponse } from '../../../types/__generated/on-premise-solution/api/sensorInstallerDownloadUrlResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSensor;

function addSudoToCommand(command: string | undefined): string {
  if (!command) return '';

  return command.startsWith('sudo ') ? command : `sudo ${command}`;
}

export function CreateSensorBlock({
  data,
  onChangeDropdown,
  isReinstall,
}: {
  data: SensorInstallerDownloadUrlResponse | null | undefined;
  onChangeDropdown?: () => void;
  isReinstall?: boolean;
}): ReactElement {
  const { handleLinkDowload } = useSensorContext();

  const [step, setStep] = useState(0);

  const [copied, setCopied] = useState(false);

  const handleCopied = (e: number | StepperChangeEvent): void => {
    if (typeof e === 'number') {
      setStep(e);
      const timeout = setTimeout(() => {
        setCopied(false);
        clearTimeout(timeout);
      }, 900);
    } else {
      setStep(e.value);
    }
  };

  return (
    <div className="create-linux-asset__content">
      {!isReinstall && (
        <div className="create-linux-asset__header">
          <p>{localeSensor[currentLocale].addModal.content1}</p>
          <span> {localeSensor[currentLocale].addModal.ulText}</span>
          <ul>
            <li>{localeSensor[currentLocale].addModal.liText1}</li>
            <li>{localeSensor[currentLocale].addModal.liText2}</li>
          </ul>
          <p
            style={{
              marginBottom: '32px',
            }}
          >
            <br />
            <a href="/lk/doc/#/?id=Сенсор" target="_blank">
              {localeSensor[currentLocale].addModal.moreInfoLink}
            </a>
          </p>
        </div>
      )}
      <div className={styles.asset_form}>
        <Stepper
          value={step}
          onChange={handleCopied}
          items={STEPPER_SENSOR}
          orientation="vertical"
          className="sensor-install-stepper"
        />
        <div className="asset-form__content">
          <div className="sensor-form__parent">
            <p
              style={{
                marginTop: '8px',
                display: 'flex',
                gap: '4px',
                position: 'relative',
              }}
            >
              {/* {localeNewTask[currentLocale].form.typeTask.title} */}
              {localeSensor[currentLocale].addModal.connectingByField}:
              <CommonTooltip>
                {localeSensor[currentLocale].addModal.connectingByTooltip}
              </CommonTooltip>
            </p>
            <SensorDropdownList onChangeDropdown={onChangeDropdown} />
          </div>
          <div className="sensor-form__download">
            <p style={{ marginTop: '8px' }}>{localeSensor[currentLocale].addModal.step2content}</p>
            <Button
              className="btn-download"
              icon="download"
              onClick={(e): void => {
                e.preventDefault();
                handleLinkDowload(data?.url);
              }}
            >
              {localeButtons[currentLocale].download}
            </Button>
            <p>{localeDashboardForm[currentLocale].form.step1.content2}</p>
            <CopyToClipboard
              text={addSudoToCommand(data?.commands[0])}
              onCopy={(): void => setCopied(true)}
            >
              <div className="code-container">
                <span>{addSudoToCommand(data?.commands[0])}</span>
                <div className={styles.btn_block}>
                  <Button
                    fill="copy"
                    onClick={(e): void => {
                      e.preventDefault();
                      handleCopied(1);
                    }}
                  />
                  {step === 1 && <HostNotification copied={copied} />}
                </div>
              </div>
            </CopyToClipboard>
          </div>
          <div className="sensor-form__rights">
            <p>{localeSensor[currentLocale].addModal.step3content}</p>
            <CopyToClipboard
              text={addSudoToCommand(data?.commands[1])}
              onCopy={(): void => setCopied(true)}
            >
              <div className="code-container">
                <div className="text-container">
                  <span>{addSudoToCommand(data?.commands[1])}</span>
                </div>
                <div className={styles.btn_block}>
                  <Button
                    fill="copy"
                    onClick={(e): void => {
                      e.preventDefault();
                      handleCopied(3);
                    }}
                  />
                  {step === 3 && <HostNotification copied={copied} />}{' '}
                </div>
              </div>
            </CopyToClipboard>
          </div>
          <div className="sensor-form__rights">
            <p>{localeSensor[currentLocale].addModal.step4content}</p>
            <CopyToClipboard
              text={addSudoToCommand(data?.commands[2])}
              onCopy={(): void => setCopied(true)}
            >
              <div className="code-container">
                <div className="text-container">
                  <span>{addSudoToCommand(data?.commands[2])}</span>
                </div>
                <div className={styles.btn_block}>
                  <Button
                    fill="copy"
                    onClick={(e): void => {
                      e.preventDefault();
                      handleCopied(4);
                    }}
                  />
                  {step === 4 && <HostNotification copied={copied} />}{' '}
                </div>
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
}
