import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '../../common/baseElements/Button';
import localeDashboardForm from '../../../utils/i18n/dashboardLocale/addDashboardForm.json';
import localeConfirmDelete from '../../../utils/i18n/commonLocales/confirmDeleteForm.json';
import localeButtons from '../../../utils/i18n/commonLocales/buttons.json';

export function SensorConfirm({
  setConfirm,
  handleCancel,
}: {
  setConfirm: (v: boolean) => void;
  handleCancel: () => void;
}): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeDashboardForm;

  return (
    <Dialog
      title={localeConfirmDelete[currentLocale].title}
      onClose={(): void => setConfirm(false)}
      className="host-confirm modal small"
    >
      <div className="k-form">
        <div className="form-content">
          <p>{localeDashboardForm[currentLocale].cancelSensorText}</p>
        </div>
        <div className="k-form-buttons">
          <Button type="button" fill="action" onClick={handleCancel}>
            {localeButtons[currentLocale].yes}
          </Button>
          <Button type="button" onClick={(): void => setConfirm(false)}>
            {localeButtons[currentLocale].no}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
