/* eslint-disable react/jsx-pascal-case */
import { useState } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { useQueryClient } from 'react-query';
import locale from '../../../utils/i18n/credentials/credentials.json';
import { TabsCredentials } from './TabsCredentials';
import './Credentials.module.scss';
import { СommonTableLoader } from '../../common/tableLoader/CommonTableLoader';
import { Button } from '../../common/baseElements/Button';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function Credentials(): React.ReactElement {
  const queryClient = useQueryClient();

  const [filterStatus, setFilterStatus] = useState(false);

  const handleRefresh = (): void => {
    setFilterStatus(true);
    queryClient.invalidateQueries('credentials').then(() => setFilterStatus(false));
    queryClient.invalidateQueries('vaultslList').then(() => setFilterStatus(false));
    setFilterStatus(false);
  };

  return (
    <div className="credentials">
      <div className="common-header-page">
        <Typography.h3>{locale[currentLocale].title}</Typography.h3>
        <div className="topactions">
          <Button icon="refresh" onClick={handleRefresh}>
            {locale[currentLocale].btnRefresh}
          </Button>
        </div>
      </div>
      <TabsCredentials />
      <СommonTableLoader queryData={filterStatus} filteringData={filterStatus} />
    </div>
  );
}
