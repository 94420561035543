import { NavLink } from 'react-router-dom';
import localeSensor from '../../../../utils/i18n/sensorLocales/sensor.json';
import type { SensorDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/sensorDashboardResponse.v1';

export function TableFSensorMetaBlock({
  data,
  currentLocale,
}: {
  data: SensorDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  return (
    <table className="column column-first">
      <tbody>
        <tr>
          <td>{localeSensor[currentLocale].descriptionPage.metablock.name}</td>
          <td>{data?.name || '-'}</td>
        </tr>
        <tr>
          <td>IP</td>
          <td>{data?.ip || '-'}</td>
        </tr>
        <tr>
          <td>{localeSensor[currentLocale].descriptionPage.metablock.sensorId}</td>
          <td>{data?.sensorId || '-'}</td>
        </tr>
        <tr>
          <td>{localeSensor[currentLocale].descriptionPage.metablock.assetsCount}</td>
          <td>{typeof data?.assetCount === 'number' ? data.assetCount : '-'}</td>
        </tr>
      </tbody>
    </table>
  );
}
