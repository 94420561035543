/* eslint-disable radix */
/* eslint-disable no-bitwise */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { nanoid } from 'nanoid';
import { ColorPicker } from '@progress/kendo-react-inputs';
import { Button } from '../baseElements/Button';
import localeTag from '../../../utils/i18n/commonLocales/tags.json';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { editTag } from '../../../services/asset-service';
import styles from './Tag.module.scss';
import { useAssetCreatePropContext } from '../../../hooks/useAssetCreatePropContext';
import type { TagUpdateResponse } from '../../../types/__generated/on-premise-solution/api/tagUpdateResponse.v1';
import type { TagUpdateRequest } from '../../../types/__generated/on-premise-solution/api/tagUpdateRequest.v1';
import { backgroundColorList } from '../../../utils/helpers/constants';
import { useTagContext } from '../../../hooks/useTagContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTag;

export function ChangeTagColor(): React.ReactElement {
  const queryClient = useQueryClient();

  const [editTagError, setEditTagError] = useState<string | null>(null);

  const { handleBgColor, chooseInColorPicker } = useAssetCreatePropContext();

  const { editTagColor, setEditTagColor, setHovered } = useTagContext();

  const [bgColor, setBgColor] = useState<string>(editTagColor?.color || '');

  const mutation = useMutation<TagUpdateResponse, ApiError, TagUpdateRequest>(
    (payload) => editTag(editTagColor?.tagId || '', payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('dashboardAssetId');
        queryClient.invalidateQueries('sensorDescription');
      },
      onError: (resp) => {
        if (resp.code && resp.code === '409') {
          setEditTagError(`${localeTag[currentLocale].confirmDelTag}`);
        } else {
          setEditTagError(`${resp.code} ${resp.type}`);
        }
      },
    },
  );

  const chooseColor = (color: string): void => {
    const payload = {
      color: color && !color.includes('#') ? `#${color}` : editTagColor?.color,
    };
    if (editTagColor) {
      setEditTagColor({
        tagId: String(editTagColor.tagId),
        indexEl: editTagColor.indexEl,
        color: color && !color.includes('#') ? `#${color}` : editTagColor?.color,
      });
    }
    mutation.mutateAsync(payload);
  };

  const closeEditColor = (): void => {
    setEditTagColor(null);
    setHovered('');
  };

  return (
    <div
      className={`change-color-tag ${styles.editTagColorWrap}`}
      onClick={(e): void => chooseInColorPicker(e, chooseColor, bgColor)}
    >
      <div className={styles.editTagColorTitle}>
        <span>{localeTag[currentLocale].changeColor}</span>
        <Button
          className="button-noborder"
          onClick={closeEditColor}
          style={{
            backgroundColor: 'inherit',
          }}
        >
          <span className="k-icon k-font-icon k-i-close" />
        </Button>
      </div>
      <div className={styles.editTagColorContainer}>
        {backgroundColorList.map((c) => (
          <div
            className={styles.editTagColorElement}
            style={{ backgroundColor: `#${c}` }}
            onClick={(): void => chooseColor(c)}
            key={nanoid()}
          />
        ))}
      </div>
      <div className={`edir-color-tag ${styles.editTagColorPicker}`}>
        <ColorPicker
          view="gradient"
          value={bgColor}
          onChange={(e): void => handleBgColor(e, setBgColor)}
          onActiveColorClick={(): void => chooseColor(bgColor)}
          gradientSettings={{ opacity: false }}
        />
      </div>
    </div>
  );
}
