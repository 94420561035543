import type { ReactElement } from 'react';
import type { AssetHostDashboardResponse } from '../../../types/__generated/on-premise-solution/api/assetHostDashboardResponse.v1';
import type { SensorDashboardResponse } from '../../../types/__generated/on-premise-solution/api/sensorDashboardResponse.v1';
import { CommonTooltip } from '../CommonTooltip';
import styles from './Tag.module.scss';
import localeTag from '../../../utils/i18n/commonLocales/tags.json';
import { useTagContext } from '../../../hooks/useTagContext';
import { TagList } from './TagList';
import { AddTag } from './AddTag';
import { BASE_ADD_TAG_PROP } from '../../../utils/helpers/constants';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTag;

export function TagHeader({
  entityId,
  entityType,
  tags,
  tagIds,
}: {
  entityId: string;
  entityType: 'host' | 'sensor';
  tags: AssetHostDashboardResponse['tags'] | SensorDashboardResponse['tags'] | undefined;
  tagIds: string[] | null | undefined;
}): ReactElement {
  const { isShowAddTag, setShowAddTag } = useTagContext();

  return (
    <>
      <div>
        {(!tags || tags.length === 0) && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
          <div className={styles.commonTooltipWrap} onClick={(): void => setShowAddTag(true)}>
            <CommonTooltip
              visibleStyle={{ marginLeft: '8px', marginTop: '-19px' }}
              hiddenStyle={{ marginLeft: '32px', marginTop: '-40px' }}
              width="20px"
              height="20px"
              icon="tag"
              time={400}
            >
              {localeTag[currentLocale].editTags}
            </CommonTooltip>
          </div>
        )}
        {isShowAddTag && (
          <AddTag entityId={entityId} entityType={entityType} addTagProp={BASE_ADD_TAG_PROP} />
        )}
      </div>
      <div className={styles.tagBlock}>
        <TagList entityId={entityId} entityType={entityType} tags={tags} tagIds={tagIds} />
      </div>
    </>
  );
}
