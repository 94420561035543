import type { InventorizationResponse } from '../../../../../types/__generated/on-premise-solution/api/inventorizationsResponse.v1';
import locale from '../../../../../utils/i18n/taskLocale/inventorizationTask.json';
import { getPastTime } from '../../../../../utils/helpers/settings-helpers';
import { InventoryNetDevices } from './InventoryNetDevices';
import { WrapperMenu } from './WrapperMenu';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function BiosInfo({ data }: { data: InventorizationResponse }): React.ReactElement {
  return (
    <WrapperMenu title={locale[currentLocale].bios.title}>
      {(data.type === 'linux' || data.type === 'windows') && (
        <>
          <table className="column">
            <tbody>
              <tr>
                <td>
                  <span>{locale[currentLocale].bios.name}</span>
                </td>
                <td>
                  <span>{data.result.bios?.name}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].bios.version}</span>
                </td>
                <td>
                  <span>{data.result.bios?.version}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="column">
            <tbody>
              <tr>
                <td>
                  <span>{locale[currentLocale].bios.serialNumber}</span>
                </td>
                <td>
                  <span>{data.result.bios?.serial_number}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span>{locale[currentLocale].bios.manufacturer}</span>
                </td>
                <td>
                  <span>{data.result.bios?.manufacturer}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </WrapperMenu>
  );
}
