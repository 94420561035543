/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-pascal-case */
import { useMutation, useQueryClient } from 'react-query';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '../../common/baseElements/Button';
import localeTag from '../../../utils/i18n/commonLocales/tags.json';
import localeDelete from '../../../utils/i18n/commonLocales/confirmDeleteForm.json';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { deleteTag } from '../../../services/asset-service';
import type { TagDeletionResponse } from '../../../types/__generated/on-premise-solution/api/tagDeletionResponse.v1';
import styles from './Tag.module.scss';
import { useTagContext } from '../../../hooks/useTagContext';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTag;

export function ConfirmTagDelete(): React.ReactElement {
  const queryClient = useQueryClient();

  const { tagDataForDel, setTagDataForDel } = useTagContext();

  const mutation = useMutation<TagDeletionResponse | null, ApiError, string>(
    () => deleteTag(tagDataForDel?.tagId || ''),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tagsList');
        queryClient.invalidateQueries('dashboardAssetId');
        queryClient.invalidateQueries('sensorDescription');
      },
    },
  );

  const handleClose = (): void => {
    setTagDataForDel(null);
  };

  const handleConfirm = (): void => {
    mutation.mutate(tagDataForDel!.tagId);
    setTagDataForDel(null);
  };

  const titleModal = `${localeTag[currentLocale].deleteTagTitle} ${tagDataForDel?.tagName || '-'}`;

  return (
    <Dialog title={titleModal} onClose={handleClose} className="modal small">
      <div className="k-form">
        <div className={styles.confirmContent}>
          <p>{localeTag[currentLocale].deleteTagText}</p>
          <span>
            <b>{tagDataForDel?.tagName || ''}</b>{' '}
          </span>
          <p>{localeTag[currentLocale].deleteTagText2}</p>
        </div>
        <div>
          <p>{localeTag[currentLocale].deleteTagText3}</p>
        </div>
        <div className="k-form-buttons">
          <Button type="submit" onClick={handleConfirm}>
            {localeDelete[currentLocale].btnDeleteYes}
          </Button>
          <Button type="button" onClick={handleClose}>
            {localeDelete[currentLocale].btnDeleteNo}
          </Button>
          {mutation.isError && (
            <div className="info alert alert-danger">Error: {mutation.error?.message}</div>
          )}
        </div>
      </div>
    </Dialog>
  );
}
