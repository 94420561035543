/* eslint-disable no-bitwise */
import { useParams } from 'react-router';
import { useAssetCreatePropContext } from '../useAssetCreatePropContext';
import { useDeviceDescription } from './useDeviceDescription';
import { useImageDescription } from './useImageDescription';
import { useHostDescription } from './useHostDescription';
import type { ISecurityVulns, TBulletinId } from '../../utils/helpers/types';

interface IUseAssetActions {
  addVuln: (vulnId: string) => void;
  selectAllVulnOnPage: (vulns: ISecurityVulns[] | TBulletinId[] | undefined) => void;
}

export function useAssetActions(): IUseAssetActions {
  const { assetId: assetIdFromUrl } = useParams<{ assetId: string }>();

  const { selectedVulns, setSelectedVulns } = useAssetCreatePropContext();

  const { devicename } = useDeviceDescription();

  const { imagename } = useImageDescription();

  const { hostname } = useHostDescription();

  const addVuln = (vulnId: string): void => {
    let name;

    if (document.location.pathname.includes('assets/hosts/')) {
      name = hostname;
    } else if (document.location.pathname.includes('assets/devices/')) {
      name = devicename;
    } else {
      name = undefined;
    }

    const selectedVuln = {
      vulnId,
      assetId: assetIdFromUrl,
      hostname: name,
      imageNames: imagename,
    };

    if (selectedVulns && selectedVulns.length > 0) {
      const index = selectedVulns?.findIndex((v) => v.vulnId === vulnId);
      if (index !== -1) {
        const cutSelectedVulns = selectedVulns.filter((v) => v.vulnId !== vulnId);
        setSelectedVulns([...cutSelectedVulns]);
      } else {
        setSelectedVulns([...selectedVulns, selectedVuln]);
      }
    } else {
      setSelectedVulns([selectedVuln]);
    }
  };

  const selectAllVulnOnPage = (vulns: ISecurityVulns[] | TBulletinId[] | undefined): void => {
    const vulnsOnPage = vulns?.map((v) => ({
      vulnId: v.bulletinId,
      assetId: undefined,
      hostname: undefined,
      imageNames: undefined,
    }));

    if (selectedVulns && vulnsOnPage) {
      const selectedAndOnPage = [...selectedVulns, ...vulnsOnPage];

      const duplicatValChecked = selectedAndOnPage.filter(
        (vuln, i, arr) => arr.findIndex((val) => val.vulnId === vuln.vulnId) === i,
      );
      setSelectedVulns(duplicatValChecked);
    }
    if (!selectedVulns && vulnsOnPage) {
      setSelectedVulns(vulnsOnPage);
    }
  };

  return {
    addVuln,
    selectAllVulnOnPage,
  };
}
