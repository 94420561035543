import { getUserData } from '../../../../services/local-storage-service';
import type { SensorDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/sensorDashboardResponse.v1';
import localeSensor from '../../../../utils/i18n/sensorLocales/sensor.json';

export function TableTSensorMetaBlock({
  data,
  currentLocale,
}: {
  data: SensorDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const currentUser = getUserData();

  return (
    <table className="column column-third">
      <tbody>
        <tr>
          <td>{localeSensor[currentLocale].descriptionPage.metablock.createdAt}</td>
          <td>{data?.createdAt ? new Date(data?.createdAt).toLocaleString() : '-'}</td>
        </tr>
        <tr>
          <td>{localeSensor[currentLocale].descriptionPage.metablock.createdBy}</td>
          <td>{data?.createdBy.source || '-'}</td>
        </tr>
        {(currentUser?.role === 'super_admin' || currentUser?.role === 'account_admin') && (
          <>
            <tr>
              <td>{localeSensor[currentLocale].descriptionPage.metablock.accountId}</td>
              <td>{data?.account.accountId || '-'}</td>
            </tr>
            <tr>
              <td>{localeSensor[currentLocale].descriptionPage.metablock.accountName}</td>
              <td>{data?.account.name || '-'}</td>
            </tr>
          </>
        )}
      </tbody>
    </table>
  );
}
