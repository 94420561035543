import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '../../common/baseElements/Button';
import localeConfirmDelete from '../../../utils/i18n/commonLocales/confirmDeleteForm.json';
import localeButtons from '../../../utils/i18n/commonLocales/buttons.json';
import localeSensor from '../../../utils/i18n/sensorLocales/sensor.json';
import localeNames from '../../../utils/i18n/commonLocales/names.json';
import { useSensorContext } from '../../../hooks/useSensorContext';

export function SensorNotEmptyModal(): React.ReactElement {
  const currentLocale = (
    window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
  ) as keyof typeof localeButtons;

  const { sensorIdContext, sensorName, setSensorNotEmptyModal } = useSensorContext();
  console.log(sensorIdContext.current, sensorName.current);

  return (
    <Dialog
      title={`${localeConfirmDelete[currentLocale].title} ${
        Array.isArray(localeConfirmDelete[currentLocale].entities.sensor)
          ? localeConfirmDelete[currentLocale].entities.sensor[0]
          : localeConfirmDelete[currentLocale].entities.sensor
      }`}
      onClose={(): void => setSensorNotEmptyModal(false)}
      className="host-confirm modal small"
    >
      <div className="k-form">
        <div className="form-content">
          <p>
            {Array.isArray(localeNames[currentLocale].sensor)
              ? localeNames[currentLocale].sensor[0]
              : localeNames[currentLocale].sensor}{' '}
            <b>{sensorName.current || sensorIdContext.current}</b>{' '}
            {localeSensor[currentLocale].notDeleteSensor}
          </p>
        </div>
        <div className="k-form-buttons">
          <Button type="button" onClick={(): void => setSensorNotEmptyModal(false)}>
            {localeButtons[currentLocale].close}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
