import qs from 'qs';
import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import type { Sensor } from '../types/__generated/on-premise-solution/api/sensor.v1';
import type { SensorInstallerDownloadUrlResponse } from '../types/__generated/on-premise-solution/api/sensorInstallerDownloadUrlResponse.v1';
import type { SensorUpdateRequest } from '../types/__generated/on-premise-solution/api/sensorUpdateRequest.v1';
import type { SensorUpdateResponse } from '../types/__generated/on-premise-solution/api/sensorUpdateResponse.v1';
import type { OnpremSensorCommandRequestListLogs } from '../types/__generated/on-premise-solution/api/onpremSensorCommandRequest.v1';
import type { SensorLogsResponse } from '../types/__generated/on-premise-solution/api/sensorLogsResponse.v1';

export async function getSensorInstaller(
  sensorId?: string | null,
): Promise<SensorInstallerDownloadUrlResponse> {
  // debugger;
  const result = await vmInstance.get<SensorInstallerDownloadUrlResponse>(
    '/sensors/installer/url',
    {
      params: {
        sensorId,
      },
      // paramsSerializer: (params) => {
      //   history.replace({
      //     pathname: `/lk/assets/download/installer/url?${qs.stringify({
      //       osType,
      //       assetId,
      //     })}`,
      //   });

      //   return qs.stringify(params, { encode: false });
      // },
    },
  );

  return result.data;
}

export async function editSensor(
  sensorId: Sensor['sensorId'],
  payload: SensorUpdateRequest,
): Promise<SensorUpdateResponse> {
  const result = await vmInstance.patch<SensorUpdateResponse>(`/sensors/${sensorId}`, {
    ...payload,
  });

  return result.data;
}

export async function deleteSensor(sensorId: string): Promise<Sensor | null> {
  const result = await vmInstance.delete<Sensor>(`/sensors/${sensorId}`);

  return result.data;
}

export async function requestCentrifugoForSensorLogs(
  sensorId: string,
  payload?: OnpremSensorCommandRequestListLogs['payload'],
): Promise<OnpremSensorCommandRequestListLogs> {
  const result = await vmInstance.post<OnpremSensorCommandRequestListLogs>(
    `/sensors/${sensorId}/command`,
    {
      sensorId,
      command: 'list_logs',
      payload,
    },
  );

  return result.data;
}

export async function getSensorLogs(
  sensorId: string,
  logsChunkId: string,
): Promise<SensorLogsResponse> {
  const result = await vmInstance.get<SensorLogsResponse>(
    `/sensors/${sensorId}/logs/${logsChunkId}`,
  );

  return result.data;
}
