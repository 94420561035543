import type { GridCellProps } from '@progress/kendo-react-grid';
import type { EnrichedLogsResponse } from '../../../../../types/__generated/on-premise-solution/api/enrichedLogsResponse.v1';
// import type { LogResponse } from '../../../../../types/__generated/on-premise-solution/api/logsResponse.v1';

export function CellUserLogUserName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;
  const { user }: EnrichedLogsResponse['data'][0] = dataItem;

  return (
    <td title={user?.name || ''}>
      <span>{user?.name || '-'}</span>
    </td>
  );
}
