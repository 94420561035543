/* eslint-disable react/jsx-pascal-case */
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import locale from '../../../utils/i18n/credentials/credentials.json';
import './Credentials.module.scss';
import { getUrlCredential } from '../../../utils/helpers/credential-helper';
import type { IDataHooks } from '../../../utils/helpers/types';
import { PAGEABLE_DATA } from '../../../utils/helpers/constants';
import { usePageChange } from '../../../hooks/components/usePageChange';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import { FILTER_OPERATORS } from '../../../utils/helpers/constant-filter-operators';
import { handleSort } from '../../../utils/sorting-in-table';
import styles from './CredentialList.module.scss';
import { getUserData } from '../../../services/local-storage-service';
import { useVaultlList } from '../../../hooks/components/useVaultlList';
import { useCredentialContext } from '../../../hooks/useCredentialContext';
import { CellCommonMenu } from './cell/CellCommonMenu';
import { AddCredentialStorageForm } from './form/AddCredentialStorageForm';
import { StandartCell } from '../../common/baseElements/StandartCell';
import { CellCommonDate } from '../../common/baseElements/CellCommonDate';
import { Input } from '../../common/baseElements/Input';
import { Button } from '../../common/baseElements/Button';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function VaultslList(): React.ReactElement {
  const urlParams = getUrlCredential('VaultslList');

  const currentUser = getUserData();

  const dataForHooks: IDataHooks = {
    name: 'endpoint',
    componentName: 'VaultslList',
  };

  const { customSearch, sort, setSort, page, setPage, data, handleCustomSearch } = useVaultlList(
    urlParams,
    dataForHooks,
  );

  const { setAddCredentialStorage, isAddCredentialStorage } = useCredentialContext();

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  return (
    <div className="credential-list">
      <div className={styles.credentialActions}>
        {currentUser?.role !== 'user' && currentUser?.role !== 'reader_user' && (
          <Button
            fill="action"
            onClick={(): void => {
              setAddCredentialStorage(true);
            }}
          >
            {locale[currentLocale].table.addStorage}
          </Button>
        )}
      </div>
      <Input
        className="k-input search-input vault-search"
        name="customSearch"
        placeholder={locale[currentLocale].table.customSearchEndpoint}
        value={customSearch}
        onChange={(e): void => handleCustomSearch(e)}
      />
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
            <Grid
              pageable={Number(data?.total) && Number(data?.total) > 15 ? PAGEABLE_DATA : false}
              sortable
              sort={sort}
              // className="table"
              data={data?.data}
              skip={page.skip}
              take={page.take}
              total={Number(data?.total)}
              filterOperators={FILTER_OPERATORS}
              onPageChange={pageChange}
              onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
              scrollable="none"
              className={
                Number(data?.total) && Number(data?.total) < 15 ? 'no-pageable-k-grid' : ''
              }
            >
              <Column
                field="name"
                title={locale[currentLocale].table.name}
                filter="text"
                width="200px"
              />
              <Column
                field="type"
                title={locale[currentLocale].table.type}
                filter="text"
                width="140px"
              />
              <Column
                field="endpoint"
                title={locale[currentLocale].table.endpoint}
                filter="text"
                width="220px"
              />
              <Column
                field="createdAt"
                title={locale[currentLocale].table.storageCreatedAt}
                filter="text"
                cell={CellCommonDate}
              />
              <Column
                field="createdBy.name"
                title={locale[currentLocale].table.storageCreatedBy}
                filter="text"
                cell={StandartCell}
              />
              <Column
                field="description"
                title={locale[currentLocale].table.description}
                filter="text"
                cell={StandartCell}
                width="400px"
                className="title"
              />
              <Column cell={CellCommonMenu} width="40px" />
            </Grid>
          </ErrorBoundary>
        </IntlProvider>
      </LocalizationProvider>
      {isAddCredentialStorage && <AddCredentialStorageForm />}
    </div>
  );
}
