import type { TreeListCellProps } from '@progress/kendo-react-treelist';
import type { GridCellProps } from '@progress/kendo-react-grid';
import localeButtons from '../../../../utils/i18n/commonLocales/buttons.json';
import { Button } from '../../../common/baseElements/Button';
import { IOpenedFromComponentInfo, useSensorContext } from '../../../../hooks/useSensorContext';
import type { SensorsResponse } from '../../../../types/__generated/on-premise-solution/api/sensorsResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeButtons;

export function CellSensorMenu(props: GridCellProps | TreeListCellProps): React.ReactElement {
  const { dataItem, level } = props;

  const openedFromInfo: IOpenedFromComponentInfo = {
    rootComponentName: level ? 'SensorTreeList' : 'SensorClassicList',
  };

  const { sensorId, name, description } = dataItem as SensorsResponse['data'][0];

  const { sensorIdContext, sensorName, sensorDescription, setEditOpen, setDeleteOpen } =
    useSensorContext();

  return (
    <td className="cell_menu">
      <div className="groups__menu">
        <div className="dropdown__container">
          <span className="k-icon k-i-more-vertical" />
          <div className="dropdown__content">
            <Button
              fill="menu"
              onClick={(): void => {
                sensorIdContext.current = sensorId;
                sensorName.current = name;
                sensorDescription.current = description || undefined;
                setEditOpen(openedFromInfo);
              }}
            >
              {localeButtons[currentLocale].edit}
            </Button>
            <Button
              fill="menu"
              onClick={(): void => {
                sensorIdContext.current = sensorId;
                sensorName.current = name;
                setDeleteOpen(openedFromInfo);
              }}
            >
              {localeButtons[currentLocale].delete}
            </Button>
          </div>
        </div>
      </div>
    </td>
  );
}
