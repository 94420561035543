/* eslint-disable jsx-a11y/no-autofocus */
import { useEffect, useState, type ReactElement } from 'react';
import styles from './Input.module.scss';
import type { IInputProps } from '../../../utils/helpers/types';
import localeErrorMessage from '../../../utils/i18n/commonLocales/errorMessage.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeErrorMessage;

export function Input(props: IInputProps): ReactElement {
  const {
    id,
    name,
    style,
    disabled,
    className,
    placeholder,
    value,
    type,
    maxLength,
    onChange,
    onFocus,
    onBlur,
    min,
    max,
    required,
    autoFocus,
    validationMessage,
    label,
    newRequired,
  } = props;

  const [errorMsg, setErrorMsg] = useState(validationMessage || '');

  const validationCheck = (): void => {
    if (disabled) setErrorMsg('');
    if (required && !value) setErrorMsg(localeErrorMessage[currentLocale].form.fillOut);
    else if (validationMessage) setErrorMsg(validationMessage);
    else setErrorMsg('');
  };

  useEffect(() => {
    if (disabled) setErrorMsg('');
    else if (required && !value) setErrorMsg(localeErrorMessage[currentLocale].form.fillOut);
    else if (validationMessage) setErrorMsg(validationMessage);
    else setErrorMsg('');
  }, [required, validationMessage, value, disabled]);

  return (
    <span className={styles.inputBlock}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}
      <input
        style={style}
        name={name}
        type={type}
        disabled={disabled}
        value={value}
        id={id}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        maxLength={maxLength}
        className={`${styles.input}${errorMsg ? ` ${styles.error}` : ''} ${className || ''}`}
        placeholder={placeholder}
        min={min}
        max={max}
        required={required || newRequired}
        autoFocus={autoFocus}
        onSelect={validationCheck}
      />
      {errorMsg && <span className={styles.errorMsg}>{errorMsg}</span>}
    </span>
  );
}
