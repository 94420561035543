import qs from 'qs';
import type { SortDescriptor } from '@progress/kendo-data-query';
import type { AxiosResponse } from 'axios';
import { dashboardInstance } from '../lib/axios/hooks/useDashboardAxios';
import type { IFilterVal } from '../utils/filtering-in-table-test';
import type { IPageState } from '../utils/helpers/types';
import { prepareFilterVal } from './filterVal-for-service';
import { history } from '../utils/history';
import type { SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse } from '../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdatesWithAvailableDashboardResponse.v1';
import { INITIAL_PAGING, INITIAL_SORT_SM } from '../utils/helpers/constants';
import type { IUrlParamsList } from '../utils/helpers/getUrl-list-helper';
import type { SoftwareUpdatesStatsResponse } from '../types/__generated/on-premise-solution/api/softwareUpdatesStatsResponse.v1';
import type { SavedWindowsSoftwareUpdateWithAvailableDashboardResponse } from '../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdateWithAvailableDashboardResponse.v1';
import { vmInstance } from '../lib/axios/hooks/useVmAxios';
import type { SavedWindowsSoftwareUpdateCreationResponse } from '../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdateCreationResponse.v1';
import type { SavedWindowsSoftwareUpdateCreationRequest } from '../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdateCreationRequest.v1';
import type { SavedWindowsSoftwareUpdateUpdateResponse } from '../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdateUpdateResponse.v1';
import type { SavedWindowsSoftwareUpdateDeletionResponse } from '../types/__generated/on-premise-solution/api/savedWindowsSoftwareUpdateDeletionResponse.v1';

export function getUrlParamsSoftList(
  pageLimitGridKey: string,
  sortValue?: SortDescriptor[],
): IUrlParamsList {
  const pageLimitGrid: IUrlParamsList['pageLimitGrid'] =
    localStorage.getItem('pageLimitGrid') &&
    JSON.parse(localStorage.getItem('pageLimitGrid') || '');

  const locationUrlData: { [name: string]: string } | null = {};
  if (document.location.search) {
    const locationSearch = document.location.search.slice(1).split('&');
    locationSearch.forEach((val) => {
      const value = val.split('=');
      locationUrlData[value[0]] = value[1] || '';
    });
  }

  const { sortField, sortOrder, skip } = locationUrlData;
  const sort: SortDescriptor[] =
    sortField && sortOrder && (sortOrder === 'asc' || sortOrder === 'desc') && !sortValue
      ? [{ field: sortField, dir: sortOrder }]
      : INITIAL_SORT_SM;

  const page: IPageState = {
    skip: INITIAL_PAGING.skip,
    take:
      pageLimitGrid && Number(pageLimitGrid[pageLimitGridKey])
        ? Number(pageLimitGrid[pageLimitGridKey])
        : INITIAL_PAGING.take,
  };

  const filterUrlData = {};

  return {
    sort,
    page,
    pageLimitGrid,
    filterUrlData,
  };
}

export async function getSoftManagementStats(): Promise<SoftwareUpdatesStatsResponse> {
  const result = await dashboardInstance.get<SoftwareUpdatesStatsResponse>(
    '/software-updates/stats',
  );

  return result.data;
}

export async function getSoftwareList(
  page: IPageState,
  filterVal: IFilterVal[],
  sort: Array<SortDescriptor>,
  softwareCodename?: string,
  version?: string,
  accountId?: string | null,
): Promise<SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse> {
  let result: AxiosResponse<SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse, any>;
  const paramsObj = {
    skip: page.skip,
    limit: page.take,
    sortField: sort[0].field.length < 1 ? undefined : sort[0].field,
    sortOrder: sort[0].dir,
    ...prepareFilterVal(filterVal),
  };
  if (accountId) paramsObj.accountId = accountId;

  if (
    !document.location.pathname.includes('assets/hosts/') &&
    !document.location.pathname.includes('task/actions/') &&
    !document.location.pathname.includes('lk/accounts/')
  ) {
    result = await dashboardInstance.get<SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse>(
      '/software-updates/windows',
      {
        params: paramsObj,

        paramsSerializer: (params) => {
          const stringifiedParams = qs.stringify(params, { encode: false });
          history.replace({
            pathname: `/lk/software-management?${stringifiedParams}`,
          });

          return stringifiedParams;
        },
      },
    );
  } else if (softwareCodename && version) {
    result = await dashboardInstance.get<SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse>(
      '/software-updates/windows',
      {
        params: paramsObj,

        paramsSerializer: (params) => {
          const stringifiedParams = qs.stringify(params, { encode: false });

          return `${stringifiedParams}&softwareCodename=${softwareCodename}&version[$gt]=${version}`;
        },
      },
    );
  } else {
    result = await dashboardInstance.get<SavedWindowsSoftwareUpdatesWithAvailableDashboardResponse>(
      '/software-updates/windows',
      {
        params: paramsObj,

        paramsSerializer: (params) => {
          const stringifiedParams = qs.stringify(params, { encode: false });

          return stringifiedParams;
        },
      },
    );
  }

  return result.data;
}

export async function getSoftwareInfo(
  fullid: string,
  accountId?: string | null,
): Promise<SavedWindowsSoftwareUpdateWithAvailableDashboardResponse> {
  const result =
    await dashboardInstance.get<SavedWindowsSoftwareUpdateWithAvailableDashboardResponse>(
      `/software-updates/windows/${fullid}${accountId ? `?accountId=${accountId}` : ''}`,
    );

  return result.data;
}

export async function donwloadSoftwares(
  softwareData: SavedWindowsSoftwareUpdateCreationRequest,
): Promise<SavedWindowsSoftwareUpdateCreationResponse> {
  const result = await vmInstance.post<SavedWindowsSoftwareUpdateCreationResponse>(
    '/software-updates/windows/saved',
    softwareData,
  );

  return result.data;
}

export async function sendApproveStatus(
  softwareUpdateid: string,
  approveStatus: boolean,
): Promise<SavedWindowsSoftwareUpdateUpdateResponse> {
  const result = await vmInstance.patch<SavedWindowsSoftwareUpdateUpdateResponse>(
    `/software-updates/windows/saved/${softwareUpdateid}`,
    { isApproved: approveStatus },
  );

  return result.data;
}

export async function removeSoftware(
  softwareUpdateid: string,
): Promise<SavedWindowsSoftwareUpdateDeletionResponse> {
  const result = await vmInstance.delete<SavedWindowsSoftwareUpdateDeletionResponse>(
    `/software-updates/windows/saved/${softwareUpdateid}`,
  );

  return result.data;
}
