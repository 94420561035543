import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useEffect } from 'react';
import localeDashboardForm from '../../../../../../utils/i18n/dashboardLocale/addDashboardForm.json';
import styles from '../../../../../common/AssetDescription.module.scss';
import '../../../../Host/hostComponents/HostDescription.module.scss';
import localeHostDescription from '../../../../../../utils/i18n/assetLocales/hostDescription.json';
import type { AssetImageDashboardResponse } from '../../../../../../types/__generated/on-premise-solution/api/assetImageDashboardResponse.v1';
import { useHostActions } from '../../../../../../hooks/components/useHostActions';
import { useAssetCreatePropContext } from '../../../../../../hooks/useAssetCreatePropContext';
import { Button } from '../../../../../common/baseElements/Button';
import type { IAssetImportanceValue } from '../../../../../../utils/helpers/types';
import { SensorDropdownList } from '../../../../Sensor/SensorDropdownList';

export function TableSImageMetaBlock({
  data,
  currentLocale,
}: {
  data: AssetImageDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const {
    hostImportance,
    isShowImportance,
    setShowImportance,
    errCreateHostMutation,
    isSavedImportance,
    setShowPerimeterImpact,
    setSavedImportance,
    setSavedPerimeterImpact,
    prepareAssetData,
  } = useAssetCreatePropContext();

  const { changeImportance } = useHostActions();

  useEffect(() => {
    return () => {
      setSavedImportance(false);
      setSavedPerimeterImpact(false);
      setShowImportance(false);
      setShowPerimeterImpact(false);
    };
  }, []);

  return (
    <table className="column column-first">
      <tbody>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.imageId}</span>
          </td>
          <td>{data?.type === 'image' && <span> {data?.imageId || '-'}</span>}</td>
        </tr>
        <tr>
          <td>
            <span>{localeHostDescription[currentLocale].summary.type}</span>
          </td>
          <td>
            {data?.type === 'image' && (
              <span style={{ textTransform: 'capitalize' }}>{data.class}</span>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeDashboardForm[currentLocale].hostImportanceText}</span>
          </td>
          <td className={styles.tdAssetMutableVals}>
            {!isShowImportance && (
              <Button
                fill="modal"
                onClick={(): void => prepareAssetData(setShowImportance, isShowImportance, data)}
              >
                <span>
                  {data?.importance
                    ? (localeDashboardForm[currentLocale].hostImportanceVals[
                        data.importance
                      ] as keyof IAssetImportanceValue)
                    : '-'}
                </span>
              </Button>
            )}
            {isShowImportance && (
              <DropDownList
                data={hostImportance}
                name="hostImportance"
                id="val"
                textField="text"
                onChange={changeImportance}
                defaultValue={{ val: '', text: localeDashboardForm[currentLocale].selectValue }}
                opened={isShowImportance}
                style={{ width: '180px' }}
              />
            )}
            {!errCreateHostMutation && isSavedImportance && (
              <div className={styles.assetMutableValsSaved}>
                <span>{localeDashboardForm[currentLocale].savedText}</span>
              </div>
            )}
          </td>
        </tr>
        {/* <tr>
          <td>Подключаться через</td>
          <td>
            <SensorDropdownList />
          </td>
        </tr> */}
      </tbody>
    </table>
  );
}
