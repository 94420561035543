import { useState, type ReactElement } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import type { SensorDashboardResponse } from '../../../types/__generated/on-premise-solution/api/sensorDashboardResponse.v1';
import localeNames from '../../../utils/i18n/commonLocales/names.json';
import { SensorHostListTab } from './SensorHostListTab';
import { SensorDeviceListTab } from './SensorDeviceListTab';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeNames;

export function AssetListTab({
  assetIds,
}: {
  assetIds?: SensorDashboardResponse['assets'];
}): ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = (e: TabStripSelectEventArguments): void => {
    setSelected(e.selected);
  };

  return (
    <div>
      <TabStrip
        selected={selected}
        onSelect={handleSelect}
        style={{
          marginTop: '12px',
        }}
      >
        <TabStripTab
          title={`${localeNames[currentLocale].hosts} (${
            assetIds?.filter((s) => s.type === 'host').length || 0
          })`}
        >
          <SensorHostListTab
            hostsIds={assetIds?.filter((s) => s.type === 'host').map((s) => s.assetId)}
          />
        </TabStripTab>
        <TabStripTab
          title={`${localeNames[currentLocale].devices} (${
            assetIds?.filter(
              (s) =>
                s.type === 'firewall' ||
                s.type === 'hypervisor' ||
                s.type === 'router' ||
                s.type === 'switch',
            ).length || 0
          })`}
        >
          <SensorDeviceListTab
            deviceIds={assetIds
              ?.filter(
                (s) =>
                  s.type === 'firewall' ||
                  s.type === 'hypervisor' ||
                  s.type === 'router' ||
                  s.type === 'switch',
              )
              .map((s) => s.assetId)}
          />
        </TabStripTab>
      </TabStrip>
    </div>
  );
}
