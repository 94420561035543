/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { useState } from 'react';
import localeTaskDescription from '../../../../utils/i18n/taskLocale/taskDescription.json';
import { createScopeData, setAssetGroupData } from '../../../../utils/helpers/task-helpers';
import type {
  TaskAssetSynchronizeExecutionDashboardResponse,
  TaskExecutionDashboardResponse,
  TaskImageExecutionDashboardResponse,
  TaskReportExecutionDashboardResponse,
} from '../../../../types/__generated/on-premise-solution/api/taskExecutionsDashboardResponse.v1';
import { ScopeElement } from './ScopeElement';
import { ScopeNotification } from './ScopeNotification';
import styles from '../TaskList.module.scss';

const currentLocale =
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru'
    ? 'ru-RU'
    : ('en-EN' as keyof typeof localeTaskDescription);

export function HistoryScope(props: GridCellProps): React.ReactElement {
  const [isShow, setShow] = useState(false);

  const { dataItem, field } = props;

  const {
    scope,
    taskType,
  }:
    | TaskExecutionDashboardResponse
    | TaskReportExecutionDashboardResponse
    | TaskImageExecutionDashboardResponse
    | TaskAssetSynchronizeExecutionDashboardResponse = dataItem;

  const scoreData = setAssetGroupData(scope);

  const data = createScopeData(
    scoreData,
    taskType,
    field === 'sensorScope' ? dataItem.assets : undefined,
  );

  return (
    <td>
      <div className={styles.assetscope}>
        {/* <HistoryScopeNotification scoreData={scoreData} taskType={taskType} isShow={isShow} /> */}
        {data && data.length > 3 ? (
          <>
            <div className={styles.assets}>
              {data?.slice(0, 3).map((se) => (
                <ScopeElement element={se} />
              ))}
            </div>
            <span
              onMouseEnter={(): void => setShow(true)}
              onMouseLeave={(): void => setShow(false)}
            >
              <ScopeNotification
                className="scope-notification"
                data={data}
                isShow={isShow}
                sliceValue={3}
              />
              {localeTaskDescription[currentLocale].summary.andMore}
              {data.length - 3}
            </span>
          </>
        ) : !data || data.length === 0 ? (
          <div>
            <span>-</span>
          </div>
        ) : (
          data?.map((se) => <ScopeElement element={se} />)
        )}
      </div>
    </td>
  );
}
