/* eslint-disable react/jsx-no-useless-fragment */
import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';

export function CellAccountName(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  return (
    <td title={dataItem.name || '-'}>
      {dataItem.accountId ? (
        <NavLink to={`/lk/accounts/${dataItem.accountId}`}>{dataItem.name || '-'}</NavLink>
      ) : (
        dataItem.name || '-'
      )}
    </td>
  );
}
