import {
  Grid,
  GridColumn as Column,
  GridSortChangeEvent,
  GridRowProps,
} from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import React from 'react';
import { PAGEABLE_DATA } from '../../utils/helpers/constants';
import type { ICommonServerList } from '../../utils/helpers/types';
import { handleSort } from '../../utils/sorting-in-table';
import localeUser from '../../utils/i18n/userLocales/user.json';
import { StandartCell } from './baseElements/StandartCell';
import { CellSrvListMenu } from '../features/User/Cells/CellSrvListMenu';
import { CellCommonDate } from './baseElements/CellCommonDate';
import { CellServerName } from '../features/User/Cells/CellServerName';
import { CellSelectServers } from '../features/User/Cells/CellSelectServers';
import { TypeCell } from './cells/TypeCell';
import { CellSelectServer } from '../features/User/Cells/CellSelectServer';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function CommonServerList(props: ICommonServerList): React.ReactElement {
  const { sort, data, page, total, pageChange, setSort, dataForHooks, parentComponent } = props;

  const rowRender = (
    trElement: React.ReactElement<HTMLTableRowElement>,
    values: GridRowProps,
  ): React.ReactElement => {
    const notActive = parentComponent === 'addTask' && !values.dataItem.credential;

    const trProps: any = { className: notActive ? 'row-grid-table' : '' };

    return React.cloneElement(trElement, { ...trProps }, trElement.props.children);
  };

  return (
    <LocalizationProvider language={currentLocale}>
      <IntlProvider locale={currentLocale.substring(0, 2)}>
        <Grid
          pageable={total && total > 15 ? PAGEABLE_DATA : false}
          sortable
          sort={sort}
          data={data}
          skip={page.skip}
          take={page.take}
          total={total || 0}
          onPageChange={pageChange}
          onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
          scrollable="none"
          className={total && total < 15 ? 'no-pageable-k-grid' : ''}
          rowRender={rowRender}
        >
          {dataForHooks.componentName === 'AddServerList' && parentComponent !== 'addTask' && (
            <Column field="addServer" title=" " cell={CellSelectServers} width="40px" />
          )}
          {parentComponent && parentComponent === 'addTask' && (
            <Column field="addServer" title=" " cell={CellSelectServer} width="40px" />
          )}
          {(parentComponent && parentComponent === 'addTask') ||
          dataForHooks.componentName !== 'ServerList' ? (
            <Column
              field="name"
              title={localeUser[currentLocale].connectServers.grid.name}
              filter="text"
              width="240px"
              cell={StandartCell}
              className="title"
            />
          ) : (
            <Column
              field="name"
              title={localeUser[currentLocale].connectServers.grid.name}
              filter="text"
              width="240px"
              cell={CellServerName}
            />
          )}

          <Column
            field="url"
            title={localeUser[currentLocale].connectServers.grid.url}
            filter="text"
            width="170px"
          />
          <Column
            field="type"
            title={localeUser[currentLocale].connectServers.grid.type}
            filter="text"
            width="60px"
            cell={TypeCell}
          />
          <Column
            field="baseDN"
            title={localeUser[currentLocale].connectServers.grid.baseDn}
            width="280px"
            cell={StandartCell}
            className="title"
          />
          <Column
            field="credential.name"
            title={localeUser[currentLocale].connectServers.grid.cred}
            cell={StandartCell}
            width="132px"
          />
          {dataForHooks.componentName === 'ServerList' && (
            <Column
              field="totalUsers"
              title={localeUser[currentLocale].connectServers.grid.totalUsers}
              width="130px"
              className="number"
            />
          )}
          <Column
            field="account.name"
            title={localeUser[currentLocale].connectServers.grid.account}
            // width="136px"
          />
          <Column
            cell={CellCommonDate}
            title={localeUser[currentLocale].connectServers.grid.createdAt}
            filter="text"
            field="createdAt"
            width="180px"
          />
          {dataForHooks.componentName === 'ServerList' && (
            <Column cell={CellSrvListMenu} width="40px" />
          )}
        </Grid>
      </IntlProvider>
    </LocalizationProvider>
  );
}
