import { useCallback, useState, type ReactElement } from 'react';
import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import type { AccountDashboardResponse } from '../../../types/__generated/on-premise-solution/api/accountsDashboardResponse.v1';
import { VmTab } from './Tabs/VmTab';
import localeAccount from '../../../utils/i18n/accountLocales/accountDescription.json';
import { NetworkTab } from './Tabs/NetworkTab';
import { SystemTab } from './Tabs/SystemTab';
import { SecurityTab } from './Tabs/SecurityTab';
import type { ConfigResponse } from '../../../types/__generated/on-premise-solution/api/configResponse.v1';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;

export function AccountSettingsTab({
  settings,
  config,
}: {
  settings: AccountDashboardResponse['settings'] | undefined;
  config: ConfigResponse | undefined;
}): ReactElement {
  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  return (
    <TabStrip
      selected={selected}
      onSelect={handleSelect}
      style={{
        marginTop: '4px',
      }}
    >
      <TabStripTab title={localeAccount[currentLocale].settingsTab.vmTabTitle}>
        <VmTab data={settings?.vm} />
      </TabStripTab>
      <TabStripTab title={localeAccount[currentLocale].settingsTab.networkTabTitle}>
        <NetworkTab dns={settings?.dnsList} smtp={settings?.smtp} config={config} />
      </TabStripTab>
      <TabStripTab title={localeAccount[currentLocale].settingsTab.systemTabTitle}>
        <SystemTab data={settings?.system} config={config} />
      </TabStripTab>
      <TabStripTab title={localeAccount[currentLocale].settingsTab.securityTabTitle}>
        <SecurityTab data={settings?.userAuth} config={config} />
      </TabStripTab>
    </TabStrip>
  );
}
