/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useEffect } from 'react';
import localeDashboardForm from '../../../../../utils/i18n/dashboardLocale/addDashboardForm.json';
import styles from '../../../../common/AssetDescription.module.scss';
import { useAssetCreatePropContext } from '../../../../../hooks/useAssetCreatePropContext';
import { Button } from '../../../../common/baseElements/Button';
import { useHostActions } from '../../../../../hooks/components/useHostActions';
import type { IAssetImportanceValue } from '../../../../../utils/helpers/types';
import type {
  AssetFirewallDashboardResponse,
  AssetHypervisorDashboardResponse,
  AssetRouterDashboardResponse,
  AssetSwitchDashboardResponse,
} from '../../../../../types/__generated/on-premise-solution/api/assetsDevicesDashboardResponse.v1';
import { CommonTooltip } from '../../../../common/CommonTooltip';

export function TableTDeviceMetaBlock({
  data,
  currentLocale,
}: {
  data:
    | AssetRouterDashboardResponse
    | AssetFirewallDashboardResponse
    | AssetSwitchDashboardResponse
    | AssetHypervisorDashboardResponse
    | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const {
    hostImportance,
    isShowImportance,
    setShowImportance,
    errCreateHostMutation,
    isSavedImportance,
    perimeterImpactText,
    isShowPerimeterImpact,
    setShowPerimeterImpact,
    isSavedPerimeterImpact,
    setShowConnectedBy,
    setSavedImportance,
    setSavedPerimeterImpact,
    prepareAssetData,
  } = useAssetCreatePropContext();

  const { changeImportance, changePerimeterImpactText } = useHostActions();

  useEffect(() => {
    return () => {
      setSavedImportance(false);
      setSavedPerimeterImpact(false);
      setShowImportance(false);
      setShowPerimeterImpact(false);
      setShowConnectedBy(false);
    };
  }, []);

  return (
    <table className="column">
      <tbody>
        <tr>
          <td>
            <span>{localeDashboardForm[currentLocale].hostImportanceText}</span>
          </td>
          <td className={styles.tdAssetMutableVals}>
            {!isShowImportance && (
              <Button
                fill="modal"
                onClick={(): void => prepareAssetData(setShowImportance, isShowImportance, data)}
              >
                <span>
                  {data?.importance
                    ? (localeDashboardForm[currentLocale].hostImportanceVals[
                        data.importance
                      ] as keyof IAssetImportanceValue)
                    : '-'}
                </span>
              </Button>
            )}
            {isShowImportance && (
              <DropDownList
                data={hostImportance}
                name="hostImportance"
                id="val"
                textField="text"
                onChange={changeImportance}
                defaultValue={{ val: '', text: localeDashboardForm[currentLocale].selectValue }}
                opened={isShowImportance}
                style={{ width: '180px' }}
              />
            )}
            {!errCreateHostMutation && isSavedImportance && (
              <div className={styles.assetMutableValsSaved}>
                <span>{localeDashboardForm[currentLocale].savedText}</span>
              </div>
            )}
          </td>
        </tr>
        <tr>
          <td>
            <span>{localeDashboardForm[currentLocale].form.perimeterImpact}</span>
          </td>
          <td className={styles.tdAssetMutableVals}>
            {!isShowPerimeterImpact && (
              <Button
                fill="modal"
                onClick={(): void =>
                  prepareAssetData(setShowPerimeterImpact, isShowPerimeterImpact, data)
                }
              >
                <span>
                  {data?.perimeterImpact === 1
                    ? localeDashboardForm[currentLocale].yes
                    : localeDashboardForm[currentLocale].no}
                </span>
              </Button>
            )}
            {isShowPerimeterImpact && (
              <DropDownList
                data={perimeterImpactText}
                name="perimeterImpactText"
                id="val"
                textField="text"
                onChange={changePerimeterImpactText}
                defaultValue={{ val: '', text: localeDashboardForm[currentLocale].selectValue }}
                opened={isShowPerimeterImpact}
                style={{ width: '180px' }}
              />
            )}
            {!errCreateHostMutation && isSavedPerimeterImpact && (
              <div className={styles.assetMutableValsSaved}>
                <span>{localeDashboardForm[currentLocale].savedText}</span>
              </div>
            )}
            <div>
              <CommonTooltip>
                {localeDashboardForm[currentLocale].form.perimeterImpactInfo}
              </CommonTooltip>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}
