/* eslint-disable react/jsx-pascal-case */
import type { ReactElement } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Filter } from '@progress/kendo-react-data-tools';
import { BoundaryErrorComponent } from '../../common/BoundaryErrorComponent';
import localeFilters from '../../../utils/i18n/commonLocales/filters.json';
import { CommonSensorList } from '../../common/CommonSensorList';
import { useSensorList } from '../../../hooks/components/useSensorList';
import { INITIAL_SORT } from '../../../utils/helpers/constants';
import { getUrlList } from '../../../utils/helpers/getUrl-list-helper';
import { Input } from '../../common/baseElements/Input';
import type { IDataHooks } from '../../../utils/helpers/types';
import { FILTER_SENSORLIST } from '../../../utils/helpers/constant-serv-filter-host';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeFilters;

export function SensorClassicList(): ReactElement {
  const urlParams = getUrlList('SensorList', INITIAL_SORT);

  const dataForHooks: IDataHooks = {
    name: 'name',
    componentName: 'SensorList',
  };

  const {
    query,
    total,
    sort,
    page,
    filter,
    customSearch,
    setSort,
    pageChange,
    onFilterChange,
    handleCustomSearch,
  } = useSensorList(urlParams, dataForHooks);

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className="sensor-classic-list">
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_SENSORLIST[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={`${localeFilters[currentLocale].customSerachPlaceholder} ${localeFilters[currentLocale].entityTitle}`}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
        <CommonSensorList
          sort={sort}
          data={query.data}
          page={page}
          total={total}
          pageChange={pageChange}
          setSort={setSort}
          dataForHooks={dataForHooks}
        />
      </div>
    </ErrorBoundary>
  );
}
