import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { useCallback, useState } from 'react';
import { TabStripSelectEventArguments, TabStripTab, TabStrip } from '@progress/kendo-react-layout';
import localeUser from '../../../utils/i18n/userLocales/user.json';
import { ServerMetaBlock } from './ServerMetaBlock';
import type { LdapDashboardResponse } from '../../../types/__generated/on-premise-solution/api/ldapsDashboardResponse.v1';
import type { ApiError } from '../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getServer } from '../../../services/user-service';
import { UserListForServer } from './UserListForServer';
import { AddCredentialForm } from '../Credentials/form/AddCredentialForm';
import { useCredentialContext } from '../../../hooks/useCredentialContext';
import { Button } from '../../common/baseElements/Button';
import type { TDataEditSrc } from '../../../utils/helpers/types';
import { useServerContext } from '../../../hooks/useServerContext';
import { AddServerForm } from './modals/AddServerForm';
import { DeleteConfirmModal } from '../../common/modal/DeleteConfirmModal';
import { EntityDescription } from '../../templates/EntityDescription';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeUser;

export function ServerDescription(): React.ReactElement {
  const { ldapId } = useParams<{ ldapId: string }>();

  const [isConfirm, setConfirm] = useState(false);

  const query = useQuery<LdapDashboardResponse, ApiError>(['ldapId', ldapId], () =>
    getServer(ldapId),
  );

  const { data } = query;

  const dataEditSrc = {
    accountId: data?.accountId,
    baseDN: data?.baseDN,
    url: data?.url,
    ldapId: data?.ldapId,
    name: data?.name,
    servername: data?.servername,
    description: data?.description,
    type: data?.type,
    account: { name: data?.account.name, accountId: data?.account.accountId },
    credential: { credentialId: data?.credential?.credentialId, name: data?.credential?.name },
    credentialName: data?.credential?.name,
    credentialType: data?.credential?.type,
  } as TDataEditSrc;

  const [selected, setSelected] = useState<number>(0);

  const handleSelect = useCallback(
    (e: TabStripSelectEventArguments): void => {
      setSelected(e.selected);
    },
    [setSelected],
  );

  const { handleEditServer, editServer, handleDelete, mutationLdapDel } = useServerContext();

  const { credentialData, isAddCredential, isEdit } = useCredentialContext();

  return (
    <EntityDescription
      isLoading={query.isLoading}
      isNotFound={query.error?.code === '404'}
      entity={{
        id: ldapId,
        type: 'server',
        name: query.data?.name,
      }}
      topactions={
        <div className="topactions">
          <Button
            // className="action"
            onClick={(): void => handleEditServer(dataEditSrc)}
          >
            {localeUser[currentLocale].serverDescription.edit}
          </Button>
          <Button onClick={(): void => setConfirm(true)} fill="delete">
            {localeUser[currentLocale].serverDescription.delete}
          </Button>
        </div>
      }
      className="serverdescription"
    >
      {isConfirm && (
        <DeleteConfirmModal
          type="auth-server"
          name={data?.name || ''}
          onClose={(): void => setConfirm(false)}
          handleDelete={(): void => handleDelete(data?.ldapId || '', setConfirm)}
          errorMessage={mutationLdapDel.error?.message}
        />
      )}
      <ServerMetaBlock data={data} />
      <TabStrip selected={selected} onSelect={handleSelect}>
        <TabStripTab title={localeUser[currentLocale].serverDescription.users}>
          <UserListForServer />
        </TabStripTab>
      </TabStrip>
      {(isAddCredential || (credentialData && isEdit)) && <AddCredentialForm />}
      {editServer && <AddServerForm />}
    </EntityDescription>
  );
}
