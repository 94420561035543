/* eslint-disable react/jsx-props-no-spreading */
// import '../../Task/TaskDescription.module.scss';
import type { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { useQuery } from 'react-query';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import {
  DATA_CACHE_STALETIME,
  FILTER_INITIAL,
  INITIAL_SORT_ALL_USER_LOGLIST,
  PAGEABLE_DATA,
} from '../../../../utils/helpers/constants';
import type { IDataHooks, IPageState } from '../../../../utils/helpers/types';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { useNotificationContext } from '../../../../hooks/useNotificationContext';
import { handleError } from '../../../../utils/errors';
import { handleFilteringTest, IFilterVal } from '../../../../utils/filtering-in-table-test';
import type { LogsResponse } from '../../../../types/__generated/on-premise-solution/api/logsResponse.v1';
import { usePageChange } from '../../../../hooks/components/usePageChange';
import { useFilter } from '../../../../hooks/components/useFilter';
import { useCustomSearch } from '../../../../hooks/components/useCustomSearch';
import { getAllUserLogs } from '../../../../services/logs-service';
import locale from '../../../../utils/i18n/userLocales/userLog.json';
import { FILTER_ALL_USER_LOGS } from '../../../../utils/helpers/constant-serv-filter-task';
import { handleSort } from '../../../../utils/sorting-in-table';
import { CellUserLogCreated } from './cells/CellUserLogCreated';
import { CellUserLogUserName } from './cells/CellUserLogUserName';
import { getUrlList } from '../../../../utils/helpers/getUrl-list-helper';
import { Input } from '../../../common/baseElements/Input';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

export function AllUserLogList({ accountId }: { accountId?: string }): React.ReactElement {
  const urlParams = getUrlList('AllUserLogList', INITIAL_SORT_ALL_USER_LOGLIST);

  const [customSearch, setCustomSearch] = useState('');

  const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filter, setFilter] = useState<CompositeFilterDescriptor>(FILTER_INITIAL);

  const [filterStatus, setFilterStatus] = useState(false);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const [staleTimeVal, setStaleTimeVal] = useState(DATA_CACHE_STALETIME);

  const filterValue = useRef<CompositeFilterDescriptor | null>(null);

  const filterRef = useRef<CompositeFilterDescriptor>(filter);

  const { taskExecutionId } = useParams<{ taskExecutionId: string }>();

  const dataForHooks: IDataHooks = {
    name: 'user.name',
    componentName: 'AllUserLogList',
    urlPath: '/lk/systemlog/users',
  };

  const { createNotification } = useNotificationContext();

  const filterSearch = useFilter(
    filterValue,
    setFilter,
    customSearch,
    setCustomSearch,
    setFilterVal,
    filterRef,
    filter,
    setPage,
    page,
    dataForHooks,
  );

  const debouncedCustomSearch = useCustomSearch(
    setFilterStatus,
    setCustomSearch,
    filter,
    setFilterVal,
    setPage,
    page,
    dataForHooks,
  );

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const query = useQuery<LogsResponse, ApiError>(
    ['AllUserLogList', page, sort, filterVal, accountId],
    () => getAllUserLogs(page, sort, filterVal, accountId),
    {
      keepPreviousData: true,
    },
  );

  useEffect(() => {
    let delaySearch: NodeJS.Timeout;
    if (!filter || filter.filters.length === 0) {
      setStaleTimeVal(DATA_CACHE_STALETIME);
      // setPage({ skip: 0, take: 15 });
      handleFilteringTest(filter, setFilterVal);
      filterRef.current = filter;
      setFilterStatus(false);
    }

    if (filter?.filters?.length > 0) {
      filterRef.current = filter;
      delaySearch = setTimeout(() => {
        handleFilteringTest(filterRef.current, setFilterVal);
        setFilterStatus(false);
      }, 300);
    }

    return () => clearTimeout(delaySearch);
  }, [filter, page.skip, taskExecutionId]);

  useEffect(() => {
    if (query.error) {
      handleError(query.error, createNotification);
    }
  }, [query.isError, query.error, createNotification]);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  const onFilterChange = (event: FilterChangeEvent): void => {
    filterSearch(event);
  };

  const handleCustomSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    debouncedCustomSearch(e);
  };

  return (
    <>
      {!accountId && (
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <div className="filter-line">
              <Filter
                value={filter}
                onChange={onFilterChange}
                fields={FILTER_ALL_USER_LOGS[currentLocale]}
              />
              {filter.filters.length === 0 && (
                <Input
                  name="customSearch"
                  placeholder={locale[currentLocale].createdBySearch}
                  value={customSearch}
                  onChange={(e): void => handleCustomSearch(e)}
                />
              )}
            </div>
          </IntlProvider>
        </LocalizationProvider>
      )}
      <LocalizationProvider language={currentLocale}>
        <IntlProvider locale={currentLocale.substring(0, 2)}>
          <Grid
            pageable={PAGEABLE_DATA}
            skip={page.skip}
            take={page.take}
            sortable
            sort={sort}
            data={query.data?.data}
            total={query.data?.total}
            onPageChange={pageChange}
            onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
            scrollable="none"
            className="all-users-log"
          >
            <Column
              field="createdAt"
              title={locale[currentLocale].form.createdAt}
              filter="text"
              width="220px"
              cell={CellUserLogCreated}
            />
            <Column
              field="user.name"
              title={locale[currentLocale].form.createdBy}
              filter="text"
              width="280px"
              cell={CellUserLogUserName}
            />
            <Column field="message" title={locale[currentLocale].form.message} filter="text" />
          </Grid>
          <СommonTableLoader queryData={query.isLoading} filteringData={filterStatus} />
        </IntlProvider>
      </LocalizationProvider>
    </>
  );
}
