/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import { nanoid } from 'nanoid';
import localeTaskDescription from '../../../../../utils/i18n/taskLocale/taskDescription.json';
import { createScopeData, type IAssetGroupData } from '../../../../../utils/helpers/task-helpers';
import styles from '../../TaskDescription.module.scss';
import { ScopeElement } from '../../cellsTaskList/ScopeElement';
import { ScopeNotification } from '../../cellsTaskList/ScopeNotification';

export function ColumnThird({
  currentLocale,
  scoreData,
  isShow,
  setShow,
  taskType,
}: {
  currentLocale: 'ru-RU' | 'en-EN';
  scoreData: IAssetGroupData;
  isShow: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  taskType: 'asset' | 'image' | 'host' | 'report' | 'device';
}): React.ReactElement {
  const data = createScopeData(scoreData, taskType);

  return (
    <table className="column">
      <tbody>
        <tr>
          <td>{localeTaskDescription[currentLocale].summary.scope}</td>
          <td>
            {data?.slice(0, 8).map((se) => (
              <ScopeElement element={se} key={nanoid()} />
            ))}
            {data && data.length > 8 && (
              <>
                <span
                  onMouseEnter={(): void => setShow(true)}
                  onMouseLeave={(): void => setShow(false)}
                >
                  {localeTaskDescription[currentLocale].summary.andMore} {data.length - 8}
                  <ScopeNotification
                    className={styles.asset_scope}
                    isShow={isShow}
                    data={data}
                    sliceValue={8}
                  />
                </span>
              </>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  );
}
