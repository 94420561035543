// import type { TreeListCellProps } from '@progress/kendo-react-treelist';
import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import localeSensor from '../../../../utils/i18n/sensorLocales/sensor.json';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSensor;

export function CellSensorParent(
  props: GridCellProps,
  // | TreeListCellProps
): React.ReactElement {
  const { dataItem } = props;

  return (
    <td title={dataItem?.parent?.name || localeSensor[currentLocale].mainServer}>
      {dataItem.parent && dataItem.parentId ? (
        <NavLink to={`/lk/sensors/${dataItem.parentId}`}>{dataItem.parent.name}</NavLink>
      ) : (
        localeSensor[currentLocale].mainServer
      )}
    </td>
  );
}
