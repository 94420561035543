import { NavLink } from 'react-router-dom';
import type { SensorDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/sensorDashboardResponse.v1';
import { getStatusAgentName } from '../../../../utils/helpers/host-list-cell-helper';
import { setColorValue } from '../../../../utils/helpers/host-vulns-metaBlock-helpers';
import localeSensor from '../../../../utils/i18n/sensorLocales/sensor.json';

export function TableSSensorMetaBlock({
  data,
  currentLocale,
}: {
  data: SensorDashboardResponse | undefined;
  currentLocale: 'ru-RU' | 'en-EN';
}): React.ReactElement {
  const colorValue = setColorValue(data?.status);

  return (
    <table className="column column-second">
      <tbody>
        <tr>
          <td>{localeSensor[currentLocale].descriptionPage.metablock.connectedTo}</td>
          {/* <td>{data?.parentId || 'Основной сервер'}</td> */}
          <td>
            {/* <SensorDropdownListTd /> */}
            {data?.parent?.sensorId ? (
              <NavLink to={`/lk/sensors/${data?.parent?.sensorId}`}>
                {data?.parent.name || '-'}
              </NavLink>
            ) : (
              localeSensor[currentLocale].mainServer
            )}
          </td>
        </tr>
        <tr>
          <td>{localeSensor[currentLocale].descriptionPage.metablock.status}</td>
          <td>
            <div className="agent-status">
              <span style={{ backgroundColor: colorValue ? colorValue[0] : '#b5b0b0' }} />
              {getStatusAgentName(data?.status, currentLocale)}
            </div>
          </td>
        </tr>
        <tr>
          <td>{localeSensor[currentLocale].descriptionPage.metablock.version}</td>
          <td>{data?.softwareVersion || '-'}</td>
        </tr>
      </tbody>
    </table>
  );
}
