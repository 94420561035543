import { useEffect, useRef, useState, type ReactElement } from 'react';
import { RadioButton, RadioButtonChangeEvent } from '@progress/kendo-react-inputs';
import { useParams } from 'react-router';
import { MaintenanceWindowModal } from '../../Host/MaintenanceWindows/MaintenanceWindowModal';
import { useMaintenanceWindowsContext } from '../../../../hooks/useMaintenanceWindowsContext';
import { Button } from '../../../common/baseElements/Button';
import localeMaintenance from '../../../../utils/i18n/maintenanceWindows/maintenanceWindowsList.json';
import localeButtons from '../../../../utils/i18n/commonLocales/buttons.json';
import localeAccount from '../../../../utils/i18n/accountLocales/accountDescription.json';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import { CommonMaintenanceList } from '../../../common/CommonMaintenanceList';
import type { AccountDashboardResponse } from '../../../../types/__generated/on-premise-solution/api/accountsDashboardResponse.v1';
import { useAccountModalContext } from '../../../../hooks/useAccountModalContext';
import settingsStyles from '../../Settings/Settings.module.scss';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeAccount;

export function VmTab({
  data,
}: {
  data: AccountDashboardResponse['settings']['vm'] | undefined;
}): ReactElement {
  const { accountId } = useParams<{ accountId: string }>();
  const { isWindowOpen, windowsListData, editWindow, setWindowEdit, disableAllPeriods } =
    useMaintenanceWindowsContext();
  const { mutationEditAccount } = useAccountModalContext();

  const [isExternalVdb, setExternalVdb] = useState(
    data?.useInherited === false ? !!data.value?.enableExternalVDBUsage : undefined,
  );

  const timer = useRef<NodeJS.Timeout | null>(null);
  const [isShowSavedMessage, setShowSavedMessage] = useState(false);

  const showSavedMessage = (): void => {
    setShowSavedMessage(true);
    if (timer.current) clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      setShowSavedMessage(false);
    }, 3000);
  };

  const handleSave = (): void => {
    mutationEditAccount
      .mutateAsync({
        accountId,
        payload: {
          'settings.vm': {
            useInherited: isExternalVdb === undefined,
            value: { enableExternalVDBUsage: !!isExternalVdb },
          },
        },
      })
      .then(() => showSavedMessage());
  };

  const handleChange = (e: RadioButtonChangeEvent): void => {
    setExternalVdb(e.value);
  };
  useEffect(() => {
    setExternalVdb(data?.useInherited === false ? !!data.value?.enableExternalVDBUsage : undefined);
  }, [data]);

  return (
    <div>
      {isWindowOpen && <MaintenanceWindowModal />}
      <div style={{ marginBottom: '48px' }}>
        <h2 style={{ marginTop: '0' }}>
          {localeAccount[currentLocale].settingsTab.externalVdbTitle}
        </h2>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            marginBottom: '20px',
          }}
        >
          <span>
            <RadioButton
              value={undefined}
              checked={isExternalVdb === undefined}
              label={localeAccount[currentLocale].settingsTab.useGlobalParameters}
              onChange={handleChange}
            />
          </span>
          <span>
            <RadioButton
              value={false}
              checked={isExternalVdb === false}
              label={localeAccount[currentLocale].settingsTab.notUseExternalVdbLabel}
              onChange={handleChange}
            />
          </span>
          <span>
            <RadioButton
              value
              checked={!!isExternalVdb}
              label={localeAccount[currentLocale].settingsTab.useExternalVdbLabel}
              onChange={handleChange}
            />
          </span>
        </div>
        <div className={settingsStyles.save_button_block}>
          <Button
            fill="action"
            onClick={handleSave}
            disabled={
              (data?.useInherited === false ? !!data.value?.enableExternalVDBUsage : undefined) ===
              isExternalVdb
            }
          >
            {localeButtons[currentLocale].save}
          </Button>
          {isShowSavedMessage && (
            <span className={settingsStyles.saved_message}>
              <i>{localeSettings[currentLocale].formSmtp.savedMessage}</i>
            </span>
          )}
        </div>
      </div>
      <div className="maintenance-block">
        <h2>{localeMaintenance[currentLocale].maintenanceWindows}</h2>
        <div
          className="button-block"
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '16px',
            margin: '16px 0',
          }}
        >
          <Button
            fill="action"
            onClick={(): void => {
              editWindow();
              setWindowEdit(false);
            }}
          >
            {localeMaintenance[currentLocale].addWindowBtn}
          </Button>
          <Button onClick={disableAllPeriods}>
            {localeMaintenance[currentLocale].disableAllBtn}
          </Button>
        </div>
        <CommonMaintenanceList data={windowsListData} />
      </div>
    </div>
  );
}
