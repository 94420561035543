/* eslint-disable react/jsx-pascal-case */
// import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';
import { useState } from 'react';
import type { SortDescriptor } from '@progress/kendo-data-query';
import { Button } from '../../../common/baseElements/Button';
import localeSettings from '../../../../utils/i18n/settingsLocales/settings.json';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { INITIAL_CREATED_AT_SORT, PAGEABLE_DATA } from '../../../../utils/helpers/constants';
import { AddApiKeyForm } from './form/AddApiKeyForm';
import { getUrlList } from '../../../../utils/helpers/getUrl-list-helper';
import type { ApiKeysResponse } from '../../../../types/__generated/on-premise-solution/api/apiKeysResponse.v1';
import type { IDataHooks, IPageState } from '../../../../utils/helpers/types';
import type { IFilterVal } from '../../../../utils/filtering-in-table-test';
import { getApiKeyList } from '../../../../services/settings-service';
import { FILTER_OPERATORS } from '../../../../utils/helpers/constant-filter-operators';
import { usePageChange } from '../../../../hooks/components/usePageChange';
import { handleSort } from '../../../../utils/sorting-in-table';
import { СommonTableLoader } from '../../../common/tableLoader/CommonTableLoader';
import styles from './ApiKeyList.module.scss';
import { CellRestrictionApiKeyList } from './cells/CellRestrictionApiKeyList';
import { useApiKeyContext } from '../../../../hooks/useApiKeyContext';
import { CellGroupDescription } from '../../../common/cellsCommonGroupList/CellGroupDescription';
import { CellMenuApiKeyList } from './cells/CellMenuApiKeyList';
import { CellLinkName } from './cells/CellLinkName';
import { ApiKeyValue } from './modal/ApiKeyValue';
import { CellCommonDate } from '../../../common/baseElements/CellCommonDate';
import { getUserData } from '../../../../services/local-storage-service';
import { DeleteConfirmModal } from '../../../common/modal/DeleteConfirmModal';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSettings;

const dataForHooks: IDataHooks = {
  name: '',
  componentName: 'TabApiKeyList',
  urlPath: '/lk/settings',
};
export function TabApiKeyList(): React.ReactElement {
  const currentUser = getUserData();

  const urlParams = getUrlList('TabApiKeyList', INITIAL_CREATED_AT_SORT);

  const [sort, setSort] = useState<SortDescriptor[]>(urlParams.sort);

  const [page, setPage] = useState<IPageState>(urlParams.page);

  const [filterVal, setFilterVal] = useState<IFilterVal[]>([]);

  const [filterStatus, setFilterStatus] = useState(false);

  const query = useQuery<ApiKeysResponse, ApiError>(
    ['TabApiKeyList', page, sort, filterVal],
    () => getApiKeyList(page, sort, filterVal),
    {
      keepPreviousData: true,
    },
  );

  const { isApiKeyForm, setApiKeyForm, dataForDelete, setDataForDelete, delToken, apiKeyValue } =
    useApiKeyContext();

  const getPage = usePageChange(setPage, urlParams, dataForHooks);

  const pageChange = (event: GridPageChangeEvent): void => {
    getPage(event);
  };

  const handleRefresh = (): void => {
    setFilterStatus(true);
    query.refetch().then(() => setFilterStatus(false));
  };

  return (
    <div className="apikey-list">
      {dataForDelete && (
        <DeleteConfirmModal
          type="api-key"
          name={dataForDelete.name}
          onClose={(): void => setDataForDelete(null)}
          handleDelete={(): void => delToken(dataForDelete.apiKeyId)}
        />
      )}
      <div className="apikey-list__actions topactions">
        {currentUser?.role !== 'user' && currentUser?.role !== 'reader_user' && (
          <Button fill="action" onClick={(): void => setApiKeyForm(true)}>
            {localeSettings[currentLocale].apiKey.add}
          </Button>
        )}
        <Button icon="refresh" onClick={handleRefresh}>
          {localeSettings[currentLocale].apiKey.refresh}
        </Button>
      </div>
      <div className={styles.apikeylist}>
        <LocalizationProvider language={currentLocale}>
          <IntlProvider locale={currentLocale.substring(0, 2)}>
            <Grid
              pageable={Number(query.data?.total) > 15 ? PAGEABLE_DATA : false}
              sortable
              sort={sort}
              data={query?.data?.data}
              skip={page.skip}
              take={page.take}
              total={Number(query.data?.total)}
              filterOperators={FILTER_OPERATORS}
              onPageChange={pageChange}
              onSortChange={(e: GridSortChangeEvent): void => handleSort(e, setSort)}
              scrollable="none"
              className={
                Number(query?.data?.total) && Number(query?.data?.total) < 15
                  ? 'no-pageable-k-grid'
                  : ''
              }
            >
              <Column
                cell={CellLinkName}
                title={localeSettings[currentLocale].apiKey.grid.name}
                filter="text"
                field="name"
                width="240px"
              />
              <Column
                cell={CellRestrictionApiKeyList}
                title={localeSettings[currentLocale].apiKey.grid.restrictions}
                filter="text"
                field="scope"
                sortable={false}
                width="560px"
              />
              <Column
                cell={CellGroupDescription}
                title={localeSettings[currentLocale].apiKey.grid.description}
                filter="text"
                field="description"
              />
              <Column
                cell={CellCommonDate}
                title={localeSettings[currentLocale].apiKey.grid.createdAt}
                filter="text"
                field="createdAt"
                width="200px"
              />
              <Column cell={CellMenuApiKeyList} filter="text" sortable={false} width="52px" />
            </Grid>
            <СommonTableLoader queryData={query.isLoading} filteringData={filterStatus} />
            {query.isError && (
              <div className="info alert alert-danger">Error: {query.error.message}</div>
            )}
          </IntlProvider>
        </LocalizationProvider>
      </div>
      {isApiKeyForm && <AddApiKeyForm />}
      {apiKeyValue && <ApiKeyValue />}
    </div>
  );
}
