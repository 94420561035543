import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { useState } from 'react';
import { Button } from '../../common/baseElements/Button';
import localeButtons from '../../../utils/i18n/commonLocales/buttons.json';
import localeSensor from '../../../utils/i18n/sensorLocales/sensor.json';
import { useSensorContext } from '../../../hooks/useSensorContext';
import { FormInput } from '../../common/form/FormInput';
import { FormTextArea } from '../../common/form/FormTextArea';
import { useSensorActions } from '../../../hooks/components/useSensorActions';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeSensor;

export function SensorEditModal(): React.ReactElement {
  const { sensorIdContext, sensorName, sensorDescription, setEditOpen } = useSensorContext();
  const { editSensor } = useSensorActions();

  const [name, setName] = useState(sensorName.current);
  const [description, setDescription] = useState(sensorDescription.current || '');

  return (
    <Dialog
      title={`${localeSensor[currentLocale].editTitle} ${
        sensorName.current || sensorIdContext.current
      }`}
      onClose={(): void => {
        setEditOpen(undefined);
      }}
      className="sensor-edit modal small"
    >
      <Form
        onSubmit={(): void => {
          if (sensorIdContext.current) {
            editSensor(sensorIdContext.current, {
              name,
              description: description || null,
            }).then(() => {
              setEditOpen(undefined);
            });
          }
        }}
        render={(formRenderProps: FormRenderProps): JSX.Element => (
          <FormElement className="create-sensor">
            <div className="form-content">
              <Field
                name="name"
                component={FormInput}
                id="name"
                label={`${localeSensor[currentLocale].addModal.nameField}:`}
                onChange={(e): void => {
                  setName(e.target.value);
                }}
                inputValue={name}
                className="form-field-wrapper"
                classForLabel="required-label"
                // customValidator={checkLength}
                type="text"
              />
              <Field
                name="name"
                component={FormTextArea}
                id="description"
                label={`${localeSensor[currentLocale].addModal.description}:`}
                onChange={(e): void => {
                  setDescription(e.value);
                }}
                inputValue={description}
                className="form-field-wrapper"
                type="text"
                required={false}
              />
            </div>
            <div className="k-form-buttons">
              <Button
                disabled={!formRenderProps.allowSubmit || !formRenderProps.valid || !name}
                type="submit"
              >
                {localeButtons[currentLocale].edit}
              </Button>
              <Button
                onClick={
                  // handleCancelSensor
                  (e): void => {
                    e.preventDefault();
                    setEditOpen(undefined);
                  }
                }
              >
                {localeButtons[currentLocale].cancel}
              </Button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
}
