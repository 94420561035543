/* eslint-disable react/jsx-pascal-case */
import type { ReactElement, ReactNode } from 'react';
import { Typography } from '@progress/kendo-react-common';
import { ErrorBoundary } from 'react-error-boundary';
import { BoundaryErrorComponent } from '../common/BoundaryErrorComponent';
import { EntityNotFound } from './EntityNotFound';
import { LoadingPanel } from '../common/tableLoader/LoadingPanel';
import localeEntity from '../../utils/i18n/commonLocales/entityDescription.json';
import localeNames from '../../utils/i18n/commonLocales/names.json';
import type { CabinetEntities } from '../../utils/helpers/types';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeEntity;

export function EntityDescription({
  isLoading,
  isNotFound,
  entity,
  className,
  children,
  title,
  topactions,
  tagBlock,
}: {
  isLoading: boolean;
  isNotFound: boolean;
  entity: {
    id: string;
    name: string | null | undefined;
    type: CabinetEntities;
  };
  className: string;
  children: ReactNode;
  title?: ReactNode | string;
  topactions?: ReactNode;
  tagBlock?: ReactNode;
}): ReactElement {
  const localeTitle = title || (
    <div className="common-header-page">
      <div className="common-header-page__title">
        <Typography.h3 className="title">
          {localeEntity[currentLocale].overview}{' '}
          {currentLocale === 'ru-RU'
            ? localeNames[currentLocale][entity.type][1].toLowerCase()
            : localeNames[currentLocale][entity.type].toLowerCase()}
          : {entity.name || entity.id}
        </Typography.h3>
        {tagBlock}
      </div>
      {topactions && <div className="topactions">{topactions}</div>}
    </div>
  );

  return (
    <ErrorBoundary FallbackComponent={BoundaryErrorComponent}>
      <div className={className}>
        {isLoading ? (
          <>
            {localeTitle}
            <LoadingPanel />
          </>
        ) : (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {isNotFound ? (
              <EntityNotFound entityId={entity.id} type={entity.type} />
            ) : (
              <>
                {localeTitle}
                {children}
              </>
            )}
          </>
        )}
      </div>
    </ErrorBoundary>
  );
}
