import { useQuery, useQueryClient } from 'react-query';
import { loadMessages } from '@progress/kendo-react-intl';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { Button } from '../../../common/baseElements/Button';
import localeCommonTable from '../../../../utils/i18n/commonLocales/table.json';
import type { ApiError } from '../../../../types/__generated/on-premise-solution/api/apiError.v1';
import { getHostGroupDescription } from '../../../../services/asset-service';
import { EditGroupForm } from '../form/EditGroup';
import { useGroupContext } from '../../../../hooks/useGroupContext';
import type { EnrichedAssetGroupResponse } from '../../../../types/__generated/on-premise-solution/api/enrichedAssetGroupResponse.v1';
import { GroupMetaBlock } from './GroupMetaBlock';
import { useHostModalContext } from '../../../../hooks/useHostModalContext';
import locale from '../../../../utils/i18n/assetLocales/assetDescriptionGroup.json';
import { useGroupHook } from '../../../../hooks/components/useGroupHook';
import { HostListForForm } from '../../../common/form/HostListForForm';
import { useTaskForGroup } from '../../../../hooks/components/useTaskForGroup';
import { getUserData } from '../../../../services/local-storage-service';
import { GroupListDescription } from './GroupListDescription';
import { HostConfirmDelFromGr } from '../../Host/hostComponents/HostConfirmDelFromGr';
import { DeleteConfirmModal } from '../../../common/modal/DeleteConfirmModal';
import { MaintenanceListModal } from '../../Host/MaintenanceWindows/MaintenanceListModal';
import { useMaintenanceWindowsContext } from '../../../../hooks/useMaintenanceWindowsContext';
import { EntityDescription } from '../../../templates/EntityDescription';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof locale;

loadMessages(localeCommonTable[currentLocale], currentLocale);

export function GroupDescription(): React.ReactElement {
  const currentUser = getUserData();

  const queryClient = useQueryClient();

  const dataAsset = useRef<string[] | undefined>(undefined);

  const { isOpen: isOpenGroup } = useGroupContext();

  const { assetGroupId } = useParams<{ assetGroupId: string }>();

  const { entityId, entityName, entityType, setMaintenanceWindowsList } =
    useMaintenanceWindowsContext();

  const query = useQuery<EnrichedAssetGroupResponse, ApiError>(
    ['hostGroupDescription'],
    () => getHostGroupDescription(assetGroupId),
    {
      onSuccess: (resp) => {
        entityId.current = resp.assetGroupId;
        entityName.current = resp.name;
        entityType.current = 'group';
        setMaintenanceWindowsList(resp.maintenanceWindows);
        dataAsset.current = resp.assets?.map((a) => a.assetId);
      },
      keepPreviousData: true,
    },
  );

  const { hostFormTemp, setHostForm, isOpen, setHostFormTemp } = useHostModalContext();

  useEffect(() => {
    queryClient.invalidateQueries('hostGroupDescription');
  }, [queryClient]);

  useEffect(() => {
    if (!isOpenGroup) {
      queryClient.invalidateQueries('hostGroupDescription');
    }
  }, [isOpenGroup, queryClient]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries('hostGroupDescription');
      entityId.current = undefined;
      entityName.current = undefined;
      entityType.current = undefined;
      setMaintenanceWindowsList(undefined);
    };
  }, []);

  const { data } = query;

  const name = data ? data.name : '';

  const {
    handleDelAssets,
    editGroup,
    addAssetToGroup,
    isConfirm,
    setConfirm,
    isError,
    handleDelete,
    errorMessage,
    isDelHostFromGr,
    setDelHostFromGr,
  } = useGroupHook(data?.name, data?.description, assetGroupId);

  const confirmDel = (): void => {
    setDelHostFromGr(true);
  };

  useEffect(() => {
    return () => {
      setHostForm(null);
      setHostFormTemp(null);
    };
  }, [setHostForm, setHostFormTemp]);

  const createTaskForGroup = useTaskForGroup(name, assetGroupId, 'static');

  const { isTableOpen } = useMaintenanceWindowsContext();

  return (
    <EntityDescription
      isLoading={query.isLoading}
      isNotFound={query.error?.code === '404'}
      entity={{
        id: assetGroupId,
        type: 'group',
        name,
      }}
      topactions={
        <>
          <Button
            onClick={(): void => {
              createTaskForGroup('default');
            }}
            className="action"
          >
            {locale[currentLocale].btns.btnCreateTask}
          </Button>
          {currentUser?.role !== 'reader_user' && (
            <div className="button-group">
              <Button
                onClick={addAssetToGroup}
                disabled={
                  data?.createdBy.source === 'user' &&
                  currentUser?.role === 'user' &&
                  currentUser?.id !== data?.createdBy.id
                }
              >
                {locale[currentLocale].btns.addAsset}
              </Button>
              <Button
                onClick={confirmDel}
                disabled={
                  !hostFormTemp ||
                  hostFormTemp?.length === 0 ||
                  // (hostFormTemp?.length > 0 && typeOfChangeGr === 'add') ||
                  (data?.createdBy.source === 'user' &&
                    currentUser?.role === 'user' &&
                    currentUser?.id !== data?.createdBy.id)
                }
              >
                {locale[currentLocale].btns.delAsset}
              </Button>
            </div>
          )}
          {currentUser?.role !== 'reader_user' && (
            <div className="button-group edit-group">
              <Button
                onClick={editGroup}
                disabled={
                  data?.createdBy.source === 'user' &&
                  currentUser?.role === 'user' &&
                  currentUser?.id !== data?.createdBy.id
                }
              >
                {locale[currentLocale].btns.edit}
              </Button>
              <Button
                fill="delete"
                onClick={(): void => setConfirm(true)}
                disabled={
                  data?.createdBy.source === 'user' &&
                  currentUser?.role === 'user' &&
                  currentUser?.id !== data?.createdBy.id
                }
              >
                {locale[currentLocale].btns.delGroup}
              </Button>
            </div>
          )}
        </>
      }
      className="asset-group-description"
    >
      {isTableOpen && <MaintenanceListModal />}

      {isConfirm && (
        <DeleteConfirmModal
          name={name}
          type="group"
          onClose={(): void => setConfirm(false)}
          handleDelete={(): void => handleDelete(assetGroupId)}
          errorMessage={isError ? errorMessage : undefined}
          countInside={data?.assetCount}
        />
      )}
      <GroupMetaBlock data={data} />
      {data?.assets && <GroupListDescription dataAsset={dataAsset.current} />}
      {isOpenGroup && <EditGroupForm />}
      {isOpen && <HostListForForm />}
      {isDelHostFromGr && (
        <HostConfirmDelFromGr
          setDelHostFromGr={setDelHostFromGr}
          handleDelAssets={handleDelAssets}
        />
      )}
    </EntityDescription>
  );
}
