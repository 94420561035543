import type { GridCellProps } from '@progress/kendo-react-grid';
import { NavLink } from 'react-router-dom';
import type {
  TaskActionDashboardResponse,
  TaskAssetLdapSynchronizeAction,
  TaskAuditImageAction,
  TaskNotificationAction,
  TaskSystemRebootAction,
  TaskWindowsSoftwareManagementAction,
} from '../../../../../types/__generated/on-premise-solution/api/taskActionDashboardResponse.v1';
import type {
  TaskAgentUpdateAction,
  TaskAuditAction,
  TaskGroupReportAction,
  TaskInventorizationAction,
  TaskSingleReportAction,
} from '../../../../../types/__generated/on-premise-solution/api/taskActionsResponse.v1';
import type { IActionsValues, IReportType } from '../../../../../utils/helpers/types';
import localeTask from '../../../../../utils/i18n/taskLocale/task.json';
import { CellActionSuccess } from './CellActionSuccess';
import { CellActionProcessing } from './CellActionProcessing';
import { CellActionTimeout } from './CellActionTimeout';
import { CellActionFail } from './CellActionFail';
import type { TaskPackageUpdateAction } from '../../../../../types/__generated/on-premise-solution/api/auditDashboardResponse.v1';
import { TaskType } from '../../common/TaskType';

const currentLocale = (
  window.navigator.language === 'ru-RU' || window.navigator.language === 'ru' ? 'ru-RU' : 'en-EN'
) as keyof typeof localeTask;

export function CellActions(props: GridCellProps): React.ReactElement {
  const { dataItem } = props;

  const { assets }: TaskActionDashboardResponse = dataItem;

  const {
    assetId,
  }:
    | TaskAuditAction
    | TaskInventorizationAction
    | TaskAgentUpdateAction
    | TaskPackageUpdateAction
    | TaskGroupReportAction
    | TaskSingleReportAction
    | TaskAuditImageAction = dataItem;

  const {
    type,
    status,
    taskType,
  }:
    | TaskAuditAction
    | TaskInventorizationAction
    | TaskAgentUpdateAction
    | TaskPackageUpdateAction
    | TaskGroupReportAction
    | TaskSingleReportAction
    | TaskAuditImageAction
    | TaskNotificationAction
    | TaskAssetLdapSynchronizeAction
    | TaskSystemRebootAction
    | TaskWindowsSoftwareManagementAction = dataItem;

  const { reportType }: TaskSingleReportAction | TaskGroupReportAction = dataItem;

  const { auditId }: TaskAuditAction | TaskAuditImageAction = dataItem;

  const { taskActionId }: TaskAuditAction = dataItem;

  const { inventorizationId }: TaskInventorizationAction = dataItem;

  const { taskExecutionId }: TaskAssetLdapSynchronizeAction = dataItem;

  const checkErrorInfo: string | undefined =
    (dataItem.result &&
      dataItem.result.data &&
      dataItem.result.data[0] &&
      'error' in dataItem.result.data[0] &&
      dataItem.result.data[0].error) ||
    undefined;

  return (
    <td>
      <div className="task__actions">
        <CellActionSuccess
          assets={assets}
          assetId={assetId}
          type={type}
          status={status}
          auditId={auditId}
          inventorizationId={inventorizationId}
          dataItem={dataItem}
          taskActionId={taskActionId}
          taskExecutionId={taskExecutionId}
        />
        <CellActionProcessing
          assets={assets}
          type={type}
          status={status}
          assetId={assetId}
          auditId={auditId}
          inventorizationId={inventorizationId}
          dataItem={dataItem}
          taskActionId={taskActionId}
        />
        <CellActionTimeout
          assets={assets}
          assetId={assetId}
          type={type}
          status={status}
          dataItem={dataItem}
        />
        <CellActionFail
          assets={assets}
          assetId={assetId}
          type={type}
          status={status}
          dataItem={dataItem}
          taskActionId={taskActionId}
          checkErrorInfo={checkErrorInfo}
        />
        {type === 'inventorization' &&
          status !== 'fail' &&
          status !== 'skipped' &&
          assets &&
          assets[0].type === 'host' &&
          assets[0].latestInventorization === null && (
            <>
              <TaskType title type="inventorization" />{' '}
              {inventorizationId ? (
                <NavLink
                  className="link-underline"
                  to={`/lk/task/actions/${inventorizationId}/inventorization`}
                >
                  <span>
                    {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                  </span>
                </NavLink>
              ) : (
                <>
                  <span>
                    {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}{' '}
                  </span>
                  <NavLink className="link-underline" to={`/lk/assets/hosts/${assetId}`}>
                    {assetId}
                  </NavLink>
                </>
              )}
            </>
          )}
        {type === 'agent_update' &&
          assets &&
          assets[0] &&
          assets[0].type === 'host' &&
          assets[0].latestInventorization && (
            <>
              <TaskType title type="agent_update" />{' '}
              <span>
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}
                {assets[0].latestInventorization.hostname}
              </span>
            </>
          )}
        {type === 'agent_update' &&
          assets &&
          assets[0] &&
          assets[0].type === 'host' &&
          assets[0].latestInventorization == null && (
            <>
              <TaskType title type="agent_update" />{' '}
              <span>
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}
              </span>
            </>
          )}
        {type === 'agent_update' &&
          assets &&
          assets[0] &&
          (assets[0].type === 'image' || assets[0].subType === 'network_device') && (
            <>
              <TaskType title type="agent_update" />{' '}
              <span>
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}
              </span>
              {assets[0].type === 'image' && assets[0] && assets[0].imageNames[0] && (
                <span>{assets[0].imageNames[0]}</span>
              )}
              {assets[0].subType === 'network_device' &&
                assets[0] &&
                assets[0].latestInventorization.hostname && (
                  <span>{assets[0] && assets[0].latestInventorization.hostname}</span>
                )}
            </>
          )}
        {type === 'agent_update' && !assets && (
          <>
            <TaskType title type="agent_update" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}
              {dataItem.assetId}
            </span>
          </>
        )}
        {type === 'system_reboot' &&
          assets &&
          assets[0] &&
          assets[0].type === 'host' &&
          assets[0].latestInventorization && (
            <>
              <TaskType title type="system_reboot" />{' '}
              <span>
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}
                {assets[0].latestInventorization.hostname}
              </span>
            </>
          )}
        {type === 'system_reboot' &&
          assets &&
          assets[0] &&
          assets[0].type === 'host' &&
          assets[0].latestInventorization == null && (
            <>
              <TaskType title type="system_reboot" />{' '}
              <span>
                {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}
              </span>
            </>
          )}
        {type === 'system_reboot' && !assets && (
          <>
            <TaskType title type="system_reboot" />{' '}
            <span>
              {localeTask[currentLocale].form.actionsValues[type as keyof IActionsValues]}
              {dataItem.assetId}
            </span>
          </>
        )}

        {type === 'group_report' && (
          <>
            <TaskType title type="group_report" />{' '}
            <span>
              {localeTask[currentLocale].form.reportTypes[reportType as keyof IReportType]}
            </span>{' '}
          </>
        )}
        {type === 'single_report' &&
          assets &&
          assets[0] &&
          // (assets[0].type === 'host' || assets[0].type === 'device') &&
          assets[0].latestInventorization && (
            <>
              <TaskType title type="single_report" />{' '}
              <span>
                <span>
                  {localeTask[currentLocale].form.reportTypes[reportType as keyof IReportType]}
                </span>{' '}
                {assets[0].type !== 'image' && assets[0].latestInventorization.hostname}
                {/* {assets[0].type === 'image' &&  assets[0].latestInventorization.
                'imageNames' in assets[0].latestInventorization &&
                  Array.isArray(assets[0].latestInventorization.imageNames) &&
                  assets[0].latestInventorization.imageNames[0]} */}
              </span>{' '}
            </>
          )}
        {type === 'single_report' &&
          assets &&
          assets[0] &&
          // (assets[0].type === 'host' || assets[0].type === 'image') &&
          assets[0].latestInventorization === null && (
            <>
              <TaskType title type="single_report" />{' '}
              <span>
                <span>
                  {localeTask[currentLocale].form.reportTypes[reportType as keyof IReportType]}
                </span>{' '}
              </span>{' '}
            </>
          )}
      </div>
    </td>
  );
}
